var _a;
import { createSelector } from '@ngrx/store';
import * as fromRoot from '@app/store/selectors';
import * as fromAddress from '@app/store/reducers/address.reducer';
import * as fromSubscription from '@app/store/selectors/subscriptions.selector';
export var getAddressesIds = (_a = fromAddress.adapter.getSelectors(fromRoot.getAddressState), _a.selectIds), getAddressEntities = _a.selectEntities, getTotalAddresses = _a.selectTotal, getAllAddresses = _a.selectAll;
export var getAddressById = function (id) { return createSelector(fromRoot.getAddressState, function (state) { return state.entities[id]; }); };
export var getSubscriptionAddress = function (id) { return createSelector(fromSubscription.getSubscriptionEntities, getAddressEntities, function (subscriptions, addresses) {
    return addresses[subscriptions[id].address];
}); };
export var getAddressesLoaded = createSelector(fromRoot.getAddressState, function (state) { return state.loaded; });
export var getSAddressesLoading = createSelector(fromRoot.getAddressState, function (state) { return state.loading; });
