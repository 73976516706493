import { Location } from '@angular/common';
import * as BrandData from "@config/brand.json";
import * as FooterData from "@config/footer.json";
import * as CarecardsData from "@config/carecards.json";
import * as ModulesData from "@config/modules.json";
var FooterComponent = /** @class */ (function () {
    function FooterComponent(locationService) {
        this.locationService = locationService;
        this.footerData = FooterData.default;
        this.brandData = BrandData.default;
        this.carecardsData = CarecardsData.default;
        this.modulesData = ModulesData.default;
    }
    FooterComponent.prototype.moveToTop = function () {
        window.scroll(0, 0);
    };
    Object.defineProperty(FooterComponent.prototype, "isSales", {
        get: function () {
            return this.locationService.path() === '/sales' || this.locationService.path() === '/churn';
        },
        enumerable: true,
        configurable: true
    });
    return FooterComponent;
}());
export { FooterComponent };
