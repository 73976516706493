export const environment = {
  name: 'production',
  production: true,
  api_uri: '/api',
  marketingApi: 'https://tn44x5v3mp.us-east-1.awsapprunner.com',
  app_version: '2.0.4',
  gtmContainerId: 'GTM-NJ83LGH',
  gtmEnvironment: '&gtm_auth=5rYFtjkiGNxkUrf49WNR0w&gtm_preview=env-3&gtm_cookies_win=x',
  propsToClearOnLS: {
    selectedPlanId: true,
    checkoutUser: true
  },
  pca_predict: {
    key: 'ZW39-HX97-ZC55-NM99',
    api_endpoint: ''
  },
  bugSnagKey: '2cf8b7001ca9d97bac061ea3717d4cee',

  // Angler AI
  anglerAiWorkspaceId: 'ws-eucalyptus-farms-9bys1d16w',
  anglerAiToken: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTE0OTM3NTM2ODcsImV4cCI6MjAyNjg1Mzc1MzY4Nywic3ViIjoid3MtZXVjYWx5cHR1cy1mYXJtcy05YnlzMWQxNnciLCJzY29wZXMiOiJDTElFTlRfRVZFTlRTIiwiaXNzIjoiZjBlZGEzZTMtZDBkMS00OTFkLTg5ZDktZjgxY2U5OTI3YTNmIiwianRpIjoiRUllRld4NllfaTM3UWJQNmRFOHNoIn0.G2SgoCKdtDyp3yjdIHbPrqKdoTtlPrpfQ4REJCQey_0k1OI7sU55srfJ09l0bjkMxWk2r-o1yrmWQi_KdWHlEU7f4HcfMhaTcN3wWEm37SRBg7e4TlDAZJt_f-hyLT5RJqg_bFA_4XXEtZ388kIGFQ3dLiODr4OBK-7lqjLrwdP-QQCUtS1mJFKkqrs7jljSgEbGZJv3H4hIWc48MtKhnC_8THrPMCw4AAzV5KK8q-KbnzLpj1J3cDUI3Oo7-FVTS3jFgZ4rsauhEmrDMMK30IUhRwqjzuMc4ZH-f4BPlF_-UJWcl1tKvsnp-6bVcufB9zxKd__MnZjzymjFSi0jIg',
};
