import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as $ from 'jquery';

import { ModalService } from '@app/core/services';

@Component({
  selector: 'sucstu-modal',
  styleUrls: ['./modal.component.scss'],
  template: `
    <div class="modal">
      <div class="modal-body" [ngStyle]="modalBodyStyles">
        <ng-content></ng-content>
      </div>
    </div>
    <div class="modal-background" [ngStyle]="modalBackgroundStyles"></div>
  `
})
export class ModalComponent implements OnInit, OnDestroy {

  @Input() id: string;
  @Input() modalBodyStyles: any;
  @Input() modalBackgroundStyles: any;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  private element: JQuery;
  isOpen = false;

  constructor(
    private modalService: ModalService,
    private el: ElementRef
  ) {
    this.element = $(el.nativeElement);
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    this.element.appendTo('body');

    // close modal on background click
    this.element.on('click', (e: any) => {
      const target = $(e.target);
      if (!target.closest('.modal-body').length) {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): Promise<any> {
    this.element.show();
    this.isOpen = true;
    return Promise.resolve();
    // Comenting this because of some weird issues that the class is not being removed on async calls
    // $('body').addClass('modal-open');
  }

  // close modal
  close(): void {
    this.onClose.emit();
    this.element.hide();
    this.isOpen = false;
    // $('body').removeClass('modal-open');
  }

}
