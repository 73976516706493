import * as searchEmail from '@app/store/actions/search-email.actions';
import * as user from '@app/store/actions/user.actions';

// models
import { User } from '@app/core/models/user.model';

export interface State {
  searching: boolean,
  searched: boolean,
  found: boolean,
  errors: any[]
};

export const initialState: State = {
  searching: false,
  searched: true,
  found: false,
  errors: []
};

export function reducer (
  state: State = initialState,
  action: searchEmail.Actions | user.Actions
): State {
  switch (action.type) {

    case searchEmail.SEARCH_EMAIL: {
      return {...state, searching: true, searched: false };
    }

    case searchEmail.SEARCH_EMAIL_COMPLETE: {
      return {...state, searching: false, searched: true, found: true };
    }

    case user.LOGOUT_COMPLETE: {
      return { ...state, searching: false, found: false, searched: true };
    }

    case searchEmail.SEARCH_EMAIL_FAIL: {
      return { ...state, searching: false, found: false, searched: true, errors: action.payload };
    }

    default: {
      return state
    }
  }
}

export const getSearchingValue = (state: State) => state.searching;
export const getSearchedValue = (state: State) => state.searched;
export const getFoundValue = (state: State) => state.found;
export const getErrors = (state: State) => state.errors;
