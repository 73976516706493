import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import * as payment from 'payment';

@Directive({ selector: '[ccExp]' })
export class CardExpirationFormatDirective {

  cardType: string;
  constructor(private el: ElementRef) {
    const element = this.el.nativeElement;

    // call lib functions
    payment.formatCardExpiry(element);
    payment.restrictNumeric(element);
  }
}
