import * as tslib_1 from "tslib";
import { createEntityAdapter } from '@ngrx/entity';
// actions
import * as paymentMethodsActions from '@app/store/actions/payment-methods.actions';
import * as userActions from '@app/store/actions/user.actions';
export var adapter = createEntityAdapter({
    selectId: function (paymentMethod) { return paymentMethod._id; },
    sortComparer: false
});
export var initialState = adapter.getInitialState({
    loading: false,
    loaded: false,
    error: null
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case paymentMethodsActions.LOAD_PAYMENT_METHODS:
        case paymentMethodsActions.UPDATE_PAYMENT_METHOD:
        case paymentMethodsActions.UPDATE_PAYMENT_METHOD_WITH_REBILL:
        case paymentMethodsActions.ADD_PAYMENT_METHOD: {
            return tslib_1.__assign({}, state, { loading: true, loaded: false });
        }
        case paymentMethodsActions.LOAD_PAYMENT_METHODS_COMPLETE: {
            return tslib_1.__assign({}, adapter.addAll(action.payload, state), { loaded: true, loading: false, error: null });
        }
        case paymentMethodsActions.UPDATE_PAYMENT_METHOD_COMPLETE: {
            return adapter.updateOne(action.payload, tslib_1.__assign({}, state, { loaded: true, loading: false }));
        }
        case paymentMethodsActions.ADD_PAYMENT_METHOD_COMPLETE: {
            return adapter.addOne(action.payload, tslib_1.__assign({}, state, { loaded: true, loading: false }));
        }
        case userActions.LOAD_COMPLETE:
        case userActions.LOGIN_COMPLETE: {
            var paymentMethodsFromUser = action.payload.paymentMethods;
            return adapter.addAll(paymentMethodsFromUser, tslib_1.__assign({}, state, { loaded: true, loading: false, error: null }));
        }
        case paymentMethodsActions.LOAD_PAYMENT_METHODS_FAIL:
        case paymentMethodsActions.ADD_PAYMENT_METHOD_FAIL:
        case paymentMethodsActions.UPDATE_PAYMENT_METHOD_FAIL: {
            return tslib_1.__assign({}, state, { error: action.payload });
        }
        default: {
            return state;
        }
    }
}
export var getPaymentMethods = function (state) { return state.entities; };
export var getPaymentIds = function (state) { return state.ids; };
export var getPaymentMethodLoaded = function (state) { return state.loaded; };
export var getPaymentMethodLoading = function (state) { return state.loading; };
export var getPaymentMethodErrors = function (state) { return state.error; };
