import { Component, OnInit, Input } from '@angular/core';
import * as GiftData from "@config/gift.json"

@Component({
  selector: 'sucstu-attributes-bar',
  templateUrl: './attributes-bar.component.html',
  styleUrls: ['./attributes-bar.component.scss'],
})
export class AttributesBarComponent implements OnInit {
  giftData = (GiftData as any).default;
  constructor() { }
  ngOnInit() { }
}
