<div class="page-container">
  <section class="faq-container container">
    <h1 *ngIf="faqData?.title" class="section__title faq__title text-medium">{{ faqData.title }}</h1>
    <h5 *ngIf="faqData?.firstText">{{ faqData?.firstText }}</h5>
    <h6 [innerHTML]="faqData?.secondText | safeHTML"></h6>
    <div class="questions-container">
      <sucstu-faq-question *ngFor="let qa of faqData?.questionsList">
        <h3 class="question__title" [innerHTML]="qa.title | safeHTML"></h3>
        <div class="question__answer question__answer-content" [innerHTML]="qa.answer | safeHTML"></div>
      </sucstu-faq-question>
    </div>
  </section>
</div>
