<div class="thanks-container">

  <div class="thanks-title-container">
    <h1 class="thanks-title">{{ giftDoneData?.title }}</h1>
    <p class="thanks-title-message">{{ giftDoneData?.message }}</p>

    <div class="thanks-title-divider">
      <img src="/assets/images/thanks-icon.png"/>
      <span class="gray-line"></span>
    </div>

    <p class="term-message">
      Subscription automatically ends after the {{ this.data.subscription.plan.term | ordinal }} month, so no need
      to cancel!
    </p>
  </div>
  <video *ngIf="giftDoneData?.video" class="video-container" [poster]="giftDoneData?.video.poster" controls preload="metadata">
    <source [src]="giftDoneData?.video.srcmp4" type="video/mp4">
    <source [src]="giftDoneData?.video.srcwebm" type="video/webm">
    Your browser does not support the video tag.
  </video>
  <div class="discount-btn-section">
    <p>{{ brandData?.mainProduct === 'succulents' ? brandData?.mainProduct + 'brighten even the succ-iest of days!' : '' }}  Want your own subscription?</p>
    <a class="btn discount-btn" (click)="goToCheckoutWithCoupon()">GET $5 OFF YOUR FIRST BOX</a>
  </div>
</div>

<ng-container *ngIf="isPlanABradsDealsDeal && renderBradsDealsPixel && isProd">
  <img [src]="bradsDealsPixelTagUrl" width="1px" height="1px"/>
</ng-container>
