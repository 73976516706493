import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

// rxjs
import { map } from 'rxjs/operators';

// config
import { APP_CONFIG, AppConfig } from './../../app.config';

@Injectable()
export class AppService {

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) { }

  getUUID() {
    const headers = new HttpHeaders({ retry: 'yes' });
    return this.httpClient
      .get(`${this.app_config.api_uri}/client-uuid`, { headers })
      .pipe(map((response: any) => response.uuid));
  }
}
