import { createSelector } from '@ngrx/store';

import * as fromRoot from '@app/store/selectors';
import * as fromUser from '@app/store/reducers/user.reducer';

export { State } from '@app/store/reducers/user.reducer';

export const getUserData = createSelector(
  fromRoot.getUserState,
  (state: fromUser.State) => state.data
);

export const getUserIsAuthenticated = createSelector(
  fromRoot.getUserState,
  (state: fromUser.State) => state.isAuthenticated
);

export const getUserError = createSelector(
  fromRoot.getUserState,
  (state: fromUser.State) => state.error
);

export const getUserLoaded = createSelector(
  fromRoot.getUserState,
  (state: fromUser.State) => state.loaded
);
export const getUserLoading = createSelector(
  fromRoot.getUserState,
  (state: fromUser.State) => state.loading
);
