/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq-question.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./faq-question.component";
var styles_FaqQuestionComponent = [i0.styles];
var RenderType_FaqQuestionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FaqQuestionComponent, data: {} });
export { RenderType_FaqQuestionComponent as RenderType_FaqQuestionComponent };
export function View_FaqQuestionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "faq-question-container"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "show-cursor": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "green-dot"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(6, 0, null, null, 2, "img", [["class", "arrow"], ["src", "./../../../../../../assets//images/eucalyptus/Imagen1.png"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "flip-arrow": 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "body"]], [[4, "display", null]], null, null, null, null)), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "faq-question-container"; var currVal_1 = _ck(_v, 2, 0, !_co.open); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "arrow"; var currVal_3 = _ck(_v, 8, 0, _co.open); _ck(_v, 7, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.open ? "block" : "none"); _ck(_v, 9, 0, currVal_4); }); }
export function View_FaqQuestionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-faq-question", [], null, null, null, View_FaqQuestionComponent_0, RenderType_FaqQuestionComponent)), i1.ɵdid(1, 49152, null, 0, i3.FaqQuestionComponent, [], null, null)], null, null); }
var FaqQuestionComponentNgFactory = i1.ɵccf("sucstu-faq-question", i3.FaqQuestionComponent, View_FaqQuestionComponent_Host_0, {}, {}, [".question__title", ".question__answer"]);
export { FaqQuestionComponentNgFactory as FaqQuestionComponentNgFactory };
