import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
// actions
import * as user from '@app/store/actions/user.actions';
// selectors
import * as fromUser from '@app/store/selectors/user.selector';

// models

@Component({
  selector: 'sucstu-header',
  styleUrls: ['./header.component.scss'],
  template: `
      <header class="header">
          <!-- Mobile Header -->
          <div class="header-container" #headerContainer>
              <div [ngClass]="{'sticked': isSticked }" [ngStyle]="{ 'top': moveFromTop + 'px' }" class="nav-row" *ngIf="!isSales">
                  <a class="logo-container" routerLink="/">
                      <img class="logo-container-img" src="assets/images/header-icon.png">
                      <img class="logo-container-img logo-container-img--mobile" src="assets/images/header-icon-mobile.png">
                  </a>
                  <h1 class="header-title header-title--desk">
                      <a routerLink="/"><span class="header-title-bolder">Succulent</span> Studios</a>
                  </h1>
                  <div class="nav-container" [ngClass]="{ 'isInspiration': isInspiration }" *ngIf="isInspiration">
                      <h5 class="guarantee-text">100% satisfaction guarantee</h5>
                      <div class="nav-action">
                          <a routerLink="/checkout">Subscribe</a>
                      </div>
                  </div>
                  <div class="nav-container" *ngIf="!isInspiration">
                      <h5 class="guarantee-text" *ngIf="!(isAuthenticated$ | async)">100% satisfaction guarantee</h5>
                      <div
                              class="nav-action"
                              [ngClass]="{ 'logged-in': isAuth }" *ngIf="(isAuthenticated$ | async) as isAuth; else loggedOut">
                          <a #loginButton routerLink="/profile">Account</a>
                          <a [attr.test-id]="'header__logout-button'" routerLink="/" (click)="logout()">Log Out</a>
                          <a *ngIf="userRole === 'admin'" routerLink="/control">Control center</a>
                      </div>
                      <ng-template #loggedOut>
                          <div class="nav-action">
                              <a [attr.test-id]="'header__login-button'" #loginButton routerLink="/login">Log In</a>
                          </div>
                      </ng-template>
                  </div>
              </div>
              <div class="title-row" *ngIf="!isSales">
                  <h1 class="header-title header-title--mobile">
                      <a routerLink="/"><span class="header-title-bolder">Succulent</span> Studios</a>
                  </h1>
              </div>
          </div>

      </header>
  `
})
export class HeaderComponent implements OnInit, OnChanges {

  @ViewChild('headerContainer') headerContainer: ElementRef;

  @Input() moveFromTop = 0;

  public isSticked: boolean;
  isAuthenticated$: Observable<boolean>;
  userRole: string;

  constructor(
    private store: Store<any>,
    private router: Router,
    private renderer: Renderer2,
    private locationService: Location,
    private elementRef: ElementRef
  ) {
    this.isAuthenticated$ = this.store.pipe(select(fromUser.getUserIsAuthenticated));
  }

  get isInspiration() {
    return this.locationService.path() === '/inspiration';
  }

  get isSales() {
    return this.locationService.path() === '/sales' || this.locationService.path() === '/churn';
  }

  @HostListener('window:scroll', ['$event'])
  onScrollPage() {
    if (document.documentElement.scrollTop === 0) {
      this.isSticked = false;
    } else {
      this.isSticked = true;
    }
  }

  ngOnInit() {
    this.store.pipe(select(fromUser.getUserData))
      .subscribe(userState => this.userRole = userState.role);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.isSales) {
      if (changes.moveFromTop.currentValue > 0) {
        const headerContainerElement = this.headerContainer.nativeElement as HTMLElement;
        const navRowElement = headerContainerElement.querySelector('.nav-row') as HTMLElement;
        const height = navRowElement.clientHeight;
      } else {
        this.renderer.setStyle(this.headerContainer.nativeElement, 'padding-top', ``);
      }
    }
  }

  openNav() {
    // this.isNavOpened = !this.isNavOpened;
  }

  logout(e: any) {
    this.router
      .navigate(['/'])
      .then(n => this.store.dispatch(new user.LogoutAction()));
  }

}
