import { NgModule, ModuleWithProviders } from '@angular/core';

// services
import * as fromServices from './services';

// resolvers
import { ClientAuthorizationResolver } from './resolvers/client-authorization.resolver';
import { SubscriptionInfoResolver } from './resolvers/subscription-info.resolver';
import { PlanResolver } from './resolvers/plan.resolver';
import { ProfileResolver } from './resolvers/profile.resolver';

// guards
import { RouterTransitionGuard } from './guards/router-transition.guard';
import * as fromPlanGuards from './guards/plans';
import * as fromBoxGuards from './guards/boxes';

// components
import { ModalInfoComponent } from './components/modal-info.component';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  imports: [
    OverlayModule,
  ],
  declarations: [ModalInfoComponent],
  entryComponents: [ModalInfoComponent],
  providers: [
    RouterTransitionGuard,
    ClientAuthorizationResolver,
    SubscriptionInfoResolver,
    PlanResolver,
    ProfileResolver,
    ...fromServices.services,
    ...fromPlanGuards.guards,
    ...fromBoxGuards.guards,
  ]
})
export class CoreModule { }
