import { Directive, ElementRef, Input, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDynamicBackground]'
})
export class DynamicBackgroundDirective implements OnInit {

  @Input() appDynamicBackground: { [width: number]: string } = { };

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.changeElementBg();
  }

  @HostListener('window:resize', ['$event'])
  changeElementBg() {
    const windowW = window.innerWidth;

    const mediaQueries = Object
      .keys(this.appDynamicBackground)
      .map(size => parseInt(size, 10))
      .sort((a, b) => a - b);

    const currentMediaQuery = mediaQueries.find((size, index) => {
      const nextSize = mediaQueries[index + 1];
      return nextSize
        ? windowW >= size && windowW < nextSize
        : windowW >= size;
    });

    const background = this.appDynamicBackground[currentMediaQuery || mediaQueries[0]];
    this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${background})`);
  }
}
