/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./shared/components/notification-bar/notification-bar.component.ngfactory";
import * as i4 from "./shared/components/notification-bar/notification-bar.component";
import * as i5 from "./shared/components/coupon-banner/coupon-banner.component.ngfactory";
import * as i6 from "./shared/components/coupon-banner/coupon-banner.component";
import * as i7 from "./shared/components/header/header.component.ngfactory";
import * as i8 from "./shared/components/header/header.component";
import * as i9 from "@ngrx/store";
import * as i10 from "@angular/router";
import * as i11 from "./shared/components/footer/footer.component.ngfactory";
import * as i12 from "./shared/components/footer/footer.component";
import * as i13 from "./app.component";
import * as i14 from "./core/services/app.service";
import * as i15 from "./auth/services/auth.service";
import * as i16 from "angulartics2/gtm";
import * as i17 from "./core/services/leads.service";
import * as i18 from "./error/services/error.service";
import * as i19 from "./core/services/coupons.service";
import * as i20 from "ngx-webstorage";
import * as i21 from "./core/services/angler-ai/angler-ai.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "app-loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "spinner"], ["viewBox", "25 25 50 50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:circle", [["class", "path"], ["cx", "50"], ["cy", "50"], ["fill", "none"], ["r", "20"], ["stroke-miterlimit", "10"], ["stroke-width", "2"]], null, null, null, null, null))], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { notificationBarComponent: 0 }), i1.ɵqud(402653184, 2, { couponBarComponent: 0 }), i1.ɵqud(402653184, 3, { mothersBannerComponent: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 14, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, [[1, 0]], null, 2, "sucstu-notification-bar", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.onCloseNotificationBar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_NotificationBarComponent_0, i3.RenderType_NotificationBarComponent)), i1.ɵdid(8, 49152, null, 0, i4.NotificationBarComponent, [], { data: [0, "data"] }, { close: "close" }), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, [[2, 0]], null, 2, "sucstu-coupon-banner", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.onCloseCouponBanner($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_CouponBannerComponent_0, i5.RenderType_CouponBannerComponent)), i1.ɵdid(11, 49152, null, 0, i6.CouponBannerComponent, [], { coupon: [0, "coupon"], showBanner: [1, "showBanner"] }, { close: "close" }), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 1, "sucstu-header", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onScrollPage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_HeaderComponent_0, i7.RenderType_HeaderComponent)), i1.ɵdid(14, 638976, null, 0, i8.HeaderComponent, [i9.Store, i10.Router, i1.Renderer2, i2.Location, i1.ElementRef], { moveFromTop: [0, "moveFromTop"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "app-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 16777216, null, null, 1, "router-outlet", [], null, [[null, "deactivate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deactivate" === en)) {
        var pd_0 = (_co.onDeactivate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 212992, null, 0, i10.RouterOutlet, [i10.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, { deactivateEvents: "deactivate" }), (_l()(), i1.ɵeld(18, 0, null, null, 1, "sucstu-footer", [["class", "footer-container"]], null, null, null, i11.View_FooterComponent_0, i11.RenderType_FooterComponent)), i1.ɵdid(19, 49152, null, 0, i12.FooterComponent, [i2.Location], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.notificationBar$)); _ck(_v, 8, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.coupon$)); var currVal_3 = _co.showCouponBanner; _ck(_v, 11, 0, currVal_2, currVal_3); var currVal_4 = _co.notificationBarHeight; _ck(_v, 14, 0, currVal_4); _ck(_v, 17, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4308992, null, 0, i13.AppComponent, [i10.Router, i10.ActivatedRoute, i14.AppService, i15.AuthService, i16.Angulartics2GoogleTagManager, i17.LeadsService, i18.ErrorService, i9.Store, i19.CouponsService, i20.LocalStorageService, i20.SessionStorageService, i21.AnglerAiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("sucstu-root", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
