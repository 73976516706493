import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { EmailValidator } from '@app/shared/validators/email.validator';
import * as BrandData from '@config/brand.json';

import { fromEvent } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Component({
  selector: 'sucstu-lead-banner',
  styleUrls: ['./lead-banner.component.scss'],
  template: `
  <div class="lead-banner-container" #bannerContainer>
    <div class="lead-banner" #banner>
      <div class="lead-banner-content">
        <h1 class="lead-banner__title text-bolder">50% OFF</h1>
        <h3 class="lead-banner__subtitle">YOUR FIRST BUNDLE OF SEASONAL EUCALYPTUS!</h3>
        <!-- [formGroup]="parent" -->
        <form class="lead-banner__form" [formGroup]="form">
          <div class="form-group">
            <input
              class="lead-input form-group__input text-extralight"
              id="nameInput" type="email"
              placeholder="Enter your email"
              formControlName="email"
              required="required"
              (keyup.enter)="!isFieldInvalid('email', 'invalidEmail') ? submitEmail() : null"/>
            <span class="lead-error error-text" *ngIf="isFieldInvalid('email', 'invalidEmail')">Please use a valid email</span>
          </div>
          <button
            class="lead-banner__btn btn btn-primary"
            type="button"
            (click)="submitEmail()">I want {{ brandData?.mainProduct }}</button>
        </form>
        <a [attr.test-id]="'lead-banner__close-button'" class="lead-banner__link"
          (click)="closeBanner()">No thanks, I do not want 50% off.
        </a>
      </div>
    </div>
  </div>
  `
})
export class LeadBannerComponent implements AfterViewInit {
  brandData = (BrandData as any).default;

  @ViewChild('banner') banner: ElementRef;
  @ViewChild('bannerContainer') bannerContainer: ElementRef;

  // @Input() parent: FormGroup;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() submit: EventEmitter<string> = new EventEmitter<string>();

  form = this.fb.group({
    email: ['', EmailValidator.checkEmail]
  });

  constructor(
    private fb: FormBuilder
  ) { }

  ngAfterViewInit() {
    fromEvent(document, 'click')
      .pipe(
        map((e: MouseEvent) => `.${(e as any).target.classList[0]}`),
        filter((className: string) => !this.bannerContainer.nativeElement.querySelector(className)),
        filter((className: string) => {
          if (
            document.getElementById &&
            document.getElementById('intercom-container') &&
            document.getElementById('intercom-container').querySelector
          ) {
            return !document.getElementById('intercom-container').querySelector(className);
          } else {
            return true;
          }
        }),
      )
      .subscribe((className: string) => {
        this.closeBanner();
      });
  }

  closeBanner() {
    $(document.body).removeClass('fixed-body');
    this.close.emit();
  }

  submitEmail() {
    const email = this.form.get('email').value;
    if (email.length && !this.isFieldInvalid('email', 'invalidEmail')) {
      this.submit.emit(email);
      this.close.emit();
    }
  }

  isFieldInvalid(field: string, validation: string) {
    const control = this.form.get(field);
    return control.hasError(validation) && control.touched;
  }
}
