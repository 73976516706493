import * as mapKeys from 'lodash/mapKeys';
import { Shipment } from '@app/core/models/shipment.model';
import * as shipmentActions from '@app/store/actions/shipment.action';

export interface Entities {
  [id: string]: Shipment
}

export interface State {
  entities: Entities,
  loaded: boolean,
  loading: boolean,
  errors: any[]
}

export const initialState: State = {
  entities: {},
  loaded: true,
  loading: false,
  errors: []
};

export function reducer(
  state: State = initialState,
  action: shipmentActions.Actions): State {
  switch (action.type) {

    case shipmentActions.LOAD_SHIPMENTS:
    case shipmentActions.UPDATE_SHIPMENT:
    case shipmentActions.ADD_SHIPMENT: {
      return { ...state, loading: true, loaded: false };
    }

    case shipmentActions.LOAD_SHIPMENTS_COMPLETE: {
      const shipments = mapKeys(action.payload, '_id');
      const entities = { ...state.entities, ...shipments };
      return { ...state, entities, loaded: true, loading: false };
    }

    case shipmentActions.UPDATE_SHIPMENT_COMPLETE: {
      const entities = { ...state.entities, [action.payload._id]: action.payload };
      return { ...state, entities, loaded: true, loading: false };
    }

    case shipmentActions.ADD_SHIPMENT_COMPLETE: {
      const entities = { [action.payload._id]: action.payload, ...state.entities };
      return { ...state, entities, loaded: true, loading: false };
    }

    case shipmentActions.LOAD_SHIPMENTS_FAIL:
    case shipmentActions.ADD_SHIPMENT_FAIL:
    case shipmentActions.UPDATE_SHIPMENT_FAIL: {
      return { ...state, errors: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const getShipments = (state: State) => state.entities;
export const getShipmentLoaded = (state: State) => state.loaded;
export const getShipmentLoading = (state: State) => state.loading;
export const getShipmentErrors = (state: State) => state.errors;
