import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';

import { FormBuilder, FormGroup } from '@angular/forms';
import { GooglePlacesService } from '@app/core/services';

@Component({
  selector: 'sucstu-address-form',
  styleUrls: ['./address-form.component.scss'],
  templateUrl: './address-form.component.html'
})
export class AddressFormComponent implements OnInit, AfterViewInit {

  @Input() form: FormGroup;
  @Input() showErrors: any;

  showLabelPreview = false;
  addressId = this.googlePlacesService.generateId();
  statesError: boolean;
  showAllFields = false;
  addressError: any;
  states = [];

  constructor(
    private cd: ChangeDetectorRef,
    private googlePlacesService: GooglePlacesService,
  ) { }

  ngOnInit() {
    const toCapitalize = text => text.split(' ').map(w => w.slice(0, 1).toUpperCase() + w.slice(1)).join(' ');
    this.states = this.googlePlacesService
      .getAvailableStates()
      .map(item => ({ ...item, state: toCapitalize(item.state) }));

    const labelControl = this.form.get('label');
    this.form.valueChanges.subscribe(value => {
      labelControl.setValue(this.createLabel(value), { emitEvent: false });
    })
  }

  ngAfterViewInit() {
    this.googlePlacesService.listenGooglePlaces(`address-${this.addressId}`, (data: any) => {

      // 1. The user is trying to manually set their address.
      if (this.showAllFields) {
        return null;
      }

      // 2. There was an error trying to find the user address.
      if (data.error && !this.showAllFields) {
        this.addressError = data.error;
        if (data.streetNumber && data.route) {
          this.form.get('shippingAddress').setValue(`${data.streetNumber} ${data.route}`);
        }
        this.form.get('label').setValue('');
        this.cd.detectChanges();
        return;
      }

      // 3. The address was found!
      this.addressError = undefined;
      console.log('FORM VALUE', this.form.value);
      console.log('DATA', data);
      this.form.setValue({
        ...this.form.value,
        ...data,
        shippingAddressExtra: this.form.value.shippingAddressExtra !== ''
          ? this.form.value.shippingAddressExtra
          : '',
      });
      this.form.get('label').setValue(this.createLabel(this.form.value));
      this.showLabelPreview = true;
      this.cd.detectChanges();
    });
  }

  toggleShowAllFields() {
    this.showAllFields = !this.showAllFields;
    this.addressError = undefined;
  }

  isFieldValid(field: string, validation: string) {
    const control = this.form.get(field);
    return control.hasError(validation) && control.touched;
  }

  createLabel(address) {
    let label = `${address.shippingAddress}`;
    if (address.shippingAddressExtra.length) {
      label += `\n${address.shippingAddressExtra}\n`;
    }
    label += `${address.city} ${address.stateCode} ${address.zip}`;
    return label;
  }

  onChangeState() {
    const stateText = this.form.get('state').value;
    const state = this.states.find(item => item.state === stateText);

    if (!state) {
      this.form.get('state').setValue('');
      this.form.get('stateCode').setValue('');
      return this.statesError = false;
    }

    this.form.get('stateCode').setValue(state.stateCode.toUpperCase());
    this.statesError = false;
  }
}
