import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Meta, Title } from '@angular/platform-browser';
import * as MetaTagsData from "@config/metatags.json"
import * as TitleTags from "@config/titletags.json"

// actions
import * as fromCouponsActions from './../../../store/actions/coupon.actions';

@Component({
  selector: 'sucstu-apartment-therapy',
  styleUrls: ['./apartment-therapy.component.scss'],
  templateUrl: './apartment-therapy.component.html'
})
export class ApartmentTherapyComponent implements OnInit {
  metaTagsData = (MetaTagsData as any).default;
  titleTagsData = (TitleTags as any).default;

  constructor(
    private router: Router,
    private store: Store<any>,
    private metaService: Meta,
    private titleService: Title,
  ) {
  }

  ngOnInit() {
    this.metaService.updateTag({
      name: 'description',
      content: this.metaTagsData.apartmentTherapy
    });

    this.titleService.setTitle(this.titleTagsData.apartmentTherapy);
  }

  applyCoupon() {
    this.router.navigate(['/checkout'], { queryParamsHandling: 'preserve' })
      .then(
        () => this.store.dispatch(new fromCouponsActions.VerifyCouponAction('ATFREE'))
      );
  }

}
