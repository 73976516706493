import { createSelector } from '@ngrx/store';

import * as fromRoot from '@app/store/selectors';
import * as fromPlans from '@app/store/reducers/plans.reducer';
import { Dictionary } from '@ngrx/entity/src/models';
import { Plan } from '@app/core/models/plan.model';

export { State } from '@app/store/reducers/plans.reducer';

export const {
  selectIds: getPlansIds,
  selectEntities: getPlansEntities,
  selectAll: getAllPlans,
  selectTotal: getTotalPlans
 } = fromPlans.adapter.getSelectors(fromRoot.getPlansState);

export const getPlansLoaded = createSelector(
  fromRoot.getPlansState,
  fromPlans.getPlansLoaded
);

export const getPlansLoading = createSelector(
  fromRoot.getPlansState,
  fromPlans.getPlansLoading
);

export const getPlansError = createSelector(
  fromRoot.getPlansState,
  fromPlans.getPlansError
);

export const getSelectedPlanId = createSelector(
  fromRoot.getPlansState,
  fromPlans.getSelectedPlanId
);

export const getPlanById = id => createSelector(
  fromRoot.getPlansState,
  ({ entities }) => entities[id]
);

export const getSelectedPlan = createSelector(
  getSelectedPlanId,
  getPlansEntities,
  (id, entities) => entities[id]
);
