import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IRedirectAction } from '@app/core/models/action.model';
import { APP_CONFIG, AppConfig } from '@app/app.config';

@Injectable()
export class ActionsService {

  constructor(
    @Inject(APP_CONFIG) private app_config: AppConfig,
    private httpClient: HttpClient,
  ) {}

  createRedirectAction(data: ICreateActionsParams) {
    return this.httpClient.post(`${this.app_config.api_uri}/actions/`, data);
  }

  createAction(data) {
    return this.httpClient.post(`${this.app_config.api_uri}/actions/`, data);
  }
}

export interface ICreateActionsParams {
  type: string;
  value: string;
  subscription?: number;
  details?: any;
}
