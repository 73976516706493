import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// service
import { AuthService } from '@app/auth/services/auth.service';
import { NotificationBarService } from '@app/core/services/notification-bar.service';

// validators
import { EmailValidator } from '@app/shared/validators/email.validator';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'sucstu-auth-remember-password',
  styleUrls: ['./remember-password.component.scss'],
  template: `
    <div class="login-container container">

      <h2 class="login-container__title">Forgot Password</h2>

      <form class="login-form" [formGroup]="form" (ngSubmit)="onSubmit()">

        <div class="form-group form-group__flex-start">
          <input #emailInput [ngClass]="{ 'input-error': isFieldValid('email', 'invalidEmail') }" class="form-group__input" type="email"
            placeholder="Email" formControlName="email" name="email" autocomplete="email" (change)="clearErrors()">
          <span class="error-text" *ngIf="isFieldValid('email', 'invalidEmail')">Please use a valid email</span>
          <span class="error-text" *ngIf="error">{{ error }}</span>
        </div>

        <div class="form-button-container">
          <button
            class="btn btn-primary btn-primary--with-hover"
            type="submit">
            Send
          </button>
        </div>

      </form>

    </div>
  `
})
export class RememberPasswordComponent {

  displayErrors = false;
  error: string;

  form = this.fb.group({
    email: ['', [Validators.required, EmailValidator.checkEmail]],
  });

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private notificationBarService: NotificationBarService
  ) {  }

  onSubmit() {
    if (this.form.invalid) {
      this.displayErrors = true;
      return;
    }

    this.authService
      .forgotPassword(this.form.get('email').value)
      .subscribe(
        (data: any) => {
          this.notificationBarService.open({
            title: '',
            message: data.message,
            type: 'success'
          });
          this.router.navigate(['/login']);
        },
        error => this.error = error.error.message
      );
  }

  clearErrors() {
    this.error = undefined;
  }

  isFieldValid(field: string, validation: string) {
    const control = this.form.get(field);
    return control.hasError(validation) && this.displayErrors;
  }

}
