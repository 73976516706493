import * as tslib_1 from "tslib";
import * as find from 'lodash/find';
import * as without from 'lodash/without';
var ModalService = /** @class */ (function () {
    function ModalService() {
        this.modals = [];
    }
    ModalService.prototype.getModal = function (id) {
        var modal = find(this.modals, { id: id });
        return modal;
    };
    ModalService.prototype.add = function (modal) {
        this.modals.push(modal);
    };
    ModalService.prototype.remove = function (id) {
        var modalToRemove = find(this.modals, { id: id });
        this.modals = without(this.modals, modalToRemove);
    };
    ModalService.prototype.open = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modal = find(this.modals, { id: id });
                        this.modals.forEach(function (item) {
                            if (item.isOpen) {
                                item.close();
                            }
                        });
                        return [4 /*yield*/, modal.open()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, Promise.resolve()];
                }
            });
        });
    };
    ModalService.prototype.close = function (id) {
        var modal = find(this.modals, { id: id });
        modal.close();
    };
    return ModalService;
}());
export { ModalService };
