import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import * as payment from 'payment';

@Directive({ selector: '[ccCvc]' })
export class CardCvcFormatDirective {

  cardType: string;
  constructor(private el: ElementRef) {
    const element = this.el.nativeElement;

    // call lib functions
    payment.formatCardCVC(element);
    payment.restrictNumeric(element);
  }
}
