import { Injectable } from '@angular/core';
import { CanActivate, Route } from '@angular/router';

@Injectable()
export class RouterTransitionGuard implements CanActivate {
  constructor() { }

  canActivate() {
    return new Promise<boolean>((resolve, reject) => {
      setTimeout(() => resolve(true), 1000);
    });
  }
}
