import * as tslib_1 from "tslib";
import { createEntityAdapter } from '@ngrx/entity';
import * as subscriptionActions from '@app/store/actions/subscription.action';
export var adapter = createEntityAdapter({
    selectId: function (susbcription) { return susbcription._id; },
    sortComparer: false
});
export var initialState = adapter.getInitialState({
    loading: false,
    loaded: false,
    error: null,
    selected: null
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // show is loading when:
        case subscriptionActions.UPDATE_SUBSCRIPTION_ADDRESS:
        case subscriptionActions.CANCEL_SUBSCRIPTION:
        case subscriptionActions.CANCEL_GIFT_IT:
        case subscriptionActions.GIFT_IT_SUBSCRIPTION:
        case subscriptionActions.TURN_OFF_SUBSCRIPTION:
        case subscriptionActions.TURN_ON_SUBSCRIPTION:
        case subscriptionActions.RE_ACTIVATE_SUBSCRIPTION:
        case subscriptionActions.SKIP_IT_SUBSCRIPTION:
        case subscriptionActions.UN_SKIP_SUBSCRIPTION:
        case subscriptionActions.UNSELECT_BOX:
        case subscriptionActions.UPDATE_SUBSCRIPTION: {
            var id = action.payload.subscriptionId;
            var changes = {
                id: id,
                changes: { isLoading: true }
            };
            return adapter.updateOne(changes, tslib_1.__assign({}, state, { loaded: true, loading: false }));
        }
        case subscriptionActions.UPDATE_SUBSCRIPTION_ADDRESS:
        case subscriptionActions.UPDATE_SUBSCRIPTION:
        case subscriptionActions.UPDATE_SUBSCRIPTION_NBD:
        case subscriptionActions.LOAD_SUBSCRIPTIONS:
        case subscriptionActions.ADD_SUBSCRIPTION:
        case subscriptionActions.SWITCH_2x_BUNDLE: {
            return tslib_1.__assign({}, state, { loading: true, loaded: false });
        }
        case subscriptionActions.LOAD_SUBSCRIPTIONS_COMPLETE: {
            return adapter.addAll(action.payload, tslib_1.__assign({}, state, { loaded: true, loading: false, error: null }));
        }
        case subscriptionActions.ADD_SUBSCRIPTION_COMPLETE: {
            return adapter.addOne(action.payload, tslib_1.__assign({}, state, { loaded: true, loading: false }));
        }
        case subscriptionActions.CHANGE_PLAN_COMPLETE:
        case subscriptionActions.UPDATE_SUBSCRIPTION_NBD_COMPLETE:
        case subscriptionActions.UPDATE_SUBSCRIPTION_ADDRESS_COMPLETE:
        case subscriptionActions.UPDATE_SUBSCRIPTION_COMPLETE: {
            return adapter.updateOne(action.payload, tslib_1.__assign({}, state, { loaded: true, loading: false }));
        }
        case subscriptionActions.UPDATE_SUBSCRIPTIONS_COMPLETE: {
            return adapter.updateMany(action.payload, tslib_1.__assign({}, state, { loaded: true, loading: false }));
        }
        case subscriptionActions.CHANGE_PLAN_FAIL:
        case subscriptionActions.UPDATE_SUBSCRIPTION_NBD_FAIL:
        case subscriptionActions.LOAD_SUBSCRIPTIONS_FAIL:
        case subscriptionActions.ADD_SUBSCRIPTION_FAIL:
        case subscriptionActions.UNSELECT_BOX_FAIL:
        case subscriptionActions.UPDATE_SUBSCRIPTION_ADDRESS_FAIL:
        case subscriptionActions.UPDATE_SUBSCRIPTION_FAIL: {
            return tslib_1.__assign({}, state, { error: action.payload });
        }
        case subscriptionActions.SELECT_SUBSCRIPTION: {
            return tslib_1.__assign({}, state, { selected: action.payload });
        }
        case subscriptionActions.DESELECT_SUBSCRIPTION: {
            return tslib_1.__assign({}, state, { selected: null });
        }
        default: {
            return state;
        }
    }
}
export var getSubscriptions = function (state) { return state.entities; };
export var getSubscriptionLoaded = function (state) { return state.loaded; };
export var getSubscriptionLoading = function (state) { return state.loading; };
export var getSubscriptionError = function (state) { return state.error; };
export var getSubscriptionSelected = function (state) { return state.selected; };
