import { Injectable } from '@angular/core';

// Date utilities
import * as format from 'date-fns/format';
import * as addDays from 'date-fns/add_days';
import * as startOfWeek from 'date-fns/start_of_week';
import * as startOfDay from 'date-fns/start_of_day';
import * as startOfMonth from 'date-fns/start_of_month';
import * as endOfMonth from 'date-fns/end_of_month';
import * as getDay from 'date-fns/get_day';
import * as setMonth from 'date-fns/set_month';
import * as setIsoDay from 'date-fns/set_iso_day';

import * as includes from 'lodash/includes';

@Injectable()
export class DatePickerService {
  private datesBase = [
    '01-01', // new year
    '01-02', // new year
    '01-15',
    '02-19',
    '05-28',
    this.getMemorialDate(),
    // '09-03',
    '07-04', // July 4
    this.getLaborDate(),
    '10-08',
    '11-11',
    '11-12',
    '11-23',
    '11-24',
    this.getThanksGivingDate(),
    '12-25', // Christmas
    '12-26', // Christmas
  ]

  disabledDates = [
    ...this.datesBase.map(date => (`${ new Date().getFullYear() }-${ date }`)),
    ...this.datesBase.map(date => (`${ (new Date().getFullYear()) + 1 }-${ date }`)),
  ]
  disableDays = [0, 2, 3, 4, 5, 6];

  constructor() {
  }

  // First Monday of September
  getLaborDate() {
    let date = setIsoDay(startOfMonth(setMonth(new Date(), 8)), 8);
    if (date.getDate() > 7) {
      date = setIsoDay(date, -6)
    }
    return format(date, 'MM-DD');
  }

  // Last Monday of May
  getMemorialDate() {
    let date = setIsoDay(
      startOfWeek(
        endOfMonth(setMonth(new Date(), 4)),
      ),
      8
    );
    if (date.getMonth() > 4) {
      date = setIsoDay(date, -6)
    }

    return format(date, 'MM-DD');
  }

  // Last thursday of November
  getThanksGivingDate() {
    let date = setIsoDay(
      startOfWeek(
        endOfMonth(setMonth(new Date(), 10)),
      ),
      11
    );
    if (date.getMonth() > 10) {
      date = setIsoDay(date, -3)
    }

    return format(date, 'MM-DD');
  }

  getNextAvailableDay(today = startOfDay(new Date())) {
    const dayOfWeek = getDay(today);
    const formatDate = format(today, 'YYYY-MM-DD');

    if (includes(this.disableDays, dayOfWeek) || includes(this.disabledDates, formatDate)) {
      return this.getNextAvailableDay(addDays(today, 1));
    }

    return today;
  }
}
