/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./coupon-banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./coupon-banner.component";
var styles_CouponBannerComponent = [i0.styles];
var RenderType_CouponBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CouponBannerComponent, data: {} });
export { RenderType_CouponBannerComponent as RenderType_CouponBannerComponent };
export function View_CouponBannerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TitleCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "coupon-banner"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "coupon-active": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "coupon-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " Savings!"])), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 0, "p", [["class", "coupon-message"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "close-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeBanner() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["X"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "coupon-banner"; var currVal_1 = _ck(_v, 3, 0, (_co.showBanner && _co.coupon)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), ((_co.brandData == null) ? null : _co.brandData.mainProduct))); _ck(_v, 5, 0, currVal_2); var currVal_3 = ((_co.coupon == null) ? null : _co.coupon.bannerMessage); _ck(_v, 7, 0, currVal_3); }); }
export function View_CouponBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-coupon-banner", [], null, null, null, View_CouponBannerComponent_0, RenderType_CouponBannerComponent)), i1.ɵdid(1, 49152, null, 0, i3.CouponBannerComponent, [], null, null)], null, null); }
var CouponBannerComponentNgFactory = i1.ɵccf("sucstu-coupon-banner", i3.CouponBannerComponent, View_CouponBannerComponent_Host_0, { coupon: "coupon", showBanner: "showBanner" }, { close: "close" }, []);
export { CouponBannerComponentNgFactory as CouponBannerComponentNgFactory };
