import { MonthlyThanksComponent } from './monthly-thanks/monthly-thanks.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ThanksComponent } from './thanks/thanks.component';
import { LeadBannerComponent } from './lead-banner/lead-banner.component';
import { ModalComponent } from './modal/modal.component';
import { CouponBannerComponent } from './coupon-banner/coupon-banner.component';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { EditableInputComponent } from './editable-input/editable-input.component';
import { EditableAddressComponent } from './editable-address/editable-address.component';
import { EditablePaymentSelectComponent } from './editable-payment-select/editable-payment-select.component';
import { AddPaymentFormComponent } from './add-payment-form/add-payment-form.component';
import { SwitchButtonComponent } from './inputs/switch-button/switch-button.component';
import { AttributesBarComponent } from './attributes-bar/attributes-bar.component';
import { GiftMessageSelectionComponent } from './gift-message-selection/gift-message-selection.component';
import { MothersBannerComponent } from './mothers-banner/mothers-banner.component';
import { PlanCardComponent } from './plan-card/plan-card.component';
import { GiftFormComponent } from './gift-form/gift-form.component';
import { AddressFormComponent } from './address-form/address-form.component';


export const components = [
  GiftFormComponent,
  HeaderComponent,
  FooterComponent,
  ThanksComponent,
  LeadBannerComponent,
  ModalComponent,
  CouponBannerComponent,
  NotificationBarComponent,
  EditableInputComponent,
  EditableAddressComponent,
  AddressFormComponent,
  EditablePaymentSelectComponent,
  AddPaymentFormComponent,
  SwitchButtonComponent,
  AttributesBarComponent,
  MonthlyThanksComponent,
  GiftMessageSelectionComponent,
  MothersBannerComponent,
  PlanCardComponent,
];

export { AddressFormComponent } from './address-form/address-form.component';
export { GiftFormComponent } from './gift-form/gift-form.component';
export { MonthlyThanksComponent } from './monthly-thanks/monthly-thanks.component';
export { HeaderComponent } from './header/header.component';
export { FooterComponent } from './footer/footer.component';
export { ThanksComponent } from './thanks/thanks.component';
export { LeadBannerComponent } from './lead-banner/lead-banner.component';
export { ModalComponent } from './modal/modal.component';
export { CouponBannerComponent } from './coupon-banner/coupon-banner.component';
export { NotificationBarComponent } from './notification-bar/notification-bar.component';
export { EditableInputComponent } from './editable-input/editable-input.component';
export { EditableAddressComponent } from './editable-address/editable-address.component';
export { EditablePaymentSelectComponent } from './editable-payment-select/editable-payment-select.component';
export { AddPaymentFormComponent } from './add-payment-form/add-payment-form.component';
export { SwitchButtonComponent } from './inputs/switch-button/switch-button.component';
export { AttributesBarComponent } from './attributes-bar/attributes-bar.component';
export { PlanCardComponent } from '../components/plan-card/plan-card.component';
