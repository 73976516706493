import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sucstu-notification-bar',
  styleUrls: ['./notification-bar.component.scss'],
  template: `
    <div class="notification-bar" [ngClass]="{
      'notification-bar-active': data.open,
      'notification-bar-disabled': !data.open,
      'notification-bar--success': data.type === 'success',
      'notification-bar--error': data.type === 'error'
    }">
      <span class="notification-bar-title">{{ data.title }}</span>
      <p class="notification-bar-message">{{ data.message }}</p>
      <span class="close-icon" (click)="closeBanner()">X</span>
    </div>
  `
})

export class NotificationBarComponent {

  @Input() data: {
    title: string,
    message: string,
    type: string,
    open: boolean
  };

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  closeBanner() {
    this.close.emit();
  }
}
