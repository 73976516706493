import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthService } from '@app/auth/services/auth.service';
import { Store } from '@ngrx/store';
import { Meta, Title } from '@angular/platform-browser';
import { OwlCarousel } from 'ngx-owl-carousel';
import { APP_CONFIG, AppConfig } from '@app/app.config';
import * as HomeData from '@config/home.json'
import * as BrandData from '@config/brand.json'
import * as MetaTagsData from '@config/metatags.json'
import * as TitleTags from '@config/titletags.json'

// RxJS
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

// services
import * as fromServices from './../../../core/services';
import { UserService } from './../../../core/services';
import { ErrorService } from '@app/error';

// models
import { Plan } from '@app/core/models/plan.model';

// components
import { LeadBannerComponent } from '@app/shared/components';

// actions
import * as fromPlansActions from './../../../store/actions/plans.actions';
import * as coupons from '@app/store/actions/coupon.actions';

// selectors
import { isPlatformBrowser } from '@angular/common';
import {MarketingService} from '@app/core/services/marketing/marketing.service';

@Component({
  selector: 'sucstu-pages-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.template.html'
})
export class HomeComponent implements OnInit, AfterViewInit, AfterContentInit {
  clicked = false;

  homeData = (HomeData as any).default;
  brandData = (BrandData as any).default;
  metaTagsData = (MetaTagsData as any).default;
  titleTagsData = (TitleTags as any).default;

  @ViewChild('newsLetterEmail') newsLetterEmail: ElementRef;
  @ViewChild('homeLeadEmail') homeLeadEmail: ElementRef;
  @ViewChild('owlMobile') owlMobile: OwlCarousel;
  @ViewChild('owlDesk') owlDesk: OwlCarousel;

  @ViewChild('leadBannerComponent', {read: ViewContainerRef})
  leadBannerComponent: ViewContainerRef;

  mobileSliderOptions = {
    items: 1,
    dots: true,
    navigation: false,
    autoplay: true,
    autoplayTimeout: 5000,
    loop: true,
    mouseDrag: false,
    touchDrag: true
  };

  plans$: Observable<Plan[]>;

  isAuthenticated: boolean = this.authService.token;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private router: Router,
    private resolver: ComponentFactoryResolver,
    private leadService: fromServices.LeadsService,
    private store: Store<any>,
    private metaService: Meta,
    private titleService: Title,
    private plansService: fromServices.PlansService,
    private errorService: ErrorService,
    @Inject(APP_CONFIG) private app_config: AppConfig,
    @Inject(PLATFORM_ID) private platform: any,
    private userService: UserService,
    private readonly marketingService: MarketingService,
  ) {
  }

  ngOnInit() {
    this.metaService.updateTag({
      name: 'description',
      content: this.metaTagsData.home
    });

    this.titleService.setTitle(this.titleTagsData.home);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ((window as any).dataLayer && typeof (window as any).dataLayer.push === 'function') {
        (window as any).dataLayer.push({ 'event': 'optimize.activate' });
      }
    }, 0);

    // Temporal, this is to set the offer type in the user account, should be removed later
    if (
      isPlatformBrowser(this.platform)
      && !!this.route.snapshot.queryParams.offerType
      && !!this.route.snapshot.queryParams.userId
    ) {
      console.log(this.route.snapshot.queryParams)
      this.userService.setOfferType({
        offerType: this.route.snapshot.queryParams.offerType,
        userId: this.route.snapshot.queryParams.userId
      }).subscribe();
    }
  }

  ngAfterContentInit() {
    const utmData = this.route.snapshot.queryParams;
    this.route.queryParams
      .subscribe(queryParams => {
        const uuid = this.localStorage.retrieve('uuid');
        if (!(queryParams.vn === 'np')) {
          // show the LF
          const canBannerBeActive = this.localStorage.retrieve('isBannerActive');
          if (canBannerBeActive || canBannerBeActive === null) {
            const bannerFactory = this.resolver.resolveComponentFactory(LeadBannerComponent);
            const component = this.leadBannerComponent.createComponent(bannerFactory);
            const ua = navigator.userAgent;
            const iOS = /iPad|iPhone|iPod/.test(ua);
            const iOS11 = /OS 11/.test(ua);

            if (iOS && iOS11) {
              $(document.body).addClass('fixed-body');
            }

            component.instance.close
              .subscribe(() => {
                this.localStorage.store('isBannerActive', false);
                $(document.body).removeClass('fixed-body');
                component.destroy();
              });

            component.instance.submit
              .pipe(switchMap((email: string) => this.createLead(email)))
              .subscribe(
                () => {
                },
                error => {
                  const message = `Error trying to create/update Lead.`;
                  this.errorService.client.notify(error, {
                    beforeSend: report => {
                      report.severity = 'warning';
                      report.updateMetaData('extras', {
                        http: true,
                        client: true,
                        message,
                        error,
                      });
                    }
                  });
                }
              );
          }
        }
      });
  }

  moveSliderToLeft() {
    this.owlMobile.previous([200]);
    this.owlDesk.previous([200]);
  }

  moveSliderToRight() {
    this.owlMobile.next([200]);
    this.owlDesk.next([200]);
  }

  // onCreateLead() {
  //   const emailInput = this.newsLetterEmail.nativeElement as HTMLInputElement;
  //   const email = emailInput.value;
  //   this.createLead(email)
  //     .pipe(take(1))
  //     .subscribe(
  //       () => {},
  //       error => {
  //         const message = `Error trying to create/update Lead.`;
  //         this.errorService.client.notify(error, {
  //           beforeSend: report => {
  //             report.severity = 'warning';
  //             report.updateMetaData('extras', {
  //               http: true,
  //               client: true,
  //               message,
  //               error,
  //             });
  //           }
  //         });
  //       }
  //     );
  // }

  createLead(email: string = ''): Observable<any> {
    const uuid = this.localStorage.retrieve('uuid');
    const utmData = this.route.snapshot.queryParams;
    // const input = this.newsLetterEmail.nativeElement as HTMLInputElement;
    // email = email.length > 0 ? email : input.value;
    this.localStorage.store('customerEmail', email);
    return this.leadService
      .create({ uuid, email, utmData })
      .pipe(
        tap((data: any) => {
          if (this.app_config.production && this.app_config.name === 'production') {
            this.marketingService
              .reportLead(email)
              .subscribe();

            window['dataLayer'].push({
              'event': 'leadFormSubmited',
              'lead': {
                'email': email
              }
            });
          }
          this.store.dispatch(new coupons.VerifyCouponAction('FIFTYOFF'));
        })
      );
  }

  selectPlan() {
    const planId = this.plansService.REGULAR_PLAN;
    this.store.dispatch(new fromPlansActions.SelectPlanAction(planId));
    this.router.navigate(['/checkout'], {queryParamsHandling: 'preserve'});
  }

}
