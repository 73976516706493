import { Component } from '@angular/core';

@Component({
  selector: 'sucstu-signup',
  styleUrls: ['./signup.component.scss'],
  template: `
    <h1>Signup</h1>
  `
})
export class SignupComponent {

}
