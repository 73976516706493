import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare var google: any;

@Injectable()
export class GooglePlacesService {

  counter = 0;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  generateId() {
    return ++this.counter;
  }

  getAddressComponent(type, components, field) {
    const componentIndex = components
      .findIndex(ac => {
        const typeIndex = ac.types.findIndex(t => t === type);
        return typeIndex !== -1;
      });

    const component = components[componentIndex];

    return typeof component !== 'undefined'
      ? {
        ...component,
        field,
        exists: true
      }
      : {
        short_name: '',
        long_name: '',
        field,
        exists: false
      };
  }

  onChangeAddress(autocomplete, cb) {
    const address = autocomplete.getPlace();

    if (!address || !address.address_components) {
      return cb({ error: `The address is wrong or is not completed.` });
    }

    const addressComponents = address.address_components;
    const city = this.getAddressComponent('locality', addressComponents, 'city');
    const city2 = this.getAddressComponent('sublocality', addressComponents, 'city');
    const country = this.getAddressComponent('country', addressComponents, 'country');
    const state = this.getAddressComponent('administrative_area_level_1', addressComponents, 'state');
    const postalCode = this.getAddressComponent('postal_code', addressComponents, 'postal code');
    const streetNumber = this.getAddressComponent('street_number', addressComponents, 'street number');
    const route = this.getAddressComponent('route', addressComponents, 'route');
    const postalCodeSuffix = this.getAddressComponent('postal_code_suffix', addressComponents, 'postal code suffix');

    if (
      (streetNumber.exists) &&
      (city.exists || city2.exists) &&
      (country.exists) &&
      (state.exists) &&
      (postalCode.exists) &&
      (route.exists)
    ) {
      const data = {
        city: city.exists ? city.long_name : city2.long_name,
        country: country.long_name,
        countryCode: country.short_name,
        formattedLine: `${streetNumber.short_name} ${route.short_name}`,
        label: '',
        line: `${streetNumber.short_name} ${route.short_name}`,
        shippingAddress: `${streetNumber.short_name} ${route.short_name}`,
        state: state.long_name,
        stateCode: state.short_name,
        zip: `${postalCode.short_name}${postalCodeSuffix.exists ? '-' + postalCodeSuffix.short_name : ''}`,
      };

      cb(data);
    } else {
      const createText = (arr: any[]) => arr.reduce((text, current) =>
        (current.long_name || current.short_name) ? text : `${text} ${current.field},`, '');

      cb({
        error: `The address is wrong or is not completed, you are missing: ${createText([
          city, country, state, postalCode, route, streetNumber
        ]).slice(0, -1)}.`,
        streetNumber: streetNumber.short_name,
        route: route.short_name
      });
    }

  }

  listenGooglePlaces(elementId, cb): void {
    if (!isPlatformBrowser(this.platformId)) {
      return null
    }

    this.counter++;
    const addressInput = document.getElementById(elementId);
    if (typeof google === 'undefined' || addressInput === null) {
      console.log('Google places is not loaded');
      return null;
    }

    const autocomplete = new google.maps.places.Autocomplete(addressInput, {
      types: ['address'],
      componentRestrictions: { country: 'US' }
    });
    autocomplete.addListener('place_changed', (data) => this.onChangeAddress(autocomplete, cb));

    addressInput.addEventListener('blur', () => {
      setTimeout(this.onChangeAddress(autocomplete, cb), 10);
    });
  }

  getAvailableStates() {
    const states = [
      { stateCode: 'al', state: 'alabama' },
      { stateCode: 'ak', state: 'alaska' },
      { stateCode: 'az', state: 'arizona' },
      { stateCode: 'ar', state: 'arkansas' },
      { stateCode: 'ca', state: 'california' },
      { stateCode: 'co', state: 'colorado' },
      { stateCode: 'ct', state: 'connecticut' },
      { stateCode: 'de', state: 'delaware' },
      { stateCode: 'dc', state: 'district of columbia' },
      { stateCode: 'fl', state: 'florida' },
      { stateCode: 'ga', state: 'georgia' },
      { stateCode: 'hi', state: 'hawaii' },
      { stateCode: 'id', state: 'idaho' },
      { stateCode: 'il', state: 'illinois' },
      { stateCode: 'in', state: 'indiana' },
      { stateCode: 'ia', state: 'iowa' },
      { stateCode: 'ks', state: 'kansas' },
      { stateCode: 'ky', state: 'kentucky' },
      { stateCode: 'la', state: 'louisiana' },
      { stateCode: 'me', state: 'maine' },
      { stateCode: 'md', state: 'maryland' },
      { stateCode: 'ma', state: 'massachusetts' },
      { stateCode: 'mi', state: 'michigan' },
      { stateCode: 'mn', state: 'minnesota' },
      { stateCode: 'ms', state: 'mississippi' },
      { stateCode: 'mo', state: 'missouri' },
      { stateCode: 'mt', state: 'montana' },
      { stateCode: 'ne', state: 'nebraska' },
      { stateCode: 'nv', state: 'nevada' },
      { stateCode: 'nh', state: 'new hampshire' },
      { stateCode: 'nj', state: 'new jersey' },
      { stateCode: 'nm', state: 'new mexico' },
      { stateCode: 'ny', state: 'new york' },
      { stateCode: 'nc', state: 'north carolina' },
      { stateCode: 'nd', state: 'north dakota' },
      { stateCode: 'oh', state: 'ohio' },
      { stateCode: 'ok', state: 'oklahoma' },
      { stateCode: 'or', state: 'oregon' },
      { stateCode: 'pa', state: 'pennsylvania' },
      { stateCode: 'ri', state: 'rhode island' },
      { stateCode: 'sc', state: 'south carolina' },
      { stateCode: 'sd', state: 'south dakota' },
      { stateCode: 'tn', state: 'tennessee' },
      { stateCode: 'tx', state: 'texas' },
      { stateCode: 'ut', state: 'utah' },
      { stateCode: 'vt', state: 'vermont' },
      { stateCode: 'va', state: 'virginia' },
      { stateCode: 'wa', state: 'washington' },
      { stateCode: 'wv', state: 'west virginia' },
      { stateCode: 'wi', state: 'wisconsin' },
      { stateCode: 'wy', state: 'wyoming' },
    ];

    return states;
  }
}
