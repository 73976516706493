import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';

import * as addressActions from '@app/store/actions/address.actions';

// models
import { Address } from '@app/core/models/address.model';

// services
import { AddressService } from '@app/core/services/address.service';

@Injectable()
export class AddressEffects {

  @Effect({ dispatch: false }) load$ = this.actions$
    .pipe(
      ofType(addressActions.LOAD_ADDRESSES),
      switchMap(() => {
        return this.addressService
          .getAddresses()
          .pipe(
            map(addresses => this.store.dispatch(new addressActions.LoadAddressesCompleteAction(addresses))),
            catchError(reason => {
              this.store.dispatch(new addressActions.LoadAddressesFailAction(reason.errors));
              return of(new addressActions.LoadAddressesFailAction(reason.errors));
            })
          );
      })
    );


  @Effect({ dispatch: false }) update$ = this.actions$
    .pipe(
      ofType(addressActions.UPDATE_ADDRESS),
      map((action: addressActions.UpdateAddressesAction) => action.payload),
      switchMap((data) => {
        return this.addressService.updateAddress(data)
          .pipe(
            map((address: Address) => this.store.dispatch(new addressActions.UpdateAddressesCompleteAction({
              id: address._id,
              changes: address
            }))),
            catchError(reason => {
              this.store.dispatch(new addressActions.UpdateAddressesFailAction(reason.errors));
              return of(new addressActions.UpdateAddressesFailAction(reason.errors));
            }),
          );
      })
    );

  constructor(
    private addressService: AddressService,
    private actions$: Actions,
    private store: Store<any>
  ) { }

}

