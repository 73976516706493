import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer,
  SimpleChanges,
  ViewChild,
  ContentChild,
  AfterContentInit,
} from '@angular/core';
import {
  FormBuilder,
  Validators
} from '@angular/forms';

// models
import { PaymentMethod } from '@app/core/models/payment-method';

// braintree library
import * as client from 'braintree-web/client';
import * as hostedFields from 'braintree-web/hosted-fields';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'sucstu-add-payment-form',
  styleUrls: ['./add-payment-form.component.scss'],
  templateUrl: 'add-payment-form.component.html'
})
export class AddPaymentFormComponent implements OnInit, AfterViewInit, OnChanges, AfterContentInit {

  @ViewChild('creditCardNumberField') creditCardNumberField: ElementRef;
  @ContentChild('saveButton') saveButton: ElementRef;
  @ContentChild('cancelButton') cancelButton: ElementRef;

  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() showLoader: EventEmitter<any> = new EventEmitter<any>();
  @Output() hideLoader: EventEmitter<any> = new EventEmitter<any>();

  @Input() braintreeClientToken: string;
  @Input() loading = false;

  canSubmitPayment = true;
  isFocus = false;

  isFormValid = false;
  isBraintreeClientInitialized = false;
  hideNewPaymentMethod = false;
  selectedPaymentMethod: PaymentMethod;

  isNumberValid: boolean;
  isCvvValid: boolean;
  isExpDateValid: boolean;

  showErrorMessages = false;
  showTokenizeError = false;
  isNumberTouched = false;
  isCvvTouched = false;
  isExpDateTouched = false;

  // braintree clien sdk
  hostedFields: any;

  fields = {
    number: {
      selector: '#card-number',
      placeholder: 'Card Number'
    },
    cvv: {
      selector: '#cvv',
      placeholder: '123'
    },
    expirationDate: {
      selector: '#expiration-date',
      placeholder: '12/2019'
    }
  };

  styles = {
    'input': {
      'font-size': '14px',
      'font-family': '"Omnes Regular", Helvetica, sans-serif',
      'font-weight': '300',
      'margin-top': '5px'
    },
    '.input-error::placeholder': {
      'color': 'red'
    }
  };

  form = this.fb.group({
    nonce: ['', Validators.required],
    lastTwo: ['', Validators.required],
    cardType: ['', Validators.required],
    type: ['', Validators.required],
    description: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private _renderer: Renderer
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.braintreeClientToken &&
      changes.braintreeClientToken.currentValue &&
      changes.braintreeClientToken.currentValue.length) {
      this.initializeBraintreeClientSDK();
    }
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    setTimeout(() =>
      this._renderer.invokeElementMethod(this.creditCardNumberField.nativeElement, 'focus', [])
    );
  }

  ngAfterContentInit() {
    fromEvent(this.saveButton.nativeElement, 'click').subscribe(() => this.onSave());
    fromEvent(this.cancelButton.nativeElement, 'click').subscribe(() => this.onCancel());
  }

  onSave() {
    const paymentMethod = this.form.value;
    this.showLoader.emit();
    if (!paymentMethod.token && this.isFormValid) {
      this.isBraintreeClientInitialized = false;
      this.hostedFields.tokenize()
        .then(payload => {
          this.isBraintreeClientInitialized = true;
          this.form.patchValue({
            nonce: payload.nonce,
            lastTwo: payload.details.lastTwo,
            cardType: payload.details.cardType,
            type: payload.type,
            description: payload.description
          });
          this.save.emit(this.form.value);
          this.showErrorMessages = false;
          this.showTokenizeError = false;
        })
        .catch(err => {
          this.hideLoader.emit();
          this.showErrorMessages = true;
          this.showTokenizeError = true;
          this.checkAndAddInputErrorClass();
        });
    } else {
      this.hideLoader.emit();
      this.showErrorMessages = true;
      this.checkAndAddInputErrorClass();
    }
  }

  onCancel() {
    this.hideLoader.emit();
    this.cancel.emit();
  }

  /**
   * Initialize the Braintree Client sdk.
   * This methods needs to be called every time the
   * payment form is in edit mode (is not saved) in order
   * to re-initialize the client sdk with the new data.
   *
   * @memberof SecurePaymentFormComponent
   */
  initializeBraintreeClientSDK() {
    client
      .create({ authorization: this.braintreeClientToken })
      .then(client => hostedFields.create({ client, fields: this.fields, styles: this.styles }))
      .then(hostedFields => {
        this.isBraintreeClientInitialized = true;
        this.hostedFields = hostedFields;
        this.hostedFields.on('validityChange', e => {
          const state = this.hostedFields.getState();
          const formValid = Object
            .keys(state.fields)
            .every((key) => {
              this.isCvvValid = state.fields.cvv.isValid;
              this.isNumberValid = state.fields.number.isValid;
              this.isExpDateValid = state.fields.expirationDate.isValid;
              return state.fields[key].isValid;
            });
          this.isFormValid = formValid ? true : false;
        });

        this.hostedFields.on('focus', e => {
          switch (e.emittedBy) {
            case 'number': {
              this.isNumberTouched = e.fields.number.isFocused ? true : this.isNumberTouched;
              break;
            }
            case 'cvv': {
              this.isCvvTouched = e.fields.cvv.isFocused ? true : this.isCvvTouched;
              break;
            }
            case 'expirationDate': {
              this.isExpDateTouched = e.fields.expirationDate.isFocused ? true : this.isExpDateTouched;
              break;
            }
          }
        });

      })
      .catch(e => console.log(e));
  }

  checkAndAddInputErrorClass() {
    if (!this.isNumberValid && this.isNumberTouched && this.showErrorMessages) {
      this.hostedFields.clear('number');
      this.hostedFields.addClass('number', 'input-error');
    }
    if (!this.isCvvValid && this.isCvvTouched && this.showErrorMessages) {
      this.hostedFields.clear('cvv');
      this.hostedFields.addClass('cvv', 'input-error');
    }
    if (!this.isExpDateValid && this.isExpDateTouched && this.showErrorMessages) {
      this.hostedFields.clear('expirationDate');
      this.hostedFields.addClass('expirationDate', 'input-error');
    }
  }
}
