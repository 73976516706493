import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as BrandData from "@config/brand.json"

import { Coupon } from './../../../core/models/coupon.model';

@Component({
  selector: 'sucstu-coupon-banner',
  styleUrls: ['./coupon-banner.component.scss'],
  template: `
    <div class="coupon-banner" [ngClass]="{ 'coupon-active': showBanner && this.coupon }">
      <span class="coupon-title">{{ brandData?.mainProduct | titlecase }} Savings!</span>
      <p class="coupon-message" [innerHTML]="coupon?.bannerMessage"></p>
      <span class="close-icon" (click)="closeBanner()">X</span>
    </div>
  `
})

export class CouponBannerComponent {
  brandData = (BrandData as any).default;

  @Input() coupon: Coupon;
  @Input() showBanner = false;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  closeBanner() {
    this.close.emit();
  }
}
