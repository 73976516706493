import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
// actions
import * as fromPaymentMethodActions from './../actions/payment-methods.actions';
import * as fromSubscriptionsActions from './../actions/subscription.action';
// models
import { PaymentMethod } from '@app/core/models/payment-method';
// services
import { UserService } from './../../core/services/user.service';
import { NotificationBarService } from './../../core/services/notification-bar.service';
import { Subscription } from '@app/core/models/subscription.model';

@Injectable()
export class PaymentMethodEffects {

  @Effect({ dispatch: false }) add$ = this.actions$
    .pipe(
      ofType(fromPaymentMethodActions.ADD_PAYMENT_METHOD),
      map((action: fromPaymentMethodActions.AddPaymentMethodAction) => action.payload),
      switchMap((nonce: string) => {
        return this.userService.addNewPaymentMethod({ nonce })
          .pipe(
            map((data: any) => {
              this.store.dispatch(new fromPaymentMethodActions.AddPaymentMethodCompleteAction(data));
            }),
            catchError(reason => {
              this.store.dispatch(new fromPaymentMethodActions.AddPaymentMethodFailAction(reason.error));
              this.notificationService.open({
                title: 'Oh oh!',
                message: reason.error.message,
                type: 'error'
              });
              return of(new fromPaymentMethodActions.AddPaymentMethodFailAction(reason.error));
            })
          );
      })
    );

  @Effect({ dispatch: false }) update$ = this.actions$
    .pipe(
      ofType(fromPaymentMethodActions.UPDATE_PAYMENT_METHOD),
      map((action: fromPaymentMethodActions.UpdatePaymentMethodAction) => action.payload),
      switchMap(({ paymentMethodId, nonce }) => {
        return this.userService.updatePaymentMethod({ paymentMethodId, nonce })
          .pipe(
            map(({ paymentMethod }: { paymentMethod: PaymentMethod }) => {
              this.store.dispatch(new fromPaymentMethodActions.UpdatePaymentMethodCompleteAction({
                id: paymentMethod._id,
                changes: paymentMethod
              }));
            }),
            catchError(reason => {
              this.store.dispatch(new fromPaymentMethodActions.UpdatePaymentMethodFailAction(reason.error));
              return of(new fromPaymentMethodActions.UpdatePaymentMethodFailAction(reason.error));
            })
          );
      })
    );

  @Effect({ dispatch: false }) updateWithRebill$ = this.actions$
    .pipe(
      ofType(fromPaymentMethodActions.UPDATE_PAYMENT_METHOD_WITH_REBILL),
      map((action: fromPaymentMethodActions.UpdatePaymentMethodWithRebillAction) => action.payload),
      switchMap(({ paymentMethodId, nonce }) => {
        return this.userService.updatePaymentMethodAndRebill({ paymentMethodId, nonce })
          .pipe(
            map(({ subscriptions, paymentMethod }: { subscriptions: Subscription[], paymentMethod: PaymentMethod }) => {
              this.store.dispatch(new fromPaymentMethodActions.UpdatePaymentMethodCompleteAction({
                id: paymentMethod._id,
                changes: paymentMethod
              }));
              this.notificationService.open({
                title: 'Payment method updated!',
                message: 'Your payment profile has been updated successfully.',
                type: 'success'
              });

              if (subscriptions && subscriptions.length) {
                this.store.dispatch(new fromSubscriptionsActions.LoadSubscriptionsCompleteAction(subscriptions));
              }
            }),
            catchError(reason => {
              this.store.dispatch(new fromPaymentMethodActions.UpdatePaymentMethodFailAction(reason.error));
              this.notificationService.open({
                title: 'Oh oh!',
                message: reason.error.message,
                type: 'error'
              });
              return of(new fromPaymentMethodActions.UpdatePaymentMethodFailAction(reason.error));
            })
          );
      })
    );

  constructor(
    private userService: UserService,
    private notificationService: NotificationBarService,
    private actions$: Actions,
    private store: Store<any>
  ) { }

}

