import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// components
import { HomeComponent } from '@app/pages/containers';
import { ApartmentTherapyComponent } from '@app/pages/containers/apartment-therapy/apartment-therapy.component';
// guards
// import { RouterTransitionGuard } from './core/guards/router-transition.guard';
// resolvers
import { SubscriptionInfoResolver } from './core/resolvers/subscription-info.resolver';
// shared components
import { MonthlyThanksComponent, ThanksComponent } from '@app/shared/components';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },

  // Temporal path
  {
    path: 'update-account',
    component: HomeComponent,
  },
  { path: '', loadChildren: 'app/gifts/gifts.module#GiftsModule' },
  { path: 'checkout', loadChildren: 'app/checkout/checkout.module#CheckoutModule' },
  {
    path: 'apartment-therapy',
    component: ApartmentTherapyComponent,
  },
  { path: 'checkout', component: HomeComponent },
  { path: 'profile', loadChildren: 'app/my-account/my-account.module#MyAccountModule' },
  { path: 'claim-checkout', loadChildren: 'app/claim-checkout/claim-checkout.module#ClaimCheckoutModule' },
  // { path: 'blog', loadChildren: 'app/blog/blog.module#BlogModule' },
  { path: 'boxes', loadChildren: 'app/boxes/boxes.module#BoxesModule' },
  {
    path: 'monthly-shipment-checkout',
    loadChildren: 'app/monthly-shipment-checkout/monthly-shipment-checkout.module#MonthlyShipmentCheckoutModule'
  },
  {
    path: 'thanks',
    component: ThanksComponent,
    resolve: {
      data: SubscriptionInfoResolver
    }
  },
  {
    path: 'monthly-thanks',
    component: MonthlyThanksComponent,
    resolve: {
      data: SubscriptionInfoResolver
    }
  },
  // { path: 'wreaths', loadChildren: 'app/wreaths/wreaths.module#WreathsModule' },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
