import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Angulartics2Module } from 'angulartics2';

import { NgxWebstorageModule } from 'ngx-webstorage';
// @ngrx modules
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterStateSerializer, StoreRouterConnectingModule, } from '@ngrx/router-store';
// App modules
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { AuthModule } from './auth/auth.module';
// configs
import { APP_CONFIG, app_config } from '@app/app.config';

import { ErrorsModule } from './error';
// root routing module
import { AppRoutingModule } from './app.routing';
// containers
import { AppComponent } from './app.component';
// reducers
import { CustomSerializer, reducers } from '@app/store/reducers';
// effects
import * as fromEffects from './store/effects';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    NgxWebstorageModule.forRoot({
      prefix: 'succulents', separator: '.', caseSensitive: true
    }),
    CoreModule,
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule,
    StoreDevtoolsModule.instrument({
      maxAge: 10
    }),
    EffectsModule.forRoot([...fromEffects.effects]),
    Angulartics2Module.forRoot(),
    SharedModule,
    AuthModule,
    PagesModule,
    AppRoutingModule,
    ErrorsModule,
  ],
  providers: [
    { provide: APP_CONFIG, useValue: app_config },
    { provide: RouterStateSerializer, useClass: CustomSerializer }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
