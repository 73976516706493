import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// rxjs
import { map } from 'rxjs/operators';

// config
import { APP_CONFIG, AppConfig } from './../../app.config';

@Injectable()
export class BraintreeService {

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) { }

  getClientAuthorizationToken(): Promise<string> {
    return this.httpClient
      .get(`${this.app_config.api_uri}/braintree/client_token`)
      .pipe(map((result: any) => result.client_token))
      .toPromise();
  }
}
