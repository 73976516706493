import { Directive, ElementRef, Input, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appBgDinamic]'
})
export class BackgroundDinamicDirective implements OnInit {
  @Input() bgDinamicXS: string;
  @Input() bgDinamicSM: string;
  @Input() bgDinamicMD: string;
  @Input() bgDinamicLG: string;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.changeElementBg();
  }

  @HostListener('window:resize', ['$event'])
  changeElementBg() {
    const windowW = window.innerWidth;
    if (this.bgDinamicXS) {
      this.el.nativeElement.style.backgroundImage = `url(${this.bgDinamicXS})`;
    }

    if (windowW >= 768 && this.bgDinamicSM) {
      this.el.nativeElement.style.backgroundImage = `url(${this.bgDinamicSM})`;
    }

    if (windowW >= 1024 && this.bgDinamicMD) {
      this.el.nativeElement.style.backgroundImage = `url(${this.bgDinamicMD})`;
    }

    if (windowW >= 1600 && this.bgDinamicLG) {
      this.el.nativeElement.style.backgroundImage = `url(${this.bgDinamicLG})`;
    }
  }
}
