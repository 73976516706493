import {
  Component,
  OnInit,
  Output,
  ElementRef,
  ViewChild,
  Renderer,
  Input,
  EventEmitter,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';

import { FormBuilder, Validators } from '@angular/forms';
import { Address } from '@app/core/models/address.model';
import { GooglePlacesService } from '@app/core/services';

@Component({
  selector: 'sucstu-editable-address',
  styleUrls: ['./editable-address.component.scss'],
  templateUrl: './editable-address.component.html'
})
export class EditableAddressComponent implements OnInit, AfterViewInit {
  statesError: boolean;

  @ViewChild('addressInput') addressInput: ElementRef;

  @Input() value: any = {};
  @Input() editing = false;

  @Output() save: EventEmitter<Address> = new EventEmitter<Address>();
  @Output() edit: EventEmitter<Address> = new EventEmitter<Address>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  showAllFields = false;
  showLabelPreview = false;

  addressError: any;

  form = this.fb.group({
    shippingAddress: ['', Validators.required],
    shippingAddressExtra: '',
    line: '',
    formattedLine: '',
    state: ['', Validators.required],
    stateCode: '',
    city: ['', Validators.required],
    zip: ['', Validators.required],
    country: ['', Validators.required],
    countryCode: '',
    label: [``, Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private _renderer: Renderer,
    private cd: ChangeDetectorRef,
    private googlePlacesService: GooglePlacesService,
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.googlePlacesService.listenGooglePlaces('address', (data) => {

      // 1. The user is trying to manually set their address.
      if (this.showAllFields) {
        return null;
      }

      // 2. There was an error trying to find the user address.
      if (data.error && !this.showAllFields) {
        this.addressError = data.error;
        if (data.streetNumber && data.route) {
          this.form.get('shippingAddress').setValue(`${data.streetNumber} ${data.route}`);
        }
        this.form.get('label').setValue('');
        this.cd.detectChanges();
        return;
      }

      // 3. The address was found!
      this.addressError = undefined;
      this.form.setValue({
        ...this.form.value,
        ...data,
        shippingAddressExtra: this.form.value.shippingAddressExtra !== ''
          ? this.form.value.shippingAddressExtra
          : '',
      });
      this.form.get('label').setValue(this.createLabel(this.form.value));
      this.showLabelPreview = true;
      this.cd.detectChanges();
    });
  }

  onSave() {
    if (this.showAllFields) {
      this.form.get('label').setValue(this.createLabel(this.form.value));
    }

    if (!this.form.valid) {
      return;
    }
    this.save.emit(this.form.value);
  }

  onCancel() {
    this.cancel.emit();
  }

  // Start the editting process for the input element
  onEdit() {
    this.form.patchValue(this.value);
    this.edit.emit();
    setTimeout(() =>
      this._renderer.invokeElementMethod(this.addressInput.nativeElement, 'focus', [])
    );
  }

  toggleShowAllFields() {
    this.showAllFields = !this.showAllFields;
    this.addressError = undefined;
  }

  isFieldValid(field: string, validation: string) {
    const control = this.form.get(field);
    return control.hasError(validation) && control.touched;
  }

  createLabel(address) {
    let label = `${address.shippingAddress}`;
    if (address.shippingAddressExtra.length) {
      label += `\n${address.shippingAddressExtra}\n`;
    }
    label += `${address.city} ${address.stateCode} ${address.zip}`;
    return label;
  }

  manuallySetStates() {
    const stateText = this.form.get('state').value;
    if (stateText && stateText === '') {
      return this.statesError = false;
    }
    const states = this.googlePlacesService.getAvailableStates();

    const index = states
      .findIndex(item => (
        item.stateCode
        === stateText.toLowerCase()
        || item.state.replace(/\s/g, '')
        === stateText.toLowerCase().replace(/\s/g, '')
      ) && stateText.length >= 2
      );

    if (index !== -1) {
      const toCapitalize = text => text.split(' ').map(w => w.slice(0, 1).toUpperCase() + w.slice(1)).join(' ');
      this.form.get('state').setValue(toCapitalize(states[index].state));
      this.form.get('stateCode').setValue(states[index].stateCode.toUpperCase());
      this.statesError = false;
    } else {
      this.form.get('state').setValue('');
      this.statesError = true;
    }
  }
}
