import { Update } from '@ngrx/entity/src/models';

import { Action } from '@ngrx/store';
import { Address } from '@app/core/models/address.model';

export const LOAD_ADDRESSES = '[Address] Load Addresses';
export const LOAD_ADDRESSES_COMPLETE = '[Address] Load Addresses Complete';
export const LOAD_ADDRESSES_FAIL = '[Address] Load Addresses Fail';

export const ADD_ADDRESS = '[Address] Add Address';
export const ADD_ADDRESS_COMPLETE = '[Address] Add Address Complete';
export const ADD_ADDRESS_FAIL = '[Address] Add Address Fail';

export const UPDATE_ADDRESS = '[Address] Update Address';
export const UPDATE_ADDRESS_COMPLETE = '[Address] Update Address Complete';
export const UPDATE_ADDRESS_FAIL = '[Address] Update Address Fail';

export class LoadAddressesAction implements Action {
  readonly type = LOAD_ADDRESSES;
  constructor() { }
};

export class LoadAddressesCompleteAction implements Action {
  readonly type = LOAD_ADDRESSES_COMPLETE;
  constructor(public payload: Address[]) { }
};

export class LoadAddressesFailAction implements Action {
  readonly type = LOAD_ADDRESSES_FAIL;
  constructor(public payload: any) { }
};

export class AddAddressesAction implements Action {
  readonly type = ADD_ADDRESS;
  constructor(public payload: Address) { }
};

export class AddAddressesCompleteAction implements Action {
  readonly type = ADD_ADDRESS_COMPLETE;
  constructor(public payload: Address) { }
};

export class AddAddressesFailAction implements Action {
  readonly type = ADD_ADDRESS_FAIL;
  constructor(public payload: any) { }
};

export class UpdateAddressesAction implements Action {
  readonly type = UPDATE_ADDRESS;
  constructor(public payload: any) { }
};

export class UpdateAddressesCompleteAction implements Action {
  readonly type = UPDATE_ADDRESS_COMPLETE;
  constructor(public payload: Update<Address>) { }
};

export class UpdateAddressesFailAction implements Action {
  readonly type = UPDATE_ADDRESS_FAIL;
  constructor(public payload: any) { }
};

export type Actions =
  | LoadAddressesAction
  | LoadAddressesCompleteAction
  | LoadAddressesFailAction
  | AddAddressesAction
  | AddAddressesCompleteAction
  | AddAddressesFailAction
  | UpdateAddressesAction
  | UpdateAddressesCompleteAction
  | UpdateAddressesFailAction;
