import {
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {
  APP_CONFIG,
  AppConfig
} from '@app/app.config';
import { ActivatedRoute } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { Coupon } from '@app/core/models/coupon.model';
import * as isToday from 'date-fns/is_today';

@Component({
  selector: 'sucstu-monthly-thanks',
  templateUrl: './monthly-thanks.component.html',
  styleUrls: ['./monthly-thanks.component.scss']
})
export class MonthlyThanksComponent implements OnInit {


  data: any = this.route.snapshot.data.data;

  constructor(
    @Inject(APP_CONFIG) private app_config: AppConfig,
    private route: ActivatedRoute,
    private angulartics: Angulartics2
  ) {
  }

  ngOnInit() {
    const { subscription, shipments } = this.data;
    const total = this.data.subscription.plan.price + (
      this.data.subscription.plan.shippingCosts * this.data.subscription.plan.shipmentsAtCheckout
    );

    if ('dataLayer' in window) {
      (window as any).dataLayer.push({
        'event': 'transactionComplete',
        'transactionId': this.app_config.production && this.app_config.name === 'production'
          ? this.data.subscription._id
          : `TEST_${this.data.subscription._id}`,
        'transactionTotal': total,
        'transactionProducts': [
          {
            'name': this.data.subscription.plan.title,
            'sku': this.data.subscription.plan.name,
            'price': total,
            'quantity': 1
          }
        ]
      });
    }

    if ('ire' in window) {
      const coupon: Coupon = subscription.usedCoupons[0];

      (window as any).ire('identify', {
        customerId: subscription.user._id,
        customerEmail: subscription.user.email
      });

      (window as any).ire('trackConversion', 15273, {
        orderId: subscription._id,
        customerId: subscription.user._id,
        customerStatus: isToday(subscription.user.createdAt) ? 'new' : 'returning',
        orderPromoCode: coupon ? coupon.code.toLowerCase() : '',
        orderDiscount: coupon ? parseInt(coupon.amount as any, 10) : 0,
        currencyCode: 'USD',
        items: [
          {
            subTotal: subscription.plan.price,
            category: (subscription.plan.type as string).toLowerCase(),
            sku: subscription.plan.name,
            quantity: 1
          }
        ]
      });
    }
  }

}
