import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Address } from '@app/core/models/address.model';
import * as addressActions from '@app/store/actions/address.actions';

export interface Entities {
  [id: string]: Address
}

export interface State extends EntityState<Address> {
  entities: Entities,
  loaded: boolean,
  loading: boolean,
  error: any
};

export const adapter: EntityAdapter<Address> = createEntityAdapter<Address>({
  selectId: (address: Address) => address._id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

export function reducer(
  state: State = initialState,
  action: addressActions.Actions): State {
  switch (action.type) {

    case addressActions.LOAD_ADDRESSES:
    case addressActions.ADD_ADDRESS:
    case addressActions.UPDATE_ADDRESS: {
      return { ...state, loading: true, loaded: false };
    }

    case addressActions.LOAD_ADDRESSES_COMPLETE: {
      return adapter.addAll(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        error: null
      });
    }

    case addressActions.ADD_ADDRESS_COMPLETE: {
      return adapter.addOne(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        error: null
      });
    }

    case addressActions.UPDATE_ADDRESS_COMPLETE: {
      return adapter.updateOne(action.payload, {
        ...state,
        loaded: true,
        loading: false
      });
    }

    case addressActions.LOAD_ADDRESSES_FAIL:
    case addressActions.LOAD_ADDRESSES_COMPLETE:
    case addressActions.UPDATE_ADDRESS_FAIL: {
      return { ...state, error: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const getAddresses = (state: State) => state.entities;
export const getAddressesLoaded = (state: State) => state.loaded;
export const getAddressesLoading = (state: State) => state.loading;
export const getAddressesError = (state: State) => state.error;
