import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Renderer, EventEmitter, AfterViewInit, ChangeDetectorRef, } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GooglePlacesService } from '@app/core/services';
var EditableAddressComponent = /** @class */ (function () {
    function EditableAddressComponent(fb, _renderer, cd, googlePlacesService) {
        this.fb = fb;
        this._renderer = _renderer;
        this.cd = cd;
        this.googlePlacesService = googlePlacesService;
        this.value = {};
        this.editing = false;
        this.save = new EventEmitter();
        this.edit = new EventEmitter();
        this.cancel = new EventEmitter();
        this.showAllFields = false;
        this.showLabelPreview = false;
        this.form = this.fb.group({
            shippingAddress: ['', Validators.required],
            shippingAddressExtra: '',
            line: '',
            formattedLine: '',
            state: ['', Validators.required],
            stateCode: '',
            city: ['', Validators.required],
            zip: ['', Validators.required],
            country: ['', Validators.required],
            countryCode: '',
            label: ["", Validators.required],
        });
    }
    EditableAddressComponent.prototype.ngOnInit = function () { };
    EditableAddressComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.googlePlacesService.listenGooglePlaces('address', function (data) {
            // 1. The user is trying to manually set their address.
            if (_this.showAllFields) {
                return null;
            }
            // 2. There was an error trying to find the user address.
            if (data.error && !_this.showAllFields) {
                _this.addressError = data.error;
                if (data.streetNumber && data.route) {
                    _this.form.get('shippingAddress').setValue(data.streetNumber + " " + data.route);
                }
                _this.form.get('label').setValue('');
                _this.cd.detectChanges();
                return;
            }
            // 3. The address was found!
            _this.addressError = undefined;
            _this.form.setValue(tslib_1.__assign({}, _this.form.value, data, { shippingAddressExtra: _this.form.value.shippingAddressExtra !== ''
                    ? _this.form.value.shippingAddressExtra
                    : '' }));
            _this.form.get('label').setValue(_this.createLabel(_this.form.value));
            _this.showLabelPreview = true;
            _this.cd.detectChanges();
        });
    };
    EditableAddressComponent.prototype.onSave = function () {
        if (this.showAllFields) {
            this.form.get('label').setValue(this.createLabel(this.form.value));
        }
        if (!this.form.valid) {
            return;
        }
        this.save.emit(this.form.value);
    };
    EditableAddressComponent.prototype.onCancel = function () {
        this.cancel.emit();
    };
    // Start the editting process for the input element
    EditableAddressComponent.prototype.onEdit = function () {
        var _this = this;
        this.form.patchValue(this.value);
        this.edit.emit();
        setTimeout(function () {
            return _this._renderer.invokeElementMethod(_this.addressInput.nativeElement, 'focus', []);
        });
    };
    EditableAddressComponent.prototype.toggleShowAllFields = function () {
        this.showAllFields = !this.showAllFields;
        this.addressError = undefined;
    };
    EditableAddressComponent.prototype.isFieldValid = function (field, validation) {
        var control = this.form.get(field);
        return control.hasError(validation) && control.touched;
    };
    EditableAddressComponent.prototype.createLabel = function (address) {
        var label = "" + address.shippingAddress;
        if (address.shippingAddressExtra.length) {
            label += "\n" + address.shippingAddressExtra + "\n";
        }
        label += address.city + " " + address.stateCode + " " + address.zip;
        return label;
    };
    EditableAddressComponent.prototype.manuallySetStates = function () {
        var stateText = this.form.get('state').value;
        if (stateText && stateText === '') {
            return this.statesError = false;
        }
        var states = this.googlePlacesService.getAvailableStates();
        var index = states
            .findIndex(function (item) { return (item.stateCode
            === stateText.toLowerCase()
            || item.state.replace(/\s/g, '')
                === stateText.toLowerCase().replace(/\s/g, '')) && stateText.length >= 2; });
        if (index !== -1) {
            var toCapitalize = function (text) { return text.split(' ').map(function (w) { return w.slice(0, 1).toUpperCase() + w.slice(1); }).join(' '); };
            this.form.get('state').setValue(toCapitalize(states[index].state));
            this.form.get('stateCode').setValue(states[index].stateCode.toUpperCase());
            this.statesError = false;
        }
        else {
            this.form.get('state').setValue('');
            this.statesError = true;
        }
    };
    return EditableAddressComponent;
}());
export { EditableAddressComponent };
