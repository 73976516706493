import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// config
import { APP_CONFIG, AppConfig } from './../../app.config';

// models
import { Address } from './../models/address.model';

// services
import { AuthService } from './../../auth/services/auth.service';

@Injectable()
export class AddressService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) { }

  getAddresses(): Observable<Address[]> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .get(`${this.app_config.api_uri}/users/${userId}/address`)
      .pipe(map((result: any) => result.addresses as Address[]));
  }

  updateAddress({ _id, newData }): Observable<Address> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .put(`${this.app_config.api_uri}/users/${userId}/address/${_id}`, newData)
      .pipe(map((result: any) => result.address as Address));
  }
}
