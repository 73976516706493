import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as boxActions from '@app/store/actions/box.actions';

// services
import { BoxService } from '@app/core/services/box.service';
import { SessionStorageService } from 'ngx-webstorage';
import * as plansActions from '@app/store/actions/plans.actions';

@Injectable()
export class BoxEffects {
  @Effect({dispatch: false}) loadAll$ = this.actions$.pipe(
    ofType(boxActions.LOAD_BOXES),
    switchMap(() => {
      return this.boxService.getBoxes().pipe(
        map(boxes =>
          this.store.dispatch(new boxActions.LoadBoxesCompleteAction(boxes))
        ),
        catchError(reason => {
          this.store.dispatch(
            new boxActions.LoadBoxesFailAction(reason.errors)
          );
          return of(new boxActions.LoadBoxesFailAction(reason.errors));
        })
      );
    })
  );

  @Effect({dispatch: false}) load$ = this.actions$.pipe(
    ofType(boxActions.LOAD_BOX),
    map((action: boxActions.LoadBoxAction) => action.payload),
    switchMap(({id}) => {
      return this.boxService.getBox(id).pipe(
        map(box =>
          this.store.dispatch(new boxActions.LoadBoxCompleteAction(box))
        ),
        catchError(reason => {
          this.store.dispatch(new boxActions.LoadBoxFailAction(reason.error));
          return of(new boxActions.LoadBoxFailAction(reason.error));
        })
      );
    })
  );
  @Effect({dispatch: false}) select$ = this.actions$
    .pipe(
      ofType(boxActions.SELECT_BOX),
      map((action: boxActions.SelectBoxAction) => action.payload),
      tap((planId: string) => this.sessionStorage.store('selectedBoxId', planId))
    );
  @Effect({dispatch: false}) deselect$ = this.actions$
    .pipe(
      ofType(boxActions.DESELECT_BOX),
      map((action: boxActions.DeselectBoxAction) => action),
      tap(() => this.sessionStorage.clear('selectedBoxId'))
    );

  constructor(
    private boxService: BoxService,
    private sessionStorage: SessionStorageService,
    private actions$: Actions,
    private store: Store<any>
  ) {
  }
}
