import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// config
import { APP_CONFIG, AppConfig } from './../../app.config';
// models
import { Coupon } from './../models/coupon.model';

@Injectable()
export class CouponsService {

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) {
  }

  verifyCoupon(code: string, planId?: string): Observable<Coupon> {
    const params = new HttpParams({
      fromObject: {
        couponCode: code,
        planId
      }
    });
    return this.httpClient
      .get(`${this.app_config.api_uri}/users/subscriptions/verify_coupon`, { params })
      .pipe(map((result: any) => result.coupon as Coupon));
  }
}
