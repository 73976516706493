import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store, select } from '@ngrx/store';

// rxjs
import { of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

// services
import { UserService } from './../services/user.service';
import { AuthService } from './../../auth/services/auth.service';

// actions
import * as user from '@app/store/actions/user.actions';
import * as subscription from '@app/store/actions/subscription.action';
import * as paymentMethod from '@app/store/actions/payment-methods.actions';

// reducers
import * as fromUser from '@app/store/selectors/user.selector';

@Injectable()
export class ProfileResolver implements Resolve<any> {

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private store: Store<any>
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const isAuthenticated$ = this.store.pipe(select(fromUser.getUserIsAuthenticated));
    const profile$ = this.authService.profile()
      .pipe(
        tap((data: any) => {
          this.store.dispatch(new user.LoadCompleteAction(data.user));
          this.store.dispatch(new subscription.LoadSubscriptionsCompleteAction(data.subscriptions));
          this.store.dispatch(new paymentMethod.LoadPaymentMethodsCompleteAction(data.user.paymentMethods));
        }),
        catchError(e => of(e.error.error))
      );

    return profile$
  }
}
