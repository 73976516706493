import { Directive, ElementRef, Renderer, Input, HostListener } from '@angular/core';
import * as payment from 'payment';

@Directive({ selector: '[ccNum]' })
export class CardNumberFormatDirective {

  cardType: string;

  constructor(private renderer: Renderer, private el: ElementRef) {
    const element   = this.el.nativeElement;
    this.cardType = '';

    // call lib functions
    payment.formatCardNumber(element);
    payment.restrictNumeric(element);
  }

  @HostListener('keypress', ['$event']) onKeypress(e) {
    const element       = this.el.nativeElement;
    const elementValue  = element.value;

    this.cardType = payment.fns.cardType(elementValue);

    if ( this.cardType !== '' ) {
      this.renderer.setElementClass(element, this.cardType, false);
    } else {
      this.cardType = '';
    }
  }
}
