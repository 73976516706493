import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as boxActions from '@app/store/actions/box.actions';
// services
import { BoxService } from '@app/core/services/box.service';
import { SessionStorageService } from 'ngx-webstorage';
var BoxEffects = /** @class */ (function () {
    function BoxEffects(boxService, sessionStorage, actions$, store) {
        var _this = this;
        this.boxService = boxService;
        this.sessionStorage = sessionStorage;
        this.actions$ = actions$;
        this.store = store;
        this.loadAll$ = this.actions$.pipe(ofType(boxActions.LOAD_BOXES), switchMap(function () {
            return _this.boxService.getBoxes().pipe(map(function (boxes) {
                return _this.store.dispatch(new boxActions.LoadBoxesCompleteAction(boxes));
            }), catchError(function (reason) {
                _this.store.dispatch(new boxActions.LoadBoxesFailAction(reason.errors));
                return of(new boxActions.LoadBoxesFailAction(reason.errors));
            }));
        }));
        this.load$ = this.actions$.pipe(ofType(boxActions.LOAD_BOX), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var id = _a.id;
            return _this.boxService.getBox(id).pipe(map(function (box) {
                return _this.store.dispatch(new boxActions.LoadBoxCompleteAction(box));
            }), catchError(function (reason) {
                _this.store.dispatch(new boxActions.LoadBoxFailAction(reason.error));
                return of(new boxActions.LoadBoxFailAction(reason.error));
            }));
        }));
        this.select$ = this.actions$
            .pipe(ofType(boxActions.SELECT_BOX), map(function (action) { return action.payload; }), tap(function (planId) { return _this.sessionStorage.store('selectedBoxId', planId); }));
        this.deselect$ = this.actions$
            .pipe(ofType(boxActions.DESELECT_BOX), map(function (action) { return action; }), tap(function () { return _this.sessionStorage.clear('selectedBoxId'); }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], BoxEffects.prototype, "loadAll$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], BoxEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], BoxEffects.prototype, "select$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], BoxEffects.prototype, "deselect$", void 0);
    return BoxEffects;
}());
export { BoxEffects };
