import { Action } from '@ngrx/store';

// models
import { Plan } from '@app/core/models/plan.model';

export const LOAD_PLANS = '[Plan] Load Plans';
export const LOAD_PLANS_COMPLETE = '[Plan Load Plans Complete';
export const LOAD_PLANS_FAIL = '[Plan] Load Plans Fail';

export const SELECT_PLAN = '[Plan] Select Plan';
export const DESELECT_PLAN = '[Plan] Deselect Plan';
export const CLEAR_PLAN_ERROR = '[Plan] Clear Plan Error';

export class LoadPlansAction implements Action {
  readonly type = LOAD_PLANS;
  constructor() {}
}

export class LoadPlansCompleteAction implements Action {
  readonly type = LOAD_PLANS_COMPLETE;
  constructor(public payload: Plan[]) {}
}

export class LoadPlansFailAction implements Action {
  readonly type = LOAD_PLANS_FAIL;
  constructor(public payload: { error: any }) {}
}

export class SelectPlanAction implements Action {
  readonly type = SELECT_PLAN;
  constructor(public payload: string) {}
}

export class DeselectPlanAction implements Action {
  readonly type = DESELECT_PLAN;
  constructor() {}
}

export class ClearPlanErrorsAction implements Action {
  readonly type = CLEAR_PLAN_ERROR;
  constructor() {}
}

export type Actions =
  | LoadPlansAction
  | LoadPlansCompleteAction
  | LoadPlansFailAction
  | SelectPlanAction
  | DeselectPlanAction
  | ClearPlanErrorsAction;
