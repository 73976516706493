import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Plan } from './../../core/models/plan.model';

import { APP_CONFIG, AppConfig } from './../../app.config';

@Injectable()
export class PlansService {

  REGULAR_PLAN = '5997c3ac8852a761249342e9';
  GIFT_PLAN = '5a10dbfaf8ceb4830ff186a9';
  BRADS_GIFT_BOGO = '5adfc738c92e1e49891852e5';

  getAvailablePlans(): Observable<Plan[]> {
    const headers = new HttpHeaders({ retry: 'yes' });
    return this.http.get(`${this.app_config.api_uri}/products/plans`, { headers })
      .pipe(map((result: any) => result.plans as Plan[]));
  }

  getPlan(id: string): Observable<{ plan: Plan, rate: any }> {
    const headers = new HttpHeaders({ retry: 'yes' });
    return this.http.get<any>(`${this.app_config.api_uri}/products/plans/${id}`, { headers })
  }

  getPlansStatistics(): Observable<{ _id: string, total: number, name: string }[]> {
    const headers = new HttpHeaders({ retry: 'yes' });
    return this.http.get<any>(`${this.app_config.api_uri}/products/plans/statistics`, { headers })
      .pipe(map((result: any) => result.plans));
  }

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) { }
}
