<img *ngIf="popular" src="/assets/images/mp-succs.png" alt="Most Popular sucstu plan" class="most-popular-image z-50">
<div class="plan-card overflow-hidden">
  <a class="plan-card-link" routerLink="/checkout" [queryParams]="queryParams">
    <div *ngIf="badge" class="plan-card-badge">
      <ng-content select=".plan-card-badge"></ng-content>
    </div>
    <div class="plan-card-content">
      <div class="plan-card-title-container">
        <div class="plan-card-title">
          <ng-content select=".plan-card-title"></ng-content>
        </div>
        <div class="plan-card-subtitle">
          <ng-content select=".plan-card-subtitle"></ng-content>
        </div>
      </div>
      <div class="plan-card-picture">
        <ng-content select=".plan-card-picture"></ng-content>
      </div>
    </div>

    <div class="plan-card-bottom-desc">
      <ng-content select=".plan-card-bottom"></ng-content>
    </div>
  </a>
</div>
