
      <header class="header">
          <!-- Mobile Header -->
          <div class="header-container" #headerContainer>
              <div [ngClass]="{'sticked': isSticked }" [ngStyle]="{ 'top': moveFromTop + 'px' }" class="nav-row" *ngIf="!isSales">
                  <a class="logo-container" routerLink="/">
                      <img class="logo-container-img" src="assets/images/header-icon.png">
                      <img class="logo-container-img logo-container-img--mobile" src="assets/images/header-icon-mobile.png">
                  </a>
                  <h1 class="header-title header-title--desk">
                      <a routerLink="/"><span class="header-title-bolder">Succulent</span> Studios</a>
                  </h1>
                  <div class="nav-container" [ngClass]="{ 'isInspiration': isInspiration }" *ngIf="isInspiration">
                      <h5 class="guarantee-text">100% satisfaction guarantee</h5>
                      <div class="nav-action">
                          <a routerLink="/checkout">Subscribe</a>
                      </div>
                  </div>
                  <div class="nav-container" *ngIf="!isInspiration">
                      <h5 class="guarantee-text" *ngIf="!(isAuthenticated$ | async)">100% satisfaction guarantee</h5>
                      <div
                              class="nav-action"
                              [ngClass]="{ 'logged-in': isAuth }" *ngIf="(isAuthenticated$ | async) as isAuth; else loggedOut">
                          <a #loginButton routerLink="/profile">Account</a>
                          <a [attr.test-id]="'header__logout-button'" routerLink="/" (click)="logout()">Log Out</a>
                          <a *ngIf="userRole === 'admin'" routerLink="/control">Control center</a>
                      </div>
                      <ng-template #loggedOut>
                          <div class="nav-action">
                              <a [attr.test-id]="'header__login-button'" #loginButton routerLink="/login">Log In</a>
                          </div>
                      </ng-template>
                  </div>
              </div>
              <div class="title-row" *ngIf="!isSales">
                  <h1 class="header-title header-title--mobile">
                      <a routerLink="/"><span class="header-title-bolder">Succulent</span> Studios</a>
                  </h1>
              </div>
          </div>

      </header>
  