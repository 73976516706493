import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '@app/app.config';
import * as BrandData from "@config/brand.json";
import * as GiftDoneData from "@config/gift-done.json";
import { Store } from '@ngrx/store';
import * as isToday from 'date-fns/is_today';
// services
import { SessionStorageService } from 'ngx-webstorage';
import { map } from 'rxjs/operators';
import { PlansService } from '@app/core/services';
import * as fromCouponsActions from './../../../store/actions/coupon.actions';
import * as format from 'date-fns/format';
import * as addDays from 'date-fns/add_days';
import { HttpParams } from '@angular/common/http';
var GiftDoneComponent = /** @class */ (function () {
    function GiftDoneComponent(app_config, route, plansService, sessionStorage, store, router) {
        this.app_config = app_config;
        this.route = route;
        this.plansService = plansService;
        this.sessionStorage = sessionStorage;
        this.store = store;
        this.router = router;
        this.giftDoneData = GiftDoneData.default;
        this.brandData = BrandData.default;
        this.data = this.route.snapshot.data.data;
        this.renderBradsDealsPixel = false;
        this.bradsDealsTrafficData = null;
        this.bradsDealsPixelTagUrl = '';
    }
    Object.defineProperty(GiftDoneComponent.prototype, "isPlanABradsDealsDeal", {
        get: function () {
            var subscription = this.data.subscription;
            return subscription.plan._id === this.plansService.BRADS_GIFT_BOGO;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GiftDoneComponent.prototype, "isProd", {
        get: function () {
            return this.app_config.production && this.app_config.name === 'production';
        },
        enumerable: true,
        configurable: true
    });
    GiftDoneComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a = this.data, subscription = _a.subscription, shipment = _a.shipment;
        var coupon = subscription.usedCoupons[0];
        var total = this.data.subscription.plan.price + (this.data.subscription.plan.shippingCosts * this.data.subscription.plan.shipmentsAtCheckout);
        if (this.isPlanABradsDealsDeal) {
            this.plansService.getPlansStatistics()
                .pipe(map(function (plans) { return plans.find(function (plan) { return plan._id === _this.plansService.BRADS_GIFT_BOGO; }); }), map(function (plan) { return plan.total; }))
                .subscribe(function (num) {
                if (num > 500) {
                    _this.renderBradsDealsPixel = false;
                    return;
                }
                _this.renderBradsDealsPixel = true;
                var data = _this.parseBradsDealsTrafficData();
                if (!data) {
                    _this.renderBradsDealsPixel = false;
                    return;
                }
                var params = new HttpParams({
                    fromObject: {
                        nid: '104',
                        oid: data.orderId,
                        transaction_id: data.transactionId,
                        amount: total.toString().replace('.', ',')
                    }
                });
                _this.bradsDealsPixelTagUrl = "https://bradsdeals.servtrk.com/?" + params.toString();
            });
        }
        if (window.dataLayer) {
            window.dataLayer.push({
                'event': 'transactionComplete',
                'transactionId': this.app_config.production && this.app_config.name === 'production'
                    ? this.data.subscription._id
                    : "TEST_" + this.data.subscription._id,
                'transactionTotal': total,
                'transactionProducts': [
                    {
                        'name': this.data.subscription.plan.title,
                        'sku': this.data.subscription.plan.name,
                        'price': total,
                        'quantity': 1
                    }
                ],
                'customerEmail': subscription.user.email,
                'sourceParam': subscription.user.utmData && subscription.user.utmData.utm_source,
                'coupon': coupon && coupon.code.toLowerCase()
            });
        }
        if (window.ire) {
            window.ire('identify', {
                customerId: subscription.user._id,
                customerEmail: subscription.user.email
            });
            window.ire('trackConversion', 15273, {
                orderId: subscription._id,
                customerId: subscription.user._id,
                customerStatus: isToday(subscription.user.createdAt) ? 'new' : 'returning',
                orderPromoCode: coupon ? coupon.code.toLowerCase() : '',
                orderDiscount: coupon ? parseInt(coupon.amount, 10) : 0,
                currencyCode: 'USD',
                items: [
                    {
                        subTotal: subscription.plan.price,
                        category: subscription.plan.type.toLowerCase(),
                        sku: subscription.plan.name,
                        quantity: 1
                    }
                ]
            });
        }
        if (window.uetq && window.uetq.push) {
            window['uetq'].push('event', 'checkout', {
                revenue_value: total,
                currency: 'USD'
            });
        }
        if (window.gapi) {
            window.gapi.load('surveyoptin', function () {
                window.gapi.surveyoptin.render({
                    // REQUIRED FIELDS
                    'merchant_id': 129373759,
                    'order_id': subscription._id,
                    'email': subscription.user.email,
                    'delivery_country': 'US',
                    'estimated_delivery_date': format(addDays(shipment.printed.cantPrintUntil, 7), 'YYYY-MM-DD'),
                });
            });
        }
    };
    GiftDoneComponent.prototype.parseBradsDealsTrafficData = function () {
        var data = this.sessionStorage.retrieve('bradsDealsTrafficData');
        if (data) {
            return JSON.parse(data);
        }
        return null;
    };
    GiftDoneComponent.prototype.goToCheckoutWithCoupon = function () {
        this.store.dispatch(new fromCouponsActions.VerifyCouponAction('FIFTYOFF'));
        this.router.navigate(['/checkout'], { queryParams: { planId: '5997c3ac8852a761249342e9' } });
    };
    return GiftDoneComponent;
}());
export { GiftDoneComponent };
