<div class="container-fluid">

  <div class="row mb-16">
    <img class="w-full h-auto" src="/assets/images/gift/bogo-background.gif" alt="">
<!--    <section class="hero-container">-->
<!--      <div class="hero-content">-->
<!--&lt;!&ndash;        <div class="hero-title-container">&ndash;&gt;-->
<!--&lt;!&ndash;          <h2 class="hero-title">&ndash;&gt;-->
<!--&lt;!&ndash;            Eucalyptus Farms&ndash;&gt;-->
<!--&lt;!&ndash;          </h2>&ndash;&gt;-->
<!--&lt;!&ndash;          <h3 class="hero-subtitle">&ndash;&gt;-->
<!--&lt;!&ndash;            Holiday BOGO&ndash;&gt;-->
<!--&lt;!&ndash;          </h3>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->
<!--    </section>-->
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="page-title-container">
      <h2 class="page-title">
        Give A Gift Subscription, Get a Box Just For You! <span class="text-bolder">JUST $12/MONTH</span> + shipping!
      </h2>
    </div>
  </div>
  <div class="row">
    <div class="attribute-card-container">
      <div class="attribute-card">
        <div class="circle-picture">
          <img src="/assets/images/we-grow.png" class="attribute-picture-flower">
        </div>
        <h2>YOU GIFT.</h2>
        <p>Tell us who gets the pleasure of receiving your gift, pick the shipment date, and write them a gift note.</p>
      </div>
      <div class="attribute-card">
        <div class="circle-picture">
          <img src="/assets/images/we-ship.png" class="attribute-picture-truck">
        </div>
        <h2>WE SHIP.</h2>
        <p>Cut, boxed, and shipped on the same day, our eucalyptus bouquets arrive beautiful, green, and ready to
          enjoy!</p>
      </div>
      <div class="attribute-card">
        <div class="circle-picture">
          <img src="/assets/images/you-enjoy.png" class="attribute-picture-succulent">
        </div>
        <h2>YOU ENJOY.</h2>
        <p>Eucalyptus enhances any space with long-lasting, show-stopping beauty while fresh and long after it's
          dried.</p>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="divider"></div>
  </div>
</div>

<div class="container">
  <div class="row">

    <div class="plans-cards-container">
      <h4 class="plans-cards-title">
        Choose A <span class="text-bolder">Gift Plan</span>
      </h4>
      <div class="plans-cards">

        <sucstu-plan-card class="plan-card__2" [queryParams]="{ planId: PLAN_6M_GIFT, bogo: true  }" [popular]="true">
<!--          <div class="plan-card-title">-->
<!--            {{ giftData.captions['6months'].title }}-->
<!--          </div>-->
<!--          <div class="plan-card-subtitle">-->
<!--            {{ giftData.captions['6months'].subTitle }}-->
<!--          </div>-->
<!--          <div class="plan-card-bottom">-->
<!--            {{ giftData.captions['6months'].bottomDescription }}-->
<!--          </div>-->
          <img class="p-1 mx-auto plan-card-picture plan-card__picture--2" src="/assets/images/gift/6mo_BOGO_sm.png" alt="6 Month Plan"/>
        </sucstu-plan-card>

        <sucstu-plan-card class="plan-card__1" [queryParams]="{ planId: PLAN_3M_GIFT, bogo: true  }">
<!--          <div class="plan-card-title">-->
<!--            {{ giftData.captions['3months'].title }}-->
<!--          </div>-->
<!--          <div class="plan-card-subtitle">-->
<!--            {{ giftData.captions['3months'].subTitle }}-->
<!--          </div>-->
<!--          <div [attr.test-id]="'gift-page__3m-button'" class="plan-card-bottom">-->
<!--            {{ giftData.captions['3months'].bottomDescription }}-->
<!--          </div>-->
          <img class="p-1 mx-auto plan-card-picture plan-card__picture--1" src="/assets/images/gift/3mo_BOGO_sm.png" alt="3 Month Plan"/>
        </sucstu-plan-card>

        <sucstu-plan-card class="plan-card__3" [queryParams]="{ planId: PLAN_12M_GIFT, bogo: true  }">
<!--          <div class="plan-card-title">-->
<!--            {{ giftData.captions['12months'].title }}-->
<!--          </div>-->
<!--          <div class="plan-card-subtitle">-->
<!--            {{ giftData.captions['12months'].subTitle }}-->
<!--          </div>-->
<!--          <div class="plan-card-bottom">-->
<!--            {{ giftData.captions['12months'].bottomDescription }}-->
<!--          </div>-->
          <img class="p-1 mx-auto plan-card-picture plan-card__picture--3" src="/assets/images/gift/12mo_BOGO_sm.png" alt="12 Month Plan"/>
        </sucstu-plan-card>

      </div>

      <p class="description-terms px-4">
        *Your BOGO includes one 3, 6, or 12-month gift subscription, plus a one-time box of eucalyptus as a bonus for you! Your box of eucalyptus will typically ship when your gift recipient's ships.
      </p>
    </div>

  </div>
</div>
