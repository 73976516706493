import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHTML'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor (protected sanitizer: DomSanitizer) {}

  transform(HTMLString: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(HTMLString);
  }

}
