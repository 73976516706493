import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import {isPlatformBrowser} from '@angular/common';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MarketingService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private readonly http: HttpClient,

  ) { }

  reportPageView(): Observable<{ eventId: string }> {
    if (!isPlatformBrowser(this.platformId)) {
      return of();
    }
    return this.http.post<{ eventId: string }>(`${environment.marketingApi}/tracking/event/PageView`, {
      userAgent: navigator.userAgent,
      sourceUrl: window.location.href,
      eventTime: new Date().getTime()
    })
  }

  reportLead(userEmail: string): Observable<{ eventId: string }> {
    if (!isPlatformBrowser(this.platformId)) {
      return of();
    }

    return this.http.post<{ eventId: string }>(`${environment.marketingApi}/tracking/event/Lead`, {
      userAgent: navigator.userAgent,
      sourceUrl: window.location.href,
      eventTime: new Date().getTime(),
      userEmail,
    })
      .pipe(tap((response) => {
        window['dataLayer'].push({
          'event': 'leadFormSubmited',
          'lead': {
            'email': userEmail,
          },
          eventID: response.eventId,
        });
      }))
  }

  reportPurchase(data: { value: number, planName: string, id: string | number }): Observable<{ eventId: string }> {
    if (!isPlatformBrowser(this.platformId)) {
      return of();
    }

    return this.http.post<{ eventId: string }>(`${environment.marketingApi}/tracking/event/Purchase`, {
      userAgent: navigator.userAgent,
      sourceUrl: window.location.href,
      eventTime: new Date().getTime(),
      cart: {
        value: data.value,
        items: [{
          id: data.id,
          quantity: 1,
          title: data.planName,
          price: data.value,
        }]
      }
    })
  }

  reportOTBPurchase(data: { shipmentsId: string }): Observable<{ eventId: string }> {
    if (!isPlatformBrowser(this.platformId)) {
      return of();
    }

    return this.http.post<{ eventId: string }>(`${environment.marketingApi}/tracking/event/OtbPurchase`, {
      userAgent: navigator.userAgent,
      sourceUrl: window.location.href,
      eventTime: new Date().getTime(),
      shipmentId: data.shipmentsId,
    });
  }

  reportPaymentInfoAdded(): Observable<{ eventId: string }> {
    if (!isPlatformBrowser(this.platformId)) {
      return of();
    }

    return this.http.post<{ eventId: string }>(`${environment.marketingApi}/tracking/event/AddPaymentInfo`, {
      userAgent: navigator.userAgent,
      sourceUrl: window.location.href,
      eventTime: new Date().getTime(),
    })
  }

  reportInitialCheckout(): Observable<{ eventId: string }> {
    if (!isPlatformBrowser(this.platformId)) {
      return of();
    }

    return this.http.post<{ eventId: string }>(`${environment.marketingApi}/tracking/event/InitiateCheckout`, {
      userAgent: navigator.userAgent,
      sourceUrl: window.location.href,
      eventTime: new Date().getTime(),
    })
  }

  reportTurnOff(data: { userId: string }): Observable<{ eventId: string }> {
    if (!isPlatformBrowser(this.platformId)) {
      return of();
    }

    return this.http.post<{ eventId: string }>(`${environment.marketingApi}/tracking/event/TurnOFF`, {
      userAgent: navigator.userAgent,
      sourceUrl: window.location.href,
      eventTime: new Date().getTime(),
      ...data
    });
  }
}
