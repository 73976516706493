
  <div class="lead-banner-container" #bannerContainer>
    <div class="lead-banner" #banner>
      <div class="lead-banner-content">
        <h1 class="lead-banner__title text-bolder">50% OFF</h1>
        <h3 class="lead-banner__subtitle">YOUR FIRST BUNDLE OF SEASONAL EUCALYPTUS!</h3>
        <!-- [formGroup]="parent" -->
        <form class="lead-banner__form" [formGroup]="form">
          <div class="form-group">
            <input
              class="lead-input form-group__input text-extralight"
              id="nameInput" type="email"
              placeholder="Enter your email"
              formControlName="email"
              required="required"
              (keyup.enter)="!isFieldInvalid('email', 'invalidEmail') ? submitEmail() : null"/>
            <span class="lead-error error-text" *ngIf="isFieldInvalid('email', 'invalidEmail')">Please use a valid email</span>
          </div>
          <button
            class="lead-banner__btn btn btn-primary"
            type="button"
            (click)="submitEmail()">I want {{ brandData?.mainProduct }}</button>
        </form>
        <a [attr.test-id]="'lead-banner__close-button'" class="lead-banner__link"
          (click)="closeBanner()">No thanks, I do not want 50% off.
        </a>
      </div>
    </div>
  </div>
  