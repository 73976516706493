/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/pipes/safe-html.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./terms.component";
var styles_TermsComponent = [i0.styles];
var RenderType_TermsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsComponent, data: {} });
export { RenderType_TermsComponent as RenderType_TermsComponent };
function View_TermsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [["class", "privacy__section__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }); }
function View_TermsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "privacy__section"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TermsComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(4, 1)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.text)); _ck(_v, 3, 0, currVal_1); }); }
export function View_TermsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.SafeHtmlPipe, [i4.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "section", [["class", "privacy-container container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "section__title privacy__title center-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "privacy__content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TermsComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.termsData == null) ? null : _co.termsData.terms_sections); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.termsData == null) ? null : _co.termsData.pageTitle); _ck(_v, 4, 0, currVal_0); }); }
export function View_TermsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-app-terms", [], null, null, null, View_TermsComponent_0, RenderType_TermsComponent)), i1.ɵdid(1, 49152, null, 0, i5.TermsComponent, [], null, null)], null, null); }
var TermsComponentNgFactory = i1.ɵccf("sucstu-app-terms", i5.TermsComponent, View_TermsComponent_Host_0, {}, {}, []);
export { TermsComponentNgFactory as TermsComponentNgFactory };
