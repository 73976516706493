import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@app/app.config';
import { isPlatformBrowser } from '@angular/common';
import { SessionStorageService } from 'ngx-webstorage';
import * as isToday from 'date-fns/is_today';

import { Payment } from '../models/payment.model';
import { Plan } from '@app/core/models/plan.model';
import { User } from '@app/core/models/user.model';
import { Coupon } from '@app/core/models/coupon.model';
import { Subscription } from '@app/core/models/subscription.model';
import {AnglerAiService} from '@app/core/services/angler-ai/angler-ai.service';
import {MarketingService} from '@app/core/services/marketing/marketing.service';

@Injectable()
export class ConvertionService {
  constructor(
    @Inject(APP_CONFIG) private app_config: AppConfig,
    @Inject(PLATFORM_ID) private platformId: any,
    private sessionStorageService: SessionStorageService,
    private readonly anglerAiService: AnglerAiService,
    private readonly marketingService: MarketingService,
  ) { }

  reportSubscriptionSale(data: { subscription: any, payment: any, user: any, plan: any, coupon: any }) {
    const { subscription, payment, user, plan, coupon } = data;
    this.reportTransactionComplete(user, subscription, coupon, payment, plan);
    this.reportIre(subscription, user, coupon, plan);
    this.reportUetq(payment);
  }

  reportOTBSale({ user, payment, shipment, box }) {
    if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
      const shippingCosts = box.shippingCosts;
      (window as any).dataLayer.push({
        'event': 'transactionComplete',
        'transactionId': this.app_config.production && this.app_config.name === 'production'
          ? shipment._id : `TEST_${shipment._id}`,
        'transactionTotal': payment.amount,
        'transactionTax': payment.taxes,
        'transactionShipping': shippingCosts * payment.shipments.length,
        'transactionType': payment.transaction,
        'transactionProducts': [
          {
            'name': payment.transaction,
            'sku': payment.transaction,
            'price': box.price,
            'quantity': payment.shipments.length
          }
        ],
        'customerEmail': user.email,
      });
    }
  }

  reportShipmentSale({ user, subscription, coupon, payment, plan }) {
    if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {

      const utmData = this.sessionStorageService.retrieve('utmData');
      const affiliateRef = this.sessionStorageService.retrieve('affiliateRef');
      const shippingCosts = plan.shippingCosts;

      console.log('Affiliate Ref', affiliateRef);
      (window as any).dataLayer.push({
        'event': 'transactionComplete',
        'transactionId': this.app_config.production && this.app_config.name === 'production'
          ? subscription._id : `TEST_${subscription._id}`,
        'transactionTotal': payment.amount,
        'transactionTax': payment.taxes,
        'transactionShipping': shippingCosts * payment.shipments.length,
        'transactionType': payment.transaction,
        'transactionProducts': [
          {
            'name': payment.transaction,
            'sku': payment.transaction,
            'price': plan.price,
            'quantity': payment.shipments.length
          }
        ],
        'customerEmail': user.email,
        'sourceParam': utmData && utmData.utm_source,
        'sourceAffiliateRef': affiliateRef,
        'coupon': coupon && coupon.code.toLowerCase()
      });
    }
  }

  private reportTransactionComplete(user, subscription: Subscription, coupon: Coupon, payment: Payment, plan: Plan) {
    if (isPlatformBrowser(this.platformId)) {
      this.anglerAiService.notifyCheckoutCompleted({
        checkout: {
          email: user.email,
          order: {
            id: subscription._id.toString(),
          },
          phone: user.phone,
          currencyCode: 'usd',
          totalPrice: {
            amount: payment && payment.amount || 0,
            currencyCode: 'usd',
          },
          totalTax: {
            amount: payment && payment.taxes || 0,
            currencyCode: 'usd',
          },
        },
        customer: {
          email: user.email,
        }
      })
        .then()
        .catch(e => {
          console.error(e);
        });
    }

    if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
      const utmData = this.sessionStorageService.retrieve('utmData');
      const affiliateRef = this.sessionStorageService.retrieve('affiliateRef');
      const shippingCosts = plan.shippingCosts;

      console.log('Affiliate Ref', affiliateRef);
      this.marketingService.reportPurchase({
        value: payment && payment.amount || 0,
        planName: plan.name,
        id: this.app_config.production && this.app_config.name === 'production'
          ? subscription._id : `TEST_${subscription._id}`,
      })
        .subscribe((response) => {
          (window as any).dataLayer.push({
            'event': 'transactionComplete',
            eventID: response.eventId,
            'transactionId': this.app_config.production && this.app_config.name === 'production'
              ? subscription._id : `TEST_${subscription._id}`,
            'transactionTotal': payment.amount,
            'transactionTax': payment.taxes,
            'transactionShipping': subscription.isPrepay ? shippingCosts * (plan.term as any) : shippingCosts * plan.shipmentsAtCheckout,
            'transactionType': plan.type,
            'transactionProducts': [
              {
                'name': plan.title,
                'sku': plan.name,
                'price': plan.price,
                'quantity': subscription.isPrepay ? plan.term : 1
              }
            ],
            'customerEmail': user.email,
            'sourceParam': utmData && utmData.utm_source,
            'sourceAffiliateRef': affiliateRef,
            'coupon': coupon && coupon.code.toLowerCase()
          });
        })
    }
  }

  private reportIre(subscription: Subscription, user: User, coupon: Coupon, plan: Plan) {
    if (isPlatformBrowser(this.platformId) && (window as any).ire) {
      window['ire']('identify', {
        customerId: user._id,
        customerEmail: user.email
      });

      window['ire']('trackConversion', 15273, {
        orderId: subscription._id,
        customerId: user._id,
        customerStatus: isToday(user.createdAt) ? 'new' : 'returning',
        orderPromoCode: coupon ? coupon.code.toLowerCase() : '',
        orderDiscount: coupon ? parseInt(coupon.amount as any, 10) : 0,
        currencyCode: 'USD',
        items: [
          {
            subTotal: plan.price,
            category: (plan.type as string).toLowerCase(),
            sku: plan.name,
            quantity: 1
          }
        ]
      });
    }
  }

  private reportUetq(payment: Payment) {
    if (isPlatformBrowser(this.platformId) && (window as any).uetq && (window as any).uetq.push) {
      window['uetq'].push('event', 'checkout', {
        revenue_value: payment.amount,
        currency: 'USD'
      });
    }
  }

}
