<div class="item-container">
  <img [src]="box.heroImage" alt="succulent">
</div>
<p class="item-name mb-4" [innerHtml]="box.name | safeHTML"></p>
<p class="item-cost mb-4">
  {{ (box.price + box.shippingCosts) | currency:'USD':'symbol':'1.2-2' }}
  per box
</p>
<div class="item-name flex items-center">
  <button (click)="onDecrease()" class="item-button">
    <span class="item-button-center">-</span>
  </button>
  <p class="item-qty">{{quantityCount}}</p>
  <button (click)="onIncrease()" class="item-button">
    <span class="item-button-center">+</span>
  </button>
</div>
