import { ElementRef, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { select, Store } from '@ngrx/store';
// actions
import * as user from '@app/store/actions/user.actions';
// selectors
import * as fromUser from '@app/store/selectors/user.selector';
// models
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(store, router, renderer, locationService, elementRef) {
        this.store = store;
        this.router = router;
        this.renderer = renderer;
        this.locationService = locationService;
        this.elementRef = elementRef;
        this.moveFromTop = 0;
        this.isAuthenticated$ = this.store.pipe(select(fromUser.getUserIsAuthenticated));
    }
    Object.defineProperty(HeaderComponent.prototype, "isInspiration", {
        get: function () {
            return this.locationService.path() === '/inspiration';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "isSales", {
        get: function () {
            return this.locationService.path() === '/sales' || this.locationService.path() === '/churn';
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.onScrollPage = function () {
        if (document.documentElement.scrollTop === 0) {
            this.isSticked = false;
        }
        else {
            this.isSticked = true;
        }
    };
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.pipe(select(fromUser.getUserData))
            .subscribe(function (userState) { return _this.userRole = userState.role; });
    };
    HeaderComponent.prototype.ngOnChanges = function (changes) {
        if (!this.isSales) {
            if (changes.moveFromTop.currentValue > 0) {
                var headerContainerElement = this.headerContainer.nativeElement;
                var navRowElement = headerContainerElement.querySelector('.nav-row');
                var height = navRowElement.clientHeight;
            }
            else {
                this.renderer.setStyle(this.headerContainer.nativeElement, 'padding-top', "");
            }
        }
    };
    HeaderComponent.prototype.openNav = function () {
        // this.isNavOpened = !this.isNavOpened;
    };
    HeaderComponent.prototype.logout = function (e) {
        var _this = this;
        this.router
            .navigate(['/'])
            .then(function (n) { return _this.store.dispatch(new user.LogoutAction()); });
    };
    return HeaderComponent;
}());
export { HeaderComponent };
