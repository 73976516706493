import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import * as shipmentsAction from '@app/store/actions/shipment.action';
// services
import { ShipmentService } from '@app/core/services/shipment.service';
var ShipmentEffects = /** @class */ (function () {
    function ShipmentEffects(shipmentService, actions$, store) {
        var _this = this;
        this.shipmentService = shipmentService;
        this.actions$ = actions$;
        this.store = store;
        this.load$ = this.actions$
            .pipe(ofType(shipmentsAction.LOAD_SHIPMENTS), switchMap(function () {
            return _this.shipmentService.getShipments()
                .pipe(map(function (shipments) { return _this.store.dispatch(new shipmentsAction.LoadShipmentsCompleteAction(shipments)); }), catchError(function (reason) {
                _this.store.dispatch(new shipmentsAction.LoadShipmentsFailAction(reason.errors));
                return of(new shipmentsAction.LoadShipmentsFailAction(reason.errors));
            }));
        }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], ShipmentEffects.prototype, "load$", void 0);
    return ShipmentEffects;
}());
export { ShipmentEffects };
