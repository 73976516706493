import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import * as fromBoxes from '@app/store/selectors/boxes.selector';
import { catchError, filter, switchMap, take, timeout } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { LoadBoxesAction } from '@app/store/actions/box.actions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngrx/store";
var AllBoxesGuard = /** @class */ (function () {
    function AllBoxesGuard(router, store) {
        this.router = router;
        this.store = store;
    }
    // TODO: develop a specific endpoint to get specific boxes from API
    AllBoxesGuard.prototype.getBoxesFromStoreOrApi = function () {
        this.store.dispatch(new LoadBoxesAction());
        return this.store
            .select(fromBoxes.getBoxesLoaded)
            .pipe(filter(function (loaded) { return loaded; }), take(1), timeout(5000), catchError(function (e) {
            return of(false);
        }));
    };
    AllBoxesGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.getBoxesFromStoreOrApi().pipe(switchMap(function (data) { return of(true); }), catchError(function (e) { return _this.router.navigate(['/']); }));
    };
    AllBoxesGuard.ngInjectableDef = i0.defineInjectable({ factory: function AllBoxesGuard_Factory() { return new AllBoxesGuard(i0.inject(i1.Router), i0.inject(i2.Store)); }, token: AllBoxesGuard, providedIn: "root" });
    return AllBoxesGuard;
}());
export { AllBoxesGuard };
