import { NgModule } from '@angular/core';
// modules
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { UiSwitchModule } from 'ngx-ui-switch';

import * as fromPipes from './pipes';
import * as fromComponents from './components';
import * as fromDirectives from './directives';
import { ReplacePipe } from './pipes/replace.pipe';

export const DECLARATIONS = [
  ...fromComponents.components,
  ...fromDirectives.directives,
  ...fromPipes.pipes,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    UiSwitchModule
  ],
  declarations: [...DECLARATIONS, ReplacePipe],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    UiSwitchModule,
    ...DECLARATIONS,
    ReplacePipe
  ],
  entryComponents: [
    fromComponents.LeadBannerComponent,
    fromComponents.NotificationBarComponent,
  ]
})
export class SharedModule {
}
