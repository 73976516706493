<div #leadBannerComponent></div>
<div class="page-container">
  <!-- <sucstu-modal-banner></sucstu-modal-banner> -->
  <!-- Hero section (background background with image and hero messages)-->
  <section class="hero-container">
    <div class="slide-nav slide-nav-container-left">
      <div class="nav-circle" (click)="moveSliderToLeft()">
        <img src="/assets/images/left-arrow.png"/>
      </div>
    </div>

    <div class="hero-slides-container">
      <div class="mobile-hero-slide">
        <owl-carousel #owlMobile [options]="mobileSliderOptions" [carouselClasses]="['owl-custom-theme']">
          <div *ngFor="let slide of homeData?.mainCarousel">
            <div class="slide-picture" appBgDinamic
              [bgDinamicXS]="slide.images?.mobile"
              [bgDinamicSM]="slide.images?.tablet"
              [bgDinamicMD]="slide.images?.desktop">
              <div class="hero-title-container">
                <h2 class="hero-title" [ngClass]="{ 'hero-title--dark': slide.alternativeStyle }">{{ slide.title }}</h2>
                <div class="hero-text" [ngClass]="{ 'hero-text--dark': slide.alternativeStyle }">{{ slide.text }}</div>
                <div class="hero-actions">
                  <a class="slide-action-button hero-btn btn btn-primary--inset btn-primary--with-hoer" (click)="selectPlan()">{{ slide.buttonText }}</a>
                  <a style="margin-top: 1rem" class="slide-action-button hero-btn btn btn-primary--inset btn-primary--with-hoer" routerLink="/gift">Send the Perfect Gift</a>
                </div>
              </div>
            </div>
          </div>
        </owl-carousel>
      </div>

      <div class="desktop-hero-slide">
        <owl-carousel #owlDesk [options]="mobileSliderOptions">
          <div *ngFor="let slide of homeData?.mainCarousel">
            <div class="slide-picture" appBgDinamic [bgDinamicXS]="slide.images?.mobile"
              [bgDinamicSM]="slide.images?.tablet"
              [bgDinamicMD]="slide.images?.desktop">
              <div class="hero-title-container">
                <h2 class="hero-title" [ngClass]="{ 'hero-title--dark': slide.alternativeStyle }">{{ slide.title }}</h2>
                <div class="hero-text" [ngClass]="{ 'hero-text--dark': slide.alternativeStyle }">{{ slide.text }}</div>
                <div class="hero-actions">
                  <a class="hero-btn btn btn-primary--inset btn-primary--with-hoer" [ngClass]="{ 'hero-btn--dark': slide.alternativeStyle }"
                    (click)="selectPlan()">{{ slide.buttonText }}</a>

                  <a style="margin-left: 1rem !important;" class="hero-btn btn btn-primary--inset btn-primary--with-hoer" [ngClass]="{ 'hero-btn--dark': slide.alternativeStyle }"
                     routerLink="/gift">Send the Perfect Gift</a>

                </div>
              </div>
            </div>
          </div>
        </owl-carousel>
      </div>
    </div>

    <div class="slide-nav slide-nav-container-right">
      <div class="nav-circle" (click)="moveSliderToRight()">
        <img src="/assets/images/right-arrow.png"/>
      </div>
    </div>
  </section>

  <!-- Plans section-->
  <section class="plans-container">
    <h1>{{ homeData?.whatYouGetSection?.sectionTitle }}</h1>
    <div class="plan-card">
      <img src="/assets/images/2x-plan.jpg" alt=""/>
      <div class="plan-content">
        <div class="plan-title">
          <h2>{{ homeData?.whatYouGetSection?.product?.title }}</h2>
          <h4>{{ homeData?.whatYouGetSection?.product?.subTitle }}</h4>
        </div>
        <p class="plan-desc" [innerHTML]="homeData?.whatYouGetSection?.product?.article | safeHTML"></p>
        <a [attr.test-id]="'regular-subscribe-button'" class="plan-btn btn btn-primary--inset btn-primary--with-hover" (click)="selectPlan()">Subscribe</a>
      </div>
    </div>
  </section>


  <!-- <sucstu-mothers-banner></sucstu-mothers-banner> -->

  <!-- Company attributes -->
  <section class="company-attributes">
    <div class="attributes-card">
      <!-- Black card -->
      <div class="plans-explanation">
        <div class="plan-explanation-top">
          <h1>{{ homeData?.autoRenewSection?.title }}</h1>
          <h5>{{ homeData?.autoRenewSection?.subTitle }}</h5>
        </div>
        <span class="plan-explanation-divider"></span>
        <div class="plan-explanation-bottom">
          <p>{{ homeData?.autoRenewSection?.text }}</p>
          <h6>{{ homeData?.autoRenewSection?.extraText }}</h6>
        </div>
      </div>
      <div class="attribute-card-container">
        <div class="attribute-card">
          <div class="circle-picture">
            <img src="/assets/images/we-grow.png" class="attribute-picture-flower">
          </div>
          <h2>{{ homeData?.infoSquares?.first?.title }}</h2>
          <p>{{ homeData?.infoSquares?.first?.text }}</p>
        </div>
      </div>
      <div class="attribute-card-container">
        <div class="attribute-card">
          <div class="circle-picture">
            <img src="/assets/images/we-ship.png" class="attribute-picture-truck">
          </div>
          <h2>{{ homeData?.infoSquares?.second?.title }}</h2>
          <p>{{ homeData?.infoSquares?.second?.text }}</p>
        </div>
      </div>
      <div class="attribute-card-container">
        <div class="attribute-card">
          <div class="circle-picture">
            <img src="/assets/images/you-enjoy.png" class="attribute-picture-succulent">
          </div>
          <h2>{{ homeData?.infoSquares?.third?.title }}</h2>
          <p>{{ homeData?.infoSquares?.third?.text }}</p>
        </div>
      </div>
      <div class="company-attributes-actions">
        <a class="btn btn-primary-inset btn-company-atrributes btn-primary--with-hover"
           (click)="selectPlan()">Subscribe</a>
        <a class="btn btn-company-succulents btn-primary-inset btn-company-atrributes btn-primary--with-hover"
           routerLink="/inspiration" queryParamsHandling="preserve">Our {{ brandData.mainProduct | uppercase }}</a>
      </div>
    </div>
  </section>

  <!-- indications section -->
  <div class="indications" *ngIf="homeData?.bottomInformation">
    <div class="oxygen-gallons">
      <div class="numbers">{{ homeData?.bottomInformation?.picture?.title }}</div>
      <span>{{ homeData?.bottomInformation?.picture?.subTitle }}</span>
    </div>
    <div class="indications-text">
      <h2>{{ homeData?.bottomInformation?.infoSquare?.title }}</h2>
      <div [innerHTML]="homeData?.bottomInformation?.infoSquare?.text | safeHTML"></div>
    </div>
  </div>
  <div class="newsletter-container" *ngIf="homeData?.newsletter">
    <div class="newsletter-title">
      <h5>{{ homeData?.newsletter?.text }}</h5>
    </div>
    <div class="newsletter-form">
      <input #newsLetterEmail class="newsletter-input" type="email" placeholder="you@example.com"/>
      <button class="newsletter-btn btn" (click)="createLead()">get $5 off</button>
    </div>
  </div>
</div>
