import { Component, OnInit } from '@angular/core';
import * as PrivacyData from "@config/privacy.json";

@Component({
  selector: 'sucstu-page-privacy-policy',
  styleUrls: ['./privacy-policy.component.scss'],
  templateUrl: 'privacy-policy.template.html'
})

export class PrivacyPolicyComponent implements OnInit {
  privacyData = (PrivacyData as any).default;

  constructor() { }

  ngOnInit() { }
}
