import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import * as AboutData from "@config/about.json"
import * as MetaTagsData from "@config/metatags.json"
import * as TitleTags from "@config/titletags.json"

@Component({
  selector: 'sucstu-pages-about',
  styleUrls: ['./about.component.scss'],
  templateUrl: './about.template.html'
})

export class AboutComponent implements OnInit {
  aboutData = (AboutData as any).default;
  metaTagsData = (MetaTagsData as any).default;
  titleTagsData = (TitleTags as any).default;

  constructor(
    private metaService: Meta,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.metaService.updateTag({
      name: 'description',
      content: this.metaTagsData.about
    });

    this.titleService.setTitle(this.titleTagsData.about);
  }
}
