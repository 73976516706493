import { Component } from '@angular/core';
import { Box } from '@app/core/models/box.model';
import {map, switchMap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromBoxesSelectors from '@app/store/selectors/boxes.selector';

@Component({
  selector: 'sucstu-summer-page',
  styleUrls: ['./summer.component.scss'],
  templateUrl: './summer.component.html'
})
export class SummerComponent {
  boxes$ = of([
    '631f790618aa6062003d1365',
    '631f790618aa6062003d1366',
    '631f790618aa6062003d1367',
    '631f790618aa6062003d1368',
    '631f790618aa6062003d1369',
    '631f790618aa6062003d136a',
  ])
    .pipe(switchMap(ids => combineLatest(ids.map(id => {
      return this.store.pipe(select(fromBoxesSelectors.getBoxById(id)));
    }))));

  cart$: BehaviorSubject<{ [boxId: string]: number }> = new BehaviorSubject<{ [boxId: string]: number }>({
    '631f790618aa6062003d1365': 0,
    '631f790618aa6062003d1366': 0,
    '631f790618aa6062003d1367': 0,
    '631f790618aa6062003d1368': 0,
    '631f790618aa6062003d1369': 0,
    '631f790618aa6062003d136a': 0,
  });

  queryParams$: Observable<{ boxId: string[], boxQty: number[] }> = this.cart$
    .pipe(map(cartState => {
      return {
        boxId: Object.keys(cartState),
        boxQty: Object.values(cartState)
      };
    }));

  constructor(
    private store: Store<any>,
  ) { }

  quantityChange(quantity: number, box: Box) {
    const currentState = this.cart$.getValue();
    this.cart$.next({
      ...currentState,
      [box._id]: quantity,
    });
  }

  preventRedirect() {
    const currentState = this.cart$.getValue();
    const hasBox = Object.keys(currentState).find(key => currentState[key] > 0);
    return !hasBox;
  }
}
