<div class="page-container">
  <div class="about-container container">
    <h1 class="section__title about__title text-medium">About</h1>
    <div class="about__container">

      <div class="about__item about__item--1">
        <div class="about__item-picture-container">
          <img [src]="aboutData?.firstSection?.photoUrl" alt="Colored Box">
        </div>
        <div class="about__item-text-container">
          <h3 class="about__item-title">{{ aboutData?.firstSection?.title }}</h3>
          <p class="about__item-subtitle">{{ aboutData?.firstSection?.subTitle }}</p>
          <div [innerHTML]="aboutData?.firstSection?.content | safeHTML"></div>
          <div *ngIf="aboutData?.firstSection?.button" class="about__item-button">
            <a [routerLink]="aboutData?.firstSection?.button?.routerLink" class="btn btn-primary btn-primary--with-hover">{{ aboutData?.firstSection?.button?.text }}</a>
          </div>
        </div>
      </div>

      <span *ngIf="aboutData?.secondSection" class="about__divider"></span>

      <div *ngIf="aboutData?.secondSection" class="about__item about__item--2">
        <div class="about__item-picture-container">
          <img [src]="aboutData?.secondSection?.photoUrl" alt="Colored Box">
        </div>
        <div class="about__item-text-container">
          <h3 class="about__item-title">{{ aboutData?.secondSection?.title }}</h3>
          <p class="about__item-subtitle">{{ aboutData?.secondSection?.subTitle }}</p>
          <div [innerHTML]="aboutData?.secondSection?.content | safeHTML"></div>
          <div *ngIf="aboutData?.secondSection?.button" class="about__item-button">
            <a [routerLink]="aboutData?.secondSection?.button?.routerLink"
              class="btn btn-primary btn-primary--with-hover">{{ aboutData?.secondSection?.button?.text }}</a>
          </div>
        </div>
      </div>

      <span *ngIf="aboutData?.thirdSection" class="about__divider"></span>

      <div *ngIf="aboutData?.thirdSection" class="about__item about__item--3">
        <div class="about__item-picture-container">
          <img [src]="aboutData?.thirdSection?.photoUrl" alt="Colored Box">
        </div>
        <div class="about__item-text-container">
          <h3 class="about__item-title">{{ aboutData?.thirdSection?.title }}</h3>
          <p class="about__item-subtitle">{{ aboutData?.thirdSection?.subTitle }}</p>
          <div [innerHTML]="aboutData?.thirdSection?.content | safeHTML"></div>
          <div *ngIf="aboutData?.thirdSection?.button" class="about__item-button">
            <a [routerLink]="aboutData?.thirdSection?.button?.routerLink"
              class="btn btn-primary btn-primary--with-hover">{{ aboutData?.thirdSection?.button?.text }}</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
