import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Box } from '@app/core/models/box.model';

@Component({
  selector: 'sucstu-summer-box-item',
  styleUrls: ['./box-item.component.scss'],
  templateUrl: 'box-item.component.html'
})
export class SummerBoxItemComponent {
  quantityCount  = 0;
  @Input() box: Box;
  @Output() quantity: EventEmitter<number> = new EventEmitter<number>();

  onIncrease() {
    this.quantityCount += 1;
    this.quantity.emit(this.quantityCount);
  }

  onDecrease() {
    if (this.quantityCount <= 0) {
      return
    }
    this.quantityCount -= 1;
    this.quantity.emit(this.quantityCount);
  }
}
