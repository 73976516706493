import { Component } from '@angular/core';
import * as faqData from "@config/faq.json"

@Component({
  selector: 'sucstu-faq',
  styleUrls: ['./faq.component.scss'],
  templateUrl: 'faq.component.html'
})
export class FaqComponent {
  faqData = (faqData as any).default;
}
