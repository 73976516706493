import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';

// rxjs
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// services
import { UserService } from './../services/user.service';

@Injectable()
export class SubscriptionInfoResolver implements Resolve<any> {

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    const subsId = route.queryParams.subscriptionId;
    if (!subsId) {
      this.router.navigate(['/profile']);
      return;
    }
    return this.userService
      .getUserSubscription(subsId)
      .pipe(catchError(e => {
        this.router.navigate(['/profile']);
        return of(e.error.error);
      }));
  }
}
