import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as subscriptionsActions from '@app/store/actions/subscription.action';
import * as addressesActions from './../actions/address.actions';
import * as shipmentsActions from './../actions/shipment.action';
// models
import { Subscription } from '@app/core/models/subscription.model';
// services
import { SubscriptionService } from '@app/core/services/subscription.service';
import { Address } from '@app/core/models/address.model';
// actions
import { OpenNotificationBarAction } from '@app/store/actions/notification-bar.actions';
import { ModalService } from '@app/core/services';

@Injectable()
export class SubscriptionEffects {

  @Effect({dispatch: false}) load$ = this.actions$
    .pipe(
      ofType(subscriptionsActions.LOAD_SUBSCRIPTIONS),
      switchMap(() => {
        return this.subscriptionService.getSubscriptions()
          .pipe(
            map(subscriptions => this.store.dispatch(new subscriptionsActions.LoadSubscriptionsCompleteAction(subscriptions))),
            catchError(reason => {
              this.store.dispatch(new subscriptionsActions.LoadSubscriptionsFailAction(reason.errors));
              return of(new subscriptionsActions.LoadSubscriptionsFailAction(reason.errors));
            })
          );
      })
    );


  @Effect({dispatch: false}) update$ = this.actions$
    .pipe(
      ofType(subscriptionsActions.UPDATE_SUBSCRIPTION),
      map((action: subscriptionsActions.UpdateSubscriptionAction) => action.payload),
      switchMap(({subscriptionId, recipient}) => {
        return this.subscriptionService.updateSubscription({_id: subscriptionId, recipient})
          .pipe(
            map((subscription: Subscription) => this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
              id: subscription._id,
              changes: {...subscription, isLoading: false}
            }))),
            catchError(reason => {
              this.store.dispatch(new subscriptionsActions.LoadSubscriptionsFailAction(reason.errors));
              return of(new subscriptionsActions.LoadSubscriptionsFailAction(reason.errors));
            })
          );
      })
    );


  @Effect({dispatch: false}) skipIt$ = this.actions$
    .pipe(
      ofType(subscriptionsActions.SKIP_IT_SUBSCRIPTION),
      map((action: subscriptionsActions.SkipItSubscriptionAction) => action.payload),
      switchMap((data: { subscriptionId: number, skipValue: string }) => {
        return this.subscriptionService.skipMonth(data.subscriptionId, data.skipValue)
          .pipe(
            map((updatedSubscription: Subscription) => {
              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                id: updatedSubscription._id,
                changes: {...updatedSubscription, isLoading: false}
              }));
            }),
            catchError(reason => {
              this.store.dispatch(new subscriptionsActions.LoadSubscriptionsFailAction(reason.error));
              return of(new subscriptionsActions.LoadSubscriptionsFailAction(reason.error));
            }),
          );
      }),
    );

  @Effect({dispatch: false}) unSkip$ = this.actions$
    .pipe(
      ofType(subscriptionsActions.UN_SKIP_SUBSCRIPTION),
      map((action: subscriptionsActions.UnSkipItSubscriptionAction) => action.payload),
      switchMap(({subscriptionId}) => {
        return this.subscriptionService.unSkipMonth(subscriptionId)
          .pipe(
            map((updatedSubscription: Subscription) => {
              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                id: updatedSubscription._id,
                changes: {...updatedSubscription, isLoading: false}
              }));
            }),
            catchError(reason => {
              this.store.dispatch(new subscriptionsActions.LoadSubscriptionsFailAction(reason.error));
              return of(new subscriptionsActions.LoadSubscriptionsFailAction(reason.error));
            }),
          );
      }),
    );

  @Effect({dispatch: false}) reActivate$ = this.actions$
    .pipe(
      ofType(subscriptionsActions.RE_ACTIVATE_SUBSCRIPTION),
      map((action: subscriptionsActions.ReActivateSubscriptionAction) => action.payload),
      switchMap((data: { subscriptionId: number, nextBillingDate: string }) => {
        return this.subscriptionService.reActivate(data.nextBillingDate, data.subscriptionId)
          .pipe(
            map((updatedSubscription: Subscription) => {
              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                id: updatedSubscription._id,
                changes: {...updatedSubscription, isLoading: false}
              }));
            }),
            catchError(reason => {
              this.store.dispatch(new subscriptionsActions.LoadSubscriptionsFailAction(reason.error));
              return of(new subscriptionsActions.LoadSubscriptionsFailAction(reason.error));
            }),
          );
      }),
    );

  @Effect({dispatch: false}) turnSubscriptionOff$ = this.actions$.pipe(
    ofType(subscriptionsActions.TURN_OFF_SUBSCRIPTION),
    map((action: subscriptionsActions.TurnOffSubscriptionAction) => action.payload),
    switchMap(({subscriptionId, turnedOffReasons, refundShipment}) => {
      return this.subscriptionService
        .turnOffAutoRenew({subscriptionId, turnedOffReasons, refundShipment})
        .pipe(
          map(({subscription, shipment}) => {

            this.store.dispatch(new OpenNotificationBarAction({
              title: '',
              message: `Your subscription is now cancelled`,
              type: 'success'
            }));

            this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
              id: subscription._id,
              changes: {...subscription, isLoading: false}
            }));

            if (shipment) {
              this.store.dispatch(new shipmentsActions.UpdateShipmentCompleteAction(shipment));
            }

            this.modalService.close(`renew-modal`);
          }),
          catchError(reason => {
            this.store.dispatch(new subscriptionsActions.TurnOffSubscriptionFailAction(reason.error));
            this.store.dispatch(new OpenNotificationBarAction({
              title: '',
              message: reason.error && reason.error.message,
              type: 'error'
            }));
            return of(new subscriptionsActions.TurnOffSubscriptionFailAction(reason.error));
          }),
        );
    }),
  );

  @Effect({dispatch: false}) turnSubscriptionOn$ = this.actions$.pipe(
    ofType(subscriptionsActions.TURN_ON_SUBSCRIPTION),
    map((action: subscriptionsActions.TurnOnSubscriptionAction) => action.payload),
    switchMap(({subscriptionId, nextBillingDate}) => {
      return this.subscriptionService
        .turnOnAutoRenew({subscriptionId, nextBillingDate})
        .pipe(
          map((subscription) => {

            this.store.dispatch(new OpenNotificationBarAction({
              title: '',
              message: `Your subscription's is now active`,
              type: 'success'
            }));

            this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
              id: subscription._id,
              changes: {...subscription, isLoading: false}
            }));

            this.modalService.close(`renew-modal`);
          }),
          catchError(reason => {
            this.modalService.close(`renew-modal`);
            this.store.dispatch(new subscriptionsActions.TurnOnSubscriptionFailAction(reason.error));
            this.store.dispatch(new OpenNotificationBarAction({
              title: '',
              message: `Can not turn on the subscription, try again.`,
              type: 'error'
            }));
            return of(new subscriptionsActions.TurnOnSubscriptionFailAction(reason.error));
          }),
        );
    }),
  );

  @Effect({dispatch: false}) giftIt$ = this.actions$
    .pipe(
      ofType(subscriptionsActions.GIFT_IT_SUBSCRIPTION),
      map((action: subscriptionsActions.GiftItSubscriptionAction) => action.payload),
      switchMap(({subscriptionId, gift}) => {
        return this.subscriptionService.giftShipment(subscriptionId, gift)
          .pipe(
            map(({subscription: updatedSubscription, address}) => {
              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                id: updatedSubscription._id,
                changes: {...updatedSubscription, isLoading: false}
              }));

              this.modalService.close(`gift-it-modal`);

              this.store.dispatch(new addressesActions.AddAddressesCompleteAction(address));
            }),
            catchError(reason => {
              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
              return of(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
            })
          );
      })
    );

  @Effect({dispatch: false}) cancelGiftIt$ = this.actions$
    .pipe(
      ofType(subscriptionsActions.CANCEL_GIFT_IT),
      map((action: subscriptionsActions.CancelGiftItSubscriptionAction) => action.payload),
      switchMap(({giftId, subscriptionId}) => {
        return this.subscriptionService.cancelGiftShipment(subscriptionId, giftId)
          .pipe(
            map((subscription: Subscription) => {
              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                id: subscription._id,
                changes: {...subscription, isLoading: false}
              }));
            }),
            catchError(reason => {
              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
              return of(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
            })
          );
      })
    );

  @Effect({dispatch: false}) cancelGiftItExtraBox$ = this.actions$
    .pipe(
      ofType(subscriptionsActions.CANCEL_GIFT_IT_EXTRA_BOX),
      map((action: subscriptionsActions.CancelGiftItExtraBoxSubscriptionAction) => action.payload),
      switchMap(({giftId, subscriptionId}) => {
        return this.subscriptionService.cancelGiftExtraBoxShipment(subscriptionId, giftId)
          .pipe(
            map((subscription: Subscription) => {
              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                id: subscription._id,
                changes: {...subscription, isLoading: false}
              }));

              this.store.dispatch(new OpenNotificationBarAction({
                title: '',
                message: `Next shipment successfully canceled! Your gift box is still scheduled.`,
                type: 'success'
              }));
            }),
            catchError(reason => {
              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
              return of(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
            })
          );
      })
    );

  @Effect({dispatch: false}) cancel$ = this.actions$
    .pipe(
      ofType(subscriptionsActions.CANCEL_SUBSCRIPTION),
      map((action: subscriptionsActions.CancelSubscriptionAction) => action.payload),
      switchMap(({subscriptionId, reasons}) => {
        return this.subscriptionService.cancelSubscription(subscriptionId, reasons)
          .pipe(
            map((updatedSubscription: Subscription) => {

              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                id: updatedSubscription._id,
                changes: {...updatedSubscription, isLoading: false}
              }));

              this.modalService.open(`cancel-subscription-success`);
            }),
            catchError(reason => {
              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
              return of(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
            })
          );
      })
    );

  @Effect({dispatch: false}) updateAddress$ = this.actions$
    .pipe(
      ofType(subscriptionsActions.UPDATE_SUBSCRIPTION_ADDRESS),
      map((action: subscriptionsActions.UpdateSubscriptionAddressAction) => action.payload),
      switchMap(({subscriptionId, address}) => {
        return this.subscriptionService.updateSubscriptionAddress({subscriptionId, address})
          .pipe(
            tap((data: { subscription: Subscription, address: Address }) => {
              // Update subscription.
              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionAddressCompleteAction({
                id: data.subscription._id,
                changes: {...data.subscription, isLoading: false}
              }));

              // Add new address to the store.
              this.store.dispatch(new addressesActions.AddAddressesCompleteAction(data.address));
            }),
            catchError(reason => {
              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionAddressFailAction(reason.error));
              return of(new subscriptionsActions.UpdateSubscriptionAddressFailAction(reason.error));
            }),
          );
      })
    );

  @Effect({dispatch: false}) updateNBD$ = this.actions$
    .pipe(
      ofType(subscriptionsActions.UPDATE_SUBSCRIPTION_NBD),
      map((action: subscriptionsActions.UpdateSubscriptionNBDAction) => action.payload),
      switchMap(({subscriptionId, newBillingDate}) => {
        return this.subscriptionService.updateSubscriptionNBD({subscriptionId, newBillingDate})
          .pipe(
            tap((data: { subscription: Subscription }) => {
              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionNBDCompleteAction({
                id: data.subscription._id,
                changes: {...data.subscription, isLoading: false}
              }));

              this.modalService.close(`update-nbd-modal`);
            }),
            catchError(reason => {
              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionNBDFailAction(reason.error));
              return of(new subscriptionsActions.UpdateSubscriptionNBDFailAction(reason.error));
            }),
          );
      })
    );

  @Effect({dispatch: false}) reactivateSubscriptionFreeShipment$ = this.actions$
    .pipe(
      ofType(subscriptionsActions.REACTIVATE_SUBSCRIPTION_FREE_SHIPMENT),
      map((action: subscriptionsActions.ReactivateSubscriptionWithFreeShipment) => action.payload),
      switchMap(({subscriptionId}) => {
        return this.subscriptionService.reactivateSubscriptionWinBack(subscriptionId)
          .pipe(
            tap(({subscription, shipment}) => {
              this.modalService.close('reactivate-winback-email');
              this.store.dispatch(new OpenNotificationBarAction({
                title: '',
                message: `You're all set! Your subscription has been reactivated, and your succulent box has been processed with free shipping!`,
                type: 'success'
              }));

              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                id: subscription._id,
                changes: {...subscription, isLoading: false}
              }));

              this.store.dispatch(new shipmentsActions.AddShipmentCompleteAction(shipment));

            }),
            catchError(reason => {
              this.store.dispatch(new subscriptionsActions.UpdateSubscriptionNBDFailAction(reason.error));
              return of(new subscriptionsActions.UpdateSubscriptionNBDFailAction(reason.error));
            }),
          );
      })
    );

  @Effect({dispatch: false}) unselectBox$ = this.actions$
    .pipe(
      ofType(subscriptionsActions.UNSELECT_BOX),
      map((action: subscriptionsActions.UnselectBoxAction) => action.payload),
      switchMap(({subscriptionId, boxId}) => {
        console.log('UNSELECT!!!');
        return this.subscriptionService.unselectSubscriptionBox(subscriptionId, boxId)
          .pipe(
            tap(({subscription, shipment}) => {
              this.store.dispatch(new OpenNotificationBarAction({
                title: '',
                message: `You're all set! You will receive the regular box now.`,
                type: 'success'
              }));
            }),
            catchError(reason => {
              console.log(reason);
              return of(false);
            }),
          );
      })
    );

  @Effect({dispatch: false}) change$ = this.actions$
    .pipe(
      ofType(subscriptionsActions.SWITCH_2x_BUNDLE),
      map((action: subscriptionsActions.Switch2xBundleAction) => action.payload),
      switchMap(({subscriptionId}) => {
        return this.subscriptionService
          .switch2xBundle(subscriptionId)
          .pipe(
            map((subscription) => {
              this.store.dispatch(new OpenNotificationBarAction({
                title: '',
                message: `Your plan has been updated and saved!`,
                type: 'success'
              }));
              this.store.dispatch(new subscriptionsActions.Switch2xBundleCompleteAction({
                id: subscription._id,
                changes: {...subscription, isLoading: false}
              }));
              this.store.dispatch(new subscriptionsActions.LoadSubscriptionsAction());
            }),
            catchError(reason => {
              this.modalService.close(`renew-modal`);
              this.store.dispatch(new subscriptionsActions.Switch2xBundleFailAction(reason.error));
              this.store.dispatch(new OpenNotificationBarAction({
                title: '',
                message: `Cannot change plan, try again.`,
                type: 'error'
              }));
              return of(new subscriptionsActions.Switch2xBundleFailAction(reason.error));
            }),
          );
      }),
      // tap((planId: string) => this.sessionStorage.store('selectedPlanId', planId))
    );

  constructor(
    private subscriptionService: SubscriptionService,
    private modalService: ModalService,
    private actions$: Actions,
    private store: Store<any>
  ) {
  }

}

