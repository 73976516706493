import { InjectionToken } from '@angular/core';
import { environment } from './../environments/environment';

export interface AppConfig {
  name: string;
  production: boolean;
  api_uri: string;
  app_version: string;
  propsToClearOnLS: {
    selectedPlanId: boolean,
  };
  pca_predict: {
    key: string,
    api_endpoint: string
  };
}

export const app_config: AppConfig = environment;

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');
