import { Coupon } from '@app/core/models/coupon.model';
import * as coupon from '@app/store/actions/coupon.actions';

export interface State {
  data: Coupon,
  searching: boolean,
  searched: boolean,
  valid: boolean,
  invalid: boolean,
  errors: any[]
};

export const initialState: State = {
  data: null,
  searching: false,
  searched: true,
  valid: false,
  invalid: false,
  errors: []
};

export function reducer (
  state: State = initialState,
  action: coupon.Actions
): State {
  switch (action.type) {

    case coupon.VERIFY_COUPON: {
      return { ...state, searching: true, searched: false, invalid: false, valid: false, errors: [] };
    }

    case coupon.VERIFY_COUPON_COMPLETE: {
      return { ...state, data: action.payload, searching: false, searched: true, invalid: false, valid: true };
    }

    case coupon.VERIFY_COUPON_FAIL: {
      return { ...state, data: null, searching: false, searched: true, invalid: true, valid: false, errors: action.payload };
    }

    case coupon.CLEAR_COUPON: {
      return { ...state, data: null, searching: false, searched: true, invalid: false, valid: false, errors: [] };
    }

    default: {
      return state
    }
  }
}

export const getCouponData = (state: State) => state.data;
export const getSearchingCoupon = (state: State) => state.searching;
export const getSearchedCoupon = (state: State) => state.searched;
export const getValidCoupon = (state: State) => state.valid;
export const getInvalidCoupon = (state: State) => state.invalid;
export const getErrorsCoupon = (state: State) => state.errors;
