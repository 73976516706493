import { Directive, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';

@Directive({
  selector: '[appNonAutocomplete]'
})
export class NonAutocompleteDirective implements OnDestroy {
  timer$: Observable<number> = timer(0, 1000);
  timerSubscription$: Subscription = this.timer$.subscribe(this.onfocus.bind(this));

  @HostBinding('name')
  @HostBinding('autocomplete')
  inputName = new Date().toISOString();

  @HostListener('focus')
  onfocus() {
    this.inputName = new Date().toISOString();
  }

  ngOnDestroy(): void {
    this.timerSubscription$.unsubscribe();
  }
}
