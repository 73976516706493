import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

// services
import { AuthService } from '@app/auth/services/auth.service';

// actions
import * as user from '@app/store/actions/user.actions';

// selectors
import * as fromUser from '@app/store/selectors/user.selector';

@Injectable()
export class RecoveryPasswordGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store<any>
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const recoveryToken = route.queryParams.recoveryToken;
    const authToken = this.authService.token;
    const isAuthenticated$ = this.store.pipe(select(fromUser.getUserIsAuthenticated));

    if (!recoveryToken) {
      this.router.navigate(['/']);
      return false;
    }

    if (authToken) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}
