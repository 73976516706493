
    <div class="notification-bar" [ngClass]="{
      'notification-bar-active': data.open,
      'notification-bar-disabled': !data.open,
      'notification-bar--success': data.type === 'success',
      'notification-bar--error': data.type === 'error'
    }">
      <span class="notification-bar-title">{{ data.title }}</span>
      <p class="notification-bar-message">{{ data.message }}</p>
      <span class="close-icon" (click)="closeBanner()">X</span>
    </div>
  