import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';

import { map, catchError } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from './../../app.config';
import { of } from 'rxjs';

import { ErrorService } from '@app/error';
import { ModalInfoService } from './../../core/services/modal-info';

interface APIResponse {
  client_token: string;
}

@Injectable()
export class ClientAuthorizationResolver implements Resolve<string> {

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorService: ErrorService,
    private modalInfoService: ModalInfoService,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.http
      .get<APIResponse>(`${this.app_config.api_uri}/braintree/client_token`)
      .pipe(
        map(data => data.client_token),
        catchError(error => {
          const message = `Error trying to fetch Braintree's client token in the ClientAuthorizationResolver.`;
          console.error(message);
          this.errorService.client.notify(error, {
            beforeSend: report => {
              report.severity = 'warning';
              report.updateMetaData('extras', {
                message,
                http: true,
                client: false,
              });
            }
          })
          this.router.navigate(['/']);
          this.modalInfoService.open({
            data: {
              title: 'Ooops!',
              message: 'There was an error trying to load the checkout page. Please try again.',
            }
          });
          return of(error);
        })
      );
  }

}
