import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as GiftData from "@config/gift.json"

@Component({
  selector: 'sucstu-gift-message-selection',
  templateUrl: './gift-message-selection.component.html',
  styleUrls: ['./gift-message-selection.component.scss']
})
export class GiftMessageSelectionComponent {
  giftData = (GiftData as any).default;

  @Input() control: FormControl;
  @Output() changeMessage: EventEmitter<string> = new EventEmitter<string>();

  messageList = this.giftData.giftMessagesIdeas;

  constructor() { }

  onChangeGiftMessage(event: Event) {
    const selectElement = this.getElementTarget(event);
    const message = this.getMessageFromElement(selectElement);
    this.control.setValue(message);
    this.changeMessage.emit(message);
  }

  onChangeGiftSelection(event: Event) {
    const selectElement = this.getElementTarget(event);
    const selectedMessage = this.getMessageFromElement(selectElement);
    const oldMessage = this.control.value;
    let message = '';

    if (this.isAPredefinedMessage(oldMessage)) {
      message = selectedMessage;
    } else {
      message = oldMessage.length
        ? `${oldMessage} ${selectedMessage}`
        : `${selectedMessage}`;
    }

    this.control.setValue(message);
    this.changeMessage.emit(message);
    this.resetSelectState(selectElement);
  }

  isAPredefinedMessage(message: string): boolean {
    return this.messageList.includes(message.trim());
  }

  getMessageFromElement(selectElement: HTMLFormElement) {
    const message = selectElement.value;
    return message;
  }

  resetSelectState(selectElement: HTMLFormElement) {
    selectElement.value = '';
  }

  getElementTarget(event: Event) {
    return event.target as HTMLFormElement;
  }
}
