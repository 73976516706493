import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

// RxJS
import { Observable, of, forkJoin } from 'rxjs';
import { take, switchMap, timeout, tap, filter, catchError } from 'rxjs/operators';

// actions
import * as boxActions from '@app/store/actions/box.actions';
import * as userActions from '@app/store/actions/user.actions';
import * as addressActions from '@app/store/actions/address.actions';

// selectors
import * as fromRoot from '@app/store/selectors';
import * as fromUser from '@app/store/selectors/user.selector';
import * as fromBoxes from '@app/store/selectors/boxes.selector';
import * as fromAddresses from '@app/store/selectors/address.selectors';

@Injectable()
export class BoxPurchaseGuard implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<any>
  ) { }

  getProfileFromStoreOrApi(): Observable<any> {
    return this.store
      .select(fromRoot.getUserState)
      .pipe(
        tap((data: fromUser.State) => {
          if (!data.isAuthenticated) {
            this.store.dispatch(new userActions.LoadAction());
          }
        }),
        filter((data: fromUser.State) => data.isAuthenticated),
        take(1),
        timeout(5000),
        catchError((e) => of(false))
      );
  }

  getAddressesFromStoreOrApi(): Observable<any> {
    this.store.dispatch(new addressActions.LoadAddressesAction());
    return this.store
      .select(fromAddresses.getAddressesLoaded)
      .pipe(
        filter(loaded => loaded),
        take(1),
        timeout(5000),
        catchError((e) => of(false))
      );
  }

  // TODO: develop a specific endpoint to get specific boxes from API
  getBoxesFromStoreOrApi(): Observable<any> {
    this.store.dispatch(new boxActions.LoadBoxesAction());
    return this.store
      .select(fromBoxes.getBoxesLoaded)
      .pipe(
        filter(loaded => loaded),
        take(1),
        timeout(5000),
        catchError((e) => {
          return of(false);
        })
      );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.getProfileFromStoreOrApi().pipe(
      switchMap(
        () => forkJoin([
          this.getAddressesFromStoreOrApi(),
          this.getBoxesFromStoreOrApi(),
        ])
      ),
      switchMap((data) => of(true)),
      catchError((e) => this.router.navigate(['/profile'])),
    );
  }
}
