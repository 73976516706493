
    <footer class="footer" *ngIf="!isSales">

      <div class="footer-links-container">
        <div *ngFor="let link of footerData?.links" class="footer-links__group">
          <h5 class="footer-links__title text-medium">{{ link.title }}</h5>
          <ul *ngFor="let row of link.rows" class="footer-links__list">
            <li [innerHTML]="row | safeHTML" class="footer-link__item"></li>
          </ul>
        </div>
      </div>

      <div class="footer-social-links">
        <div class="footer-social-links__group">
          <a
            *ngIf="brandData?.socialMedia?.facebook"
            class="social-link__item"
            [href]="brandData.socialMedia.facebook"
            target="a_blank">
            <img src="/assets/images/facebook@2x.png" alt="Facebook Icon">
          </a>
          <a
            *ngIf="brandData?.socialMedia?.twitter"
            class="social-link__item"
            [href]="brandData.socialMedia.twitter"
            target="a_blank">
            <img src="/assets/images/twitter.svg" alt="Twitter Icon ">
          </a>
          <a
            *ngIf="brandData?.socialMedia?.pinterest"
            class="social-link__item"
            [href]="brandData.socialMedia.pinterest"
            target="a_blank">
            <img src="/assets/images/pinterest-black@2x.png" alt="Pinterest Icon">
          </a>
          <a
            *ngIf="brandData?.socialMedia?.instagram"
            class="social-link__item"
            [href]="brandData.socialMedia.instagram"
            target="a_blank">
            <img src="/assets/images/instagram@2x.png" alt="Instagram Icon">
          </a>
        </div>
        <a
          (click)="moveToTop()"
          class="go-top-btn"><img src="/assets/images/go-top.png" alt=""></a>
      </div>
    </footer>
  