import { Component, OnInit } from '@angular/core';
import * as TermsData from "@config/terms.json";

@Component({
  selector: 'sucstu-app-terms',
  styleUrls: ['./terms.component.scss'],
  templateUrl: './terms.component.html'
})
export class TermsComponent {
  termsData = (TermsData as any).default;

  constructor() { }
}
