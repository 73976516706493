import { HttpClient, HttpHeaders } from '@angular/common/http';
// config
import { AppConfig } from './../../app.config';
var LeadsService = /** @class */ (function () {
    function LeadsService(httpClient, app_config) {
        this.httpClient = httpClient;
        this.app_config = app_config;
    }
    LeadsService.prototype.create = function (_a) {
        var uuid = _a.uuid, email = _a.email, utmData = _a.utmData;
        var headers = new HttpHeaders({ retry: 'yes' });
        return this.httpClient
            .post(this.app_config.api_uri + "/leads", { uuid: uuid, email: email, utmData: utmData }, { headers: headers });
    };
    return LeadsService;
}());
export { LeadsService };
