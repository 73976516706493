import * as tslib_1 from "tslib";
import { isPlatformBrowser } from '@angular/common';
var GooglePlacesService = /** @class */ (function () {
    function GooglePlacesService(platformId) {
        this.platformId = platformId;
        this.counter = 0;
    }
    GooglePlacesService.prototype.generateId = function () {
        return ++this.counter;
    };
    GooglePlacesService.prototype.getAddressComponent = function (type, components, field) {
        var componentIndex = components
            .findIndex(function (ac) {
            var typeIndex = ac.types.findIndex(function (t) { return t === type; });
            return typeIndex !== -1;
        });
        var component = components[componentIndex];
        return typeof component !== 'undefined'
            ? tslib_1.__assign({}, component, { field: field, exists: true }) : {
            short_name: '',
            long_name: '',
            field: field,
            exists: false
        };
    };
    GooglePlacesService.prototype.onChangeAddress = function (autocomplete, cb) {
        var address = autocomplete.getPlace();
        if (!address || !address.address_components) {
            return cb({ error: "The address is wrong or is not completed." });
        }
        var addressComponents = address.address_components;
        var city = this.getAddressComponent('locality', addressComponents, 'city');
        var city2 = this.getAddressComponent('sublocality', addressComponents, 'city');
        var country = this.getAddressComponent('country', addressComponents, 'country');
        var state = this.getAddressComponent('administrative_area_level_1', addressComponents, 'state');
        var postalCode = this.getAddressComponent('postal_code', addressComponents, 'postal code');
        var streetNumber = this.getAddressComponent('street_number', addressComponents, 'street number');
        var route = this.getAddressComponent('route', addressComponents, 'route');
        var postalCodeSuffix = this.getAddressComponent('postal_code_suffix', addressComponents, 'postal code suffix');
        if ((streetNumber.exists) &&
            (city.exists || city2.exists) &&
            (country.exists) &&
            (state.exists) &&
            (postalCode.exists) &&
            (route.exists)) {
            var data = {
                city: city.exists ? city.long_name : city2.long_name,
                country: country.long_name,
                countryCode: country.short_name,
                formattedLine: streetNumber.short_name + " " + route.short_name,
                label: '',
                line: streetNumber.short_name + " " + route.short_name,
                shippingAddress: streetNumber.short_name + " " + route.short_name,
                state: state.long_name,
                stateCode: state.short_name,
                zip: "" + postalCode.short_name + (postalCodeSuffix.exists ? '-' + postalCodeSuffix.short_name : ''),
            };
            cb(data);
        }
        else {
            var createText = function (arr) { return arr.reduce(function (text, current) {
                return (current.long_name || current.short_name) ? text : text + " " + current.field + ",";
            }, ''); };
            cb({
                error: "The address is wrong or is not completed, you are missing: " + createText([
                    city, country, state, postalCode, route, streetNumber
                ]).slice(0, -1) + ".",
                streetNumber: streetNumber.short_name,
                route: route.short_name
            });
        }
    };
    GooglePlacesService.prototype.listenGooglePlaces = function (elementId, cb) {
        var _this = this;
        if (!isPlatformBrowser(this.platformId)) {
            return null;
        }
        this.counter++;
        var addressInput = document.getElementById(elementId);
        if (typeof google === 'undefined' || addressInput === null) {
            console.log('Google places is not loaded');
            return null;
        }
        var autocomplete = new google.maps.places.Autocomplete(addressInput, {
            types: ['address'],
            componentRestrictions: { country: 'US' }
        });
        autocomplete.addListener('place_changed', function (data) { return _this.onChangeAddress(autocomplete, cb); });
        addressInput.addEventListener('blur', function () {
            setTimeout(_this.onChangeAddress(autocomplete, cb), 10);
        });
    };
    GooglePlacesService.prototype.getAvailableStates = function () {
        var states = [
            { stateCode: 'al', state: 'alabama' },
            { stateCode: 'ak', state: 'alaska' },
            { stateCode: 'az', state: 'arizona' },
            { stateCode: 'ar', state: 'arkansas' },
            { stateCode: 'ca', state: 'california' },
            { stateCode: 'co', state: 'colorado' },
            { stateCode: 'ct', state: 'connecticut' },
            { stateCode: 'de', state: 'delaware' },
            { stateCode: 'dc', state: 'district of columbia' },
            { stateCode: 'fl', state: 'florida' },
            { stateCode: 'ga', state: 'georgia' },
            { stateCode: 'hi', state: 'hawaii' },
            { stateCode: 'id', state: 'idaho' },
            { stateCode: 'il', state: 'illinois' },
            { stateCode: 'in', state: 'indiana' },
            { stateCode: 'ia', state: 'iowa' },
            { stateCode: 'ks', state: 'kansas' },
            { stateCode: 'ky', state: 'kentucky' },
            { stateCode: 'la', state: 'louisiana' },
            { stateCode: 'me', state: 'maine' },
            { stateCode: 'md', state: 'maryland' },
            { stateCode: 'ma', state: 'massachusetts' },
            { stateCode: 'mi', state: 'michigan' },
            { stateCode: 'mn', state: 'minnesota' },
            { stateCode: 'ms', state: 'mississippi' },
            { stateCode: 'mo', state: 'missouri' },
            { stateCode: 'mt', state: 'montana' },
            { stateCode: 'ne', state: 'nebraska' },
            { stateCode: 'nv', state: 'nevada' },
            { stateCode: 'nh', state: 'new hampshire' },
            { stateCode: 'nj', state: 'new jersey' },
            { stateCode: 'nm', state: 'new mexico' },
            { stateCode: 'ny', state: 'new york' },
            { stateCode: 'nc', state: 'north carolina' },
            { stateCode: 'nd', state: 'north dakota' },
            { stateCode: 'oh', state: 'ohio' },
            { stateCode: 'ok', state: 'oklahoma' },
            { stateCode: 'or', state: 'oregon' },
            { stateCode: 'pa', state: 'pennsylvania' },
            { stateCode: 'ri', state: 'rhode island' },
            { stateCode: 'sc', state: 'south carolina' },
            { stateCode: 'sd', state: 'south dakota' },
            { stateCode: 'tn', state: 'tennessee' },
            { stateCode: 'tx', state: 'texas' },
            { stateCode: 'ut', state: 'utah' },
            { stateCode: 'vt', state: 'vermont' },
            { stateCode: 'va', state: 'virginia' },
            { stateCode: 'wa', state: 'washington' },
            { stateCode: 'wv', state: 'west virginia' },
            { stateCode: 'wi', state: 'wisconsin' },
            { stateCode: 'wy', state: 'wyoming' },
        ];
        return states;
    };
    return GooglePlacesService;
}());
export { GooglePlacesService };
