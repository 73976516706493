// import {
//   trigger,
//   state,
//   style,
//   animate,
//   transition
// } from '@angular/animations';

import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';

import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { environment } from '@env/environment';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { select, Store } from '@ngrx/store';
// Angulartics
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
// RxJS
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
// services
import { CouponsService } from '@app/core/services/coupons.service';
import { AuthService } from '@app/auth/services/auth.service';
import { AppService, LeadsService } from '@app/core/services';
import { ErrorService } from './error/services';
// actions
// actions
import * as userActions from '@app/store/actions/user.actions';
import * as couponsActions from '@app/store/actions/coupon.actions';
import * as notificationBarActions from '@app/store/actions/notification-bar.actions';
// selectors
import * as fromRoot from '@app/store/selectors';
import * as fromCoupons from '@app/store/selectors/coupons.selector';
import * as fromUser from '@app/store/selectors/user.selector';
// models
import { Coupon } from '@app/core/models/coupon.model';
// components
import { NotificationBarComponent } from '@app/shared/components';
import { CouponBannerComponent } from '@app/shared/components/coupon-banner/coupon-banner.component';
import { MothersBannerComponent } from '@app/shared/components/mothers-banner/mothers-banner.component';
import { paramsMap } from '@app/core/mappers/params-map.mapper';

import {AnglerAiService} from '@app/core/services/angler-ai/angler-ai.service';

@Component({
  selector: 'sucstu-root',
  template: `
    <div class="app-loading" *ngIf="loading">
      <div class="logo"></div>
      <svg class="spinner" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
      </svg>
    </div>
    <div
      class="content">
      <!-- [@appState]="state" -->
      <div class="main">

        <sucstu-notification-bar
          [data]="(notificationBar$ | async)"
          (close)="onCloseNotificationBar()">
        </sucstu-notification-bar>

        <sucstu-coupon-banner
          [showBanner]="showCouponBanner"
          [coupon]="coupon$ | async"
          (close)="onCloseCouponBanner($event)">
        </sucstu-coupon-banner>

        <sucstu-header
          [moveFromTop]="notificationBarHeight">
        </sucstu-header>

        <div class="app-container">
          <router-outlet (deactivate)="onDeactivate()"></router-outlet>
        </div>

      </div>
      <sucstu-footer class="footer-container"></sucstu-footer>
    </div>
  `,
  styleUrls: ['./app.component.scss'],
  // animations: [
  //   trigger('appState', [
  //     state('inactive', style({ opacity: '0' })),
  //     state('active', style({ opacity: '1' })),
  //     transition('inactive <=> active', animate('500ms ease-out'))
  //   ])
  // ]
})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild(NotificationBarComponent, { read: ElementRef }) notificationBarComponent: ElementRef;
  @ViewChild(CouponBannerComponent, { read: ElementRef }) couponBarComponent: ElementRef;
  @ViewChild(MothersBannerComponent, { read: ElementRef }) mothersBannerComponent: ElementRef;

  user$: Observable<fromUser.State>;
  notificationBar$: Observable<any>;
  coupon$: Observable<Coupon>;
  sessionCoupon: Coupon;

  loading = true;
  state = 'inactive';
  showCouponBanner: boolean;

  notificationBarHeight = 0;
  displayMotherBanner = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private authService: AuthService,
    private angulartics: Angulartics2GoogleTagManager,
    private leadService: LeadsService,
    private errorService: ErrorService,
    private store: Store<fromRoot.State>,
    private couponService: CouponsService,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private readonly anglerAiService: AnglerAiService,
  ) {
    this.angulartics.startTracking();
    router.events
      .pipe(withLatestFrom(this.store))
      .subscribe(([event, state]) => {
        this.navigationInterceptor(event as RouterEvent);

        if (event instanceof NavigationEnd) {
          this.sendSmarterChaosIdentify(state.user as fromUser.State);
        }
      });
  }

  ngOnInit() {
    this.user$ = this.store.pipe(select(fromRoot.getUserState));
    this.coupon$ = this.store.pipe(select(fromCoupons.getCouponData));
    this.notificationBar$ = this.store.pipe(select(fromRoot.getNotificationBarState));
    this.sessionCoupon = JSON.parse(this.sessionStorage.retrieve('coupon'));
    this.showCouponBanner = JSON.parse(this.localStorage.retrieve('showCouponBanner'));

    // Clear LS props when needed.
    const currentAppVersion = this.localStorage.retrieve('appVersion');
    if (!currentAppVersion || currentAppVersion !== environment.app_version) {
      this.localStorage.store('appVersion', environment.app_version);
      Object.keys(environment.propsToClearOnLS)
        .filter(key => environment.propsToClearOnLS[key])
        .forEach(key => this.localStorage.clear(key));
    }

    // Set UUID in order to be capable
    // of recognize the client when the lead
    // data is saved/updated
    const availableAffiliateRefs = ['clickref', 'clickRef'];
    const availableLeadsProps = [
      'utm_medium',
      'utm_term',
      'utm_campaign',
      'utm_source',
      'utm_content',
      'http_referrer_url',
      'source'
    ];

    const queryParams$ = this.route.queryParams
    .pipe(
      filter((data: any) => !!Object.keys(data).length),
      map(paramsMap),
    );

    const utmData$ = queryParams$.pipe(
      filter((data: any) => {
        const value = Object.keys(data)
          .findIndex(key => availableLeadsProps.includes(key));
        return value !== -1;
      }),
    );

    const affiliateRef$ = queryParams$.pipe(
      map((data: any) => {
        const key = Object.keys(data).find(item => availableAffiliateRefs.includes(item));
        return data[key] || null;
      }),
      filter(data => !!data)
    ).subscribe(data => {
      this.sessionStorage.store('affiliateRef', data);
    });

    const storedUUID = this.localStorage.retrieve('uuid');
    const getUUID$ = storedUUID ? of(storedUUID) :
      this.appService.getUUID().pipe(tap((uuid) => this.localStorage.store('uuid', uuid)));

    getUUID$
      .pipe(
        take(1),
        switchMap(uuid => {
          return utmData$.pipe(map(utmData => ({ uuid, params: utmData })));
        }),
        switchMap(({ uuid, params }) => {
          console.log('Here is', uuid, params);
          this.sessionStorage.store('utmData', {
            ...params,
            utm_source: params.utm_source || params.source,
          });
          return this.leadService.create({ uuid, utmData: params });
        }),
      ).subscribe(
      (data) => console.log(data),
      (error) => {
        const message = `Error trying to create UUID/Lead.`;
        this.errorService.client.notify(error, {
          beforeSend: report => {
            report.severity = 'warning';
            report.updateMetaData('extras', {
              http: true,
              client: true,
              message,
              error,
            });
          }
        });
      }
    );

    this.user$
      .subscribe((user: fromUser.State) => {
        if (!user.isAuthenticated && this.authService.token) {
          this.store.dispatch(new userActions.LoadAction());
        }
      });

    this.route.queryParams
      .pipe(
        map(queryParams => queryParams.token),
        filter(token => token && !this.authService.token),
        switchMap(token => this.authService.checkToken(token)),
      )
      .subscribe(
        token => {
          this.authService.setJWTToken(token);
          this.store.dispatch(new userActions.LoadAction());
        },
        error => {
          this.router.navigate(['/login']);
          console.log(error);
        }
      );

    this.coupon$
      .subscribe((coupon: Coupon) => {
        if (coupon) {
          this.localStorage.store('showCouponBanner', true);
          this.sessionStorage.store('coupon', JSON.stringify(coupon));
          this.showCouponBanner = true;
          return;
        }

        this.localStorage.store('showCouponBanner', false);
        this.showCouponBanner = false;
      });

    this.localStorage
      .observe('authToken')
      .subscribe(token => {
        if (!token) {
          this.store.dispatch(new userActions.LogoutCompleteAction());
        }
      });

    this.route.queryParams
      .pipe(
        map(queryParams => queryParams.ccode),
        filter((value) => value &&
          this.route.snapshot.queryParams.bogo !== 'true' &&
          !this.route.snapshot.queryParams.bradsdeals),
      )
      .subscribe(coupon => {
        this.store.dispatch(new couponsActions.VerifyCouponAction(coupon));
      });

    // initialize intercom
    if ((window as any).Intercom) {
      (window as any).Intercom('boot', {
        app_id: 'kdi5b3f0'
      });
    }

    this.displayMotherBanner = this.router.url === '/';
  }

  ngAfterViewInit() {
    this.notificationBar$
      .subscribe(notificationBar => {
        const element = this.notificationBarComponent.nativeElement as HTMLElement;
        if (element && notificationBar.open) {
          const container = element.querySelector('.notification-bar');
          setTimeout(() => {
            this.notificationBarHeight = container.clientHeight - 1;
          });
        } else {
          this.notificationBarHeight = 0;
        }
      });

    this.coupon$
      .subscribe(coupon => {
        const element = this.couponBarComponent.nativeElement as HTMLElement;
        if (this.showCouponBanner && element && coupon) {
          const container = element.querySelector('.coupon-banner');
          setTimeout(() => {
            this.notificationBarHeight = container.clientHeight - 1;
          });
        } else {
          this.notificationBarHeight = 0;
        }
      });
  }

  onCloseCouponBanner(event: any) {
    this.showCouponBanner = false;
    this.notificationBarHeight = 0;
    this.localStorage.store('showCouponBanner', false);
  }

  onCloseNotificationBar() {
    this.store.dispatch(new notificationBarActions.CloseNotificationBarAction());
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      window.scroll(0, 0);
      // this.state = 'inactive';
      this.loading = true;
    }

    if (event instanceof NavigationEnd) {
      // this.state = 'active';
      // this.loading = false
      if ((window as any).Intercom) {
        (window as any).Intercom('update');
      }

      this.user$
        .pipe(take(1))
        .subscribe(user => {
          this.anglerAiService.notifyPageView({
            customer: user.data
          })
            .then()
            .catch(e => {
              console.error(e);
            });
        });
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel || event instanceof NavigationEnd || event instanceof NavigationError) {
      this.loading = false;
    }
  }

  sendSmarterChaosIdentify(user: fromUser.State) {
    if (user.isAuthenticated && (window as any).ire) {
      (window as any).ire('identify', {
        customerId: user.data._id,
        customerEmail: user.data.email
      });
    }
  }

  onDeactivate() {
    window.scroll(0, 0);
    // this.showCouponBanner = false;
  }

}
