import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Add GTM script to <head>
const gtmHeadScript = document.createElement('script');
const gtmBodyScript = document.createElement('noscript');

const gtmBodyIframe = document.createElement('iframe');
gtmBodyIframe.src = `https://www.googletagmanager.com/ns.html?id=${environment.gtmContainerId}${environment.gtmEnvironment}`;
gtmBodyIframe.height = '0';
gtmBodyIframe.width = '0';
gtmBodyIframe.style.display = 'none';
gtmBodyIframe.style.visibility = 'hidden';

gtmBodyScript.appendChild(gtmBodyIframe);

gtmHeadScript.innerHTML = `(function (w, d, s, l, i) {
  w[l] = w[l] || []; w[l].push({
    'gtm.start':
      new Date().getTime(), event: 'gtm.js'
  }); var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl + '${environment.gtmEnvironment}'; f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', '${environment.gtmContainerId}');`;

document.head.appendChild(gtmHeadScript);
document.body.appendChild(gtmBodyScript);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
