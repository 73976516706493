/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/faq-question/faq-question.component.ngfactory";
import * as i3 from "./components/faq-question/faq-question.component";
import * as i4 from "../../../shared/pipes/safe-html.pipe";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common";
import * as i7 from "./faq.component";
var styles_FaqComponent = [i0.styles];
var RenderType_FaqComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FaqComponent, data: {} });
export { RenderType_FaqComponent as RenderType_FaqComponent };
function View_FaqComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["class", "section__title faq__title text-medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.faqData.title; _ck(_v, 1, 0, currVal_0); }); }
function View_FaqComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.faqData == null) ? null : _co.faqData.firstText); _ck(_v, 1, 0, currVal_0); }); }
function View_FaqComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "sucstu-faq-question", [], null, null, null, i2.View_FaqQuestionComponent_0, i2.RenderType_FaqQuestionComponent)), i1.ɵdid(1, 49152, null, 0, i3.FaqQuestionComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "h3", [["class", "question__title"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, 1, 1, "div", [["class", "question__answer question__answer-content"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(5, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.title)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.answer)); _ck(_v, 4, 0, currVal_1); }); }
export function View_FaqComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.SafeHtmlPipe, [i5.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "section", [["class", "faq-container container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqComponent_2)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h6", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(8, 1), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "questions-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqComponent_3)), i1.ɵdid(11, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.faqData == null) ? null : _co.faqData.title); _ck(_v, 4, 0, currVal_0); var currVal_1 = ((_co.faqData == null) ? null : _co.faqData.firstText); _ck(_v, 6, 0, currVal_1); var currVal_3 = ((_co.faqData == null) ? null : _co.faqData.questionsList); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), ((_co.faqData == null) ? null : _co.faqData.secondText))); _ck(_v, 7, 0, currVal_2); }); }
export function View_FaqComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-faq", [], null, null, null, View_FaqComponent_0, RenderType_FaqComponent)), i1.ɵdid(1, 49152, null, 0, i7.FaqComponent, [], null, null)], null, null); }
var FaqComponentNgFactory = i1.ɵccf("sucstu-faq", i7.FaqComponent, View_FaqComponent_Host_0, {}, {}, []);
export { FaqComponentNgFactory as FaqComponentNgFactory };
