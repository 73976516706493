import { Store } from '@ngrx/store';
// actions
import * as fromNotificationBar from '@app/store/actions/notification-bar.actions';
var NotificationBarService = /** @class */ (function () {
    function NotificationBarService(store) {
        this.store = store;
    }
    NotificationBarService.prototype.open = function (_a) {
        var title = _a.title, message = _a.message, _b = _a.type, type = _b === void 0 ? 'success' : _b;
        this.store.dispatch(new fromNotificationBar.OpenNotificationBarAction({ title: title, message: message, type: type }));
    };
    NotificationBarService.prototype.close = function (domElem) {
        this.store.dispatch(new fromNotificationBar.CloseNotificationBarAction());
    };
    return NotificationBarService;
}());
export { NotificationBarService };
