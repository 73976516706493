import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// rxjs
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
// config
import { APP_CONFIG, AppConfig } from './../../app.config';
// models
import { Subscription } from './../models/subscription.model';
import { Gift } from './../models/gift.model';
import { Address } from '@app/core/models/address.model';
import { Shipment } from '@app/core/models/shipment.model';
// services
import { AuthService } from './../../auth/services/auth.service';
import { Coupon } from '@app/core/models/coupon.model';
import {AnglerAiService} from '@app/core/services/angler-ai/angler-ai.service';
import {MarketingService} from '@app/core/services/marketing/marketing.service';

export interface GiftShipmentPayload {
  subscription: Subscription,
  gift: Gift
}

@Injectable()
export class SubscriptionService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    @Inject(APP_CONFIG) private app_config: AppConfig,
    private readonly anglerAiService: AnglerAiService,
    private readonly marketingService: MarketingService,
  ) {
  }

  getSubscriptions(): Observable<Subscription[]> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .get(`${ this.app_config.api_uri }/users/${ userId }/subscriptions`)
      .pipe(map((result: any) => result.subscriptions as Subscription[]));
  }

  updateSubscription(data: { _id: number, recipient: { firstName: string, lastName: string } }): Observable<Subscription> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .put(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ data._id }`, data)
      .pipe(map((result: any) => result.subscription as Subscription));
  }

  cancelSubscription(subscriptionId: number, reasons: any): Observable<Subscription> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .request('delete', `${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ subscriptionId }`, {body: {reasons}})
      .pipe(map((result: any) => result.subscription as Subscription));
  }

  skipMonth(subscriptionId: number, skipValue: string): Observable<Subscription> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .post(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ subscriptionId }/skipMonth`, {subscriptionId, skipValue})
      .pipe(map((result: any) => result.subscription as Subscription));
  }

  unSkipMonth(subscriptionId: number): Observable<Subscription> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .put(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ subscriptionId }/unSkipMonth`, {subscriptionId})
      .pipe(map((results: any) => results.subscription as Subscription));
  }

  reActivate(nextBillingDate: string, subscriptionId: number): Observable<Subscription> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .put(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ subscriptionId }/re-activate`, {nextBillingDate})
      .pipe(map((results: any) => results.subscription as Subscription));
  }

  turnOffAutoRenew(data: { subscriptionId: number, turnedOffReasons: any, refundShipment: boolean }) {
    const userId = this.authService.tokenPayload._id;

    this.marketingService.reportTurnOff({ userId })
      .subscribe({ error: console.error });

    return this.httpClient
      .put(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ data.subscriptionId }/turn-off`, {
        turnedOffReasons: data.turnedOffReasons,
        refundShipment: data.refundShipment
      })
      .pipe(delay(1000), map((results: any) => ({subscription: results.subscription, shipment: results.shipment})));
  }

  turnOnAutoRenew(data: { subscriptionId: number, nextBillingDate: string }) {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .put(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ data.subscriptionId }/turn-on`, {
        nextBillingDate: data.nextBillingDate,
      })
      .pipe(delay(1000), map((results: any) => results.subscription as Subscription));
  }

  giftShipment(id: number, gift: Gift): Observable<{ subscription: Subscription, address: Address }> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .post(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/giftShipment`, {subscriptionId: id, gift})
      .pipe(delay(1000), map((result: any) => result as { subscription: Subscription, address: Address }));
  }

  cancelGiftShipment(subscriptionId: number, giftId: string): Observable<Subscription> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .delete(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ subscriptionId }/giftShipment/${ giftId }`)
      .pipe(map((result: any) => result.subscription as Subscription));
  }

  cancelGiftExtraBoxShipment(subscriptionId: number, giftId: string): Observable<Subscription> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .delete(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ subscriptionId }/giftShipment/${ giftId }/cancel-extra-box`)
      .pipe(map((result: any) => result.subscription as Subscription));
  }

  updateSubscriptionAddress({subscriptionId, address}): Observable<{ subscription: Subscription, address: Address }> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .put(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ subscriptionId }/address`, {address})
      .pipe(map(data => data as ({ subscription: Subscription, address: Address })));
  }

  updateSubscriptionNBD({subscriptionId, newBillingDate}) {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .put(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ subscriptionId }/nbd`, {newBillingDate})
      .pipe(map(data => data as ({ subscription: Subscription })));
  }

  buyShipment(data: BuyShipmentData) {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .post(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ data.subscriptionId }/shipment`, {...data})
      .pipe(delay(1000), map((result: any) => result as { subscription: Subscription, shipment: Shipment }));
  }

  getLastShipmentWithoutShip(id) {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .get(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ id }/last-pending-shipment`)
      .pipe(delay(1000), map((result: any) => result as { shipment: Shipment, payment: any }));
  }

  reactivateSubscriptionWinBack(id) {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .post(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ id }/reactivate-with-free-shipping`, null)
      .pipe(delay(1000), map((result: any) => result as { shipment: Shipment, subscription: Subscription }));
  }

  unselectSubscriptionBox(subscriptionId, boxId) {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .delete(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ subscriptionId }/unselect-box`, {
        params: {
          boxId
        }
      })
      .pipe(map((result: any) => result as { shipment: Shipment, subscription: Subscription }));
  }

  selectSubscriptionBox(subscriptionId, boxId) {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .post(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ subscriptionId }/select-box`, {boxId})
      .pipe(map((result: any) => result as { shipment: Shipment, subscription: Subscription }));
  }

  switch2xBundle(subscriptionId) {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .post(`${ this.app_config.api_uri }/users/${ userId }/subscriptions/${ subscriptionId }/switch-2x-bundle`, {})
      .pipe(delay(1000), map((results: any) => results.subscription as Subscription));
  }
}

export interface BuyShipmentData {
  subscriptionId: number
  address: Address,
  coupon?: Coupon,
  isAGift: boolean,
  initialDate: string,
  giftMessage: string | null,
  recipient: {
    firstName: string,
    lastName: string,
  },
  paymentMethod: {
    action: string,
    data: any
  },
}
