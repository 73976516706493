import { Action } from '@ngrx/store';

// models
import { User } from '@app/core/models/user.model';
import { Address } from '@app/core/models/address.model';
import { PaymentMethod } from '@app/core/models/payment-method';
import { Subscription } from '@app/core/models/subscription.model';

export const LOAD = '[User] Load';
export const LOAD_COMPLETE = '[User] Load Complete';
export const LOAD_FAIL = '[User] Load Fail';

export const LOGIN = '[User] Login';
export const LOGIN_COMPLETE = '[User] Login Complete';
export const LOGIN_FAIL = '[User] Login Fail';

export const CREATE = '[User] Create';
export const CREATE_COMPLETE = '[User] Create Complete';
export const CREATE_FAIL = '[User] Create Fail';

export const UPDATE = '[User] Update';
export const UPDATE_COMPLETE = '[User] Update Complete';
export const UPDATE_FAIL = '[User] Update Fail';

export const UPDATE_NAME = '[User] Update Name';

export const LOGOUT = '[User] Logout';
export const LOGOUT_COMPLETE = '[User] Logout Complete';
export const LOGOUT_FAIL = '[User] Logout Fail';

export class LoadAction implements Action {
  readonly type = LOAD;
  constructor() { }
};

export class LoadCompleteAction implements Action {
  readonly type = LOAD_COMPLETE;
  constructor(public payload: User) { }
};

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
  constructor(public payload: any) { }
};

export class LoginAction implements Action {
  readonly type = LOGIN;
  constructor(public payload: { email: string, password: string}) { }
};

export class LoginCompleteAction implements Action {
  readonly type = LOGIN_COMPLETE;
  constructor(public payload: User) { }
};

export class LoginFailAction implements Action {
  readonly type = LOGIN_FAIL;
  constructor(public payload: any) { }
};

export class CreateAction implements Action {
  readonly type = CREATE;
  constructor(public payload: User) { }
};

export class CreateCompleteAction implements Action {
  readonly type = CREATE_COMPLETE;
  constructor(public payload: User) { }
};

export class CreateFailAction implements Action {
  readonly type = CREATE_FAIL;
  constructor(public payload: any) { }
};

export class UpdateAction implements Action {
  readonly type = UPDATE;
  constructor(public payload: any) { }
};

export class UpdateCompleteAction implements Action {
  readonly type = UPDATE_COMPLETE;
  constructor(public payload: User) { }
};

export class UpdateFailAction implements Action {
  readonly type = UPDATE_FAIL;
  constructor(public payload: any) { }
};

export class UpdateNameAction implements Action {
  readonly type = UPDATE_NAME;
  constructor(public payload: { firstName, lastName, _id }) { }
};

export class LogoutAction implements Action {
  readonly type = LOGOUT;
  constructor() { }
};

export class LogoutCompleteAction implements Action {
  readonly type = LOGOUT_COMPLETE;
  constructor() { }
};

export class LogoutFailAction implements Action {
  readonly type = LOGOUT_FAIL;
  constructor(public payload: any) { }
};

export type Actions =
  | LoadAction
  | LoadCompleteAction
  | LoadFailAction
  | LoginAction
  | LoginCompleteAction
  | LoginFailAction
  | CreateAction
  | CreateCompleteAction
  | CreateFailAction
  | UpdateAction
  | UpdateCompleteAction
  | UpdateFailAction
  | LogoutAction
  | LogoutCompleteAction
  | LogoutFailAction;
