/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gift-done.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/pipes/ordinal.pipe";
import * as i3 from "@angular/common";
import * as i4 from "./gift-done.component";
import * as i5 from "../../../app.config";
import * as i6 from "@angular/router";
import * as i7 from "../../../core/services/plans.service";
import * as i8 from "ngx-webstorage";
import * as i9 from "@ngrx/store";
var styles_GiftDoneComponent = [i0.styles];
var RenderType_GiftDoneComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GiftDoneComponent, data: {} });
export { RenderType_GiftDoneComponent as RenderType_GiftDoneComponent };
function View_GiftDoneComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "video", [["class", "video-container"], ["controls", ""], ["preload", "metadata"]], [[8, "poster", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "source", [["type", "video/webm"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your browser does not support the video tag. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.giftDoneData == null) ? null : _co.giftDoneData.video.poster); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.giftDoneData == null) ? null : _co.giftDoneData.video.srcmp4); _ck(_v, 1, 0, currVal_1); var currVal_2 = ((_co.giftDoneData == null) ? null : _co.giftDoneData.video.srcwebm); _ck(_v, 2, 0, currVal_2); }); }
function View_GiftDoneComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["height", "1px"], ["width", "1px"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bradsDealsPixelTagUrl; _ck(_v, 1, 0, currVal_0); }); }
export function View_GiftDoneComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.OrdinalPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "thanks-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "thanks-title-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "thanks-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "thanks-title-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "thanks-title-divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["src", "/assets/images/thanks-icon.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["class", "gray-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "p", [["class", "term-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" Subscription automatically ends after the ", " month, so no need to cancel! "])), i1.ɵppd(12, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GiftDoneComponent_1)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [["class", "discount-btn-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", " Want your own subscription?"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "a", [["class", "btn discount-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToCheckoutWithCoupon() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["GET $5 OFF YOUR FIRST BOX"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GiftDoneComponent_2)), i1.ɵdid(21, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = ((_co.giftDoneData == null) ? null : _co.giftDoneData.video); _ck(_v, 14, 0, currVal_3); var currVal_5 = ((_co.isPlanABradsDealsDeal && _co.renderBradsDealsPixel) && _co.isProd); _ck(_v, 21, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.giftDoneData == null) ? null : _co.giftDoneData.title); _ck(_v, 4, 0, currVal_0); var currVal_1 = ((_co.giftDoneData == null) ? null : _co.giftDoneData.message); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), _co.data.subscription.plan.term)); _ck(_v, 11, 0, currVal_2); var currVal_4 = ((((_co.brandData == null) ? null : _co.brandData.mainProduct) === "succulents") ? (((_co.brandData == null) ? null : _co.brandData.mainProduct) + "brighten even the succ-iest of days!") : ""); _ck(_v, 17, 0, currVal_4); }); }
export function View_GiftDoneComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-pages-gift-done", [], null, null, null, View_GiftDoneComponent_0, RenderType_GiftDoneComponent)), i1.ɵdid(1, 114688, null, 0, i4.GiftDoneComponent, [i5.APP_CONFIG, i6.ActivatedRoute, i7.PlansService, i8.SessionStorageService, i9.Store, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GiftDoneComponentNgFactory = i1.ɵccf("sucstu-pages-gift-done", i4.GiftDoneComponent, View_GiftDoneComponent_Host_0, {}, {}, []);
export { GiftDoneComponentNgFactory as GiftDoneComponentNgFactory };
