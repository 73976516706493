import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

// config
import { APP_CONFIG, AppConfig } from './../../app.config';

export interface Lead {
  email: string,
  url: string
}

export interface LeadParams {
  uuid: string,
  email?: string,
  utmData?: any
}

@Injectable()
export class LeadsService {

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) { }

  create({ uuid, email, utmData }: LeadParams) {
    const headers = new HttpHeaders({ retry: 'yes' });
    return this.httpClient
      .post(`${this.app_config.api_uri}/leads`, { uuid, email, utmData }, { headers });
  }
}
