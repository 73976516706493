<div class="page-container">
  <!-- <sucstu-modal-banner></sucstu-modal-banner> -->
  <!-- Hero section (background background with image and hero messages)-->
  <section class="hero-container">

    <div class="hero-slides-container">

      <div class="slide-picture">
        <div class="hero-title-container">
          <h2 class="hero-title">Succulent Studios</h2>
          <div class="hero-text">welcomes</div>
          <div class="hero-subtitle">
            <img width="350"
                 src="/assets/images/apartment-therapy/apartment-therapy-text.png"
                 alt="Apartment Therapy">
          </div>
          <div class="hero-text">shoppers!</div>
          <div class="hero-actions">
            <a class="hero-btn btn btn-primary--inset btn-primary--with-hover"
               (click)="applyCoupon()">Subscribe</a>
          </div>
        </div>
      </div>
    </div>

  </section>

  <!-- Plans section-->
  <section class="plans-container">
    <h1>What you get.</h1>
    <div class="plan-card">
      <img src="/assets/images/2x-plan.jpg" alt=""/>
      <div class="plan-content">
        <div class="plan-title">
          <h2><b>2 / month</b> $10</h2>
          <h4>(+ free shipping on your first month)</h4>
        </div>
        <p class="plan-desc">Get FREE SHIPPING on your first month of succulents! Perfect for homes, apartments,
          offices, and dorms. Add 'em to your bedside table, windowsill, desk -- anywhere you want a little pop of color
          and a boost of oxygen!</p>
        <button class="plan-btn btn btn-primary--inset btn-primary--with-hover" (click)="applyCoupon()">Get Started</button>
      </div>
    </div>
  </section>


  <div class="subscribe-container">
    <button class="subscribe-btn btn" (click)="applyCoupon()">Subscribe</button>
  </div>
</div>
