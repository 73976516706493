import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import * as MetaTagsData from '@config/metatags.json';
import * as TitleTags from '@config/titletags.json';
import * as GiftData from '@config/gift.json';
// Actions
// Services

@Component({
  selector: 'sucstu-bogo-component',
  templateUrl: 'bogo.component.html',
  styleUrls: ['./bogo.component.scss']
})
export class BogoComponent implements OnInit {
  metaTagsData = (MetaTagsData as any).default;
  titleTagsData = (TitleTags as any).default;
  giftData = (GiftData as any).default;

  PLAN_3M_GIFT = '5a10dbfaf8ceb4830ff186a9';
  PLAN_6M_GIFT = '5abd46654d4f52a1ea64fa91';
  PLAN_12M_GIFT = '5b33d996641451a36467b89d';

  constructor(
    private metaService: Meta,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.setupMetaTags();
  }

  setupMetaTags() {
    this.metaService.updateTag({
      name: 'description',
      content: this.metaTagsData.bogo
    });
    this.titleService.setTitle(this.titleTagsData.bogo);
  }
}
