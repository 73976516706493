/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./signup.component";
var styles_SignupComponent = [i0.styles];
var RenderType_SignupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignupComponent, data: {} });
export { RenderType_SignupComponent as RenderType_SignupComponent };
export function View_SignupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Signup"]))], null, null); }
export function View_SignupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-signup", [], null, null, null, View_SignupComponent_0, RenderType_SignupComponent)), i1.ɵdid(1, 49152, null, 0, i2.SignupComponent, [], null, null)], null, null); }
var SignupComponentNgFactory = i1.ɵccf("sucstu-signup", i2.SignupComponent, View_SignupComponent_Host_0, {}, {}, []);
export { SignupComponentNgFactory as SignupComponentNgFactory };
