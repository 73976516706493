import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APP_CONFIG, AppConfig } from '@app/app.config';
import * as format from 'date-fns/format';
import * as addDays from 'date-fns/add_days';

import { Coupon } from '@app/core/models/coupon.model';
import * as isToday from 'date-fns/is_today';
import * as ThanksData from "@config/thanks.json"

// Angulartics

@Component({
  selector: 'sucstu-thanks',
  styleUrls: ['./thanks.component.scss'],
  templateUrl: './thanks.component.html',
})
export class ThanksComponent implements OnInit {
  thanksData = (ThanksData as any).default;

  data: any = this.route.snapshot.data.data;

  constructor(
    @Inject(APP_CONFIG) private app_config: AppConfig,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    const { subscription, shipment } = this.data;
    const coupon: Coupon = subscription.usedCoupons[0];

    const total = this.data.subscription.plan.price + (
      this.data.subscription.plan.shippingCosts * this.data.subscription.plan.shipmentsAtCheckout
    );

    if ('dataLayer' in window) {
      (window as any).dataLayer.push({
        'event': 'transactionComplete',
        'transactionId': this.app_config.production && this.app_config.name === 'production'
          ? this.data.subscription._id
          : `TEST_${this.data.subscription._id}`,
        'transactionTotal': total,
        'transactionProducts': [
          {
            'name': this.data.subscription.plan.title,
            'sku': this.data.subscription.plan.name,
            'price': total,
            'quantity': 1
          }
        ],
        'customerEmail': subscription.user.email,
        'sourceParam': subscription.user.utmData && subscription.user.utmData.utm_source,
        'coupon': coupon && coupon.code.toLowerCase()
      });
    }

    if ((window as any).ire) {
      window['ire']('identify', {
        customerId: subscription.user._id,
        customerEmail: subscription.user.email
      });

      window['ire']('trackConversion', 15273, {
        orderId: subscription._id,
        customerId: subscription.user._id,
        customerStatus: isToday(subscription.user.createdAt) ? 'new' : 'returning',
        orderPromoCode: coupon ? coupon.code.toLowerCase() : '',
        orderDiscount: coupon ? parseInt(coupon.amount as any, 10) : 0,
        currencyCode: 'USD',
        items: [
          {
            subTotal: subscription.plan.price,
            category: (subscription.plan.type as string).toLowerCase(),
            sku: subscription.plan.name,
            quantity: 1
          }
        ]
      });
    }

    if ((window as any).uetq && (window as any).uetq.push) {
      window['uetq'].push('event', 'checkout', {
        revenue_value: total,
        currency: 'USD'
      });
    }

    if ((window as any).gapi) {
      (window as any).gapi.load('surveyoptin', function () {
        (window as any).gapi.surveyoptin.render(
          {
            // REQUIRED FIELDS
            'merchant_id': 129373759,
            'order_id': subscription._id,
            'email': subscription.user.email,
            'delivery_country': 'US',
            'estimated_delivery_date': format(addDays(shipment.printed.cantPrintUntil, 7), 'YYYY-MM-DD'),
          });
      });
    }
  }
}
