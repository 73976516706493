import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

// selectors
import * as fromRoot from '@app/store/selectors';

// actions
import * as fromNotificationBar from '@app/store/actions/notification-bar.actions';

@Injectable()
export class NotificationBarService {

  constructor(
    private store: Store<fromRoot.State>
  ) { }

  open({ title, message, type = 'success' }) {
    this.store.dispatch(new fromNotificationBar.OpenNotificationBarAction({ title, message, type }));
  }

  close(domElem) {
    this.store.dispatch(new fromNotificationBar.CloseNotificationBarAction());
  }
}
