<div class="thanks-container">

  <div class="thanks-title-container">
    <h1 class="thanks-title">{{ thanksData?.title }}</h1>

    <p class="thanks-title-message">
      {{ thanksData?.message }}
    </p>

    <div class="thanks-title-divider">
      <img src="/assets/images/thanks-icon.png" />
      <span class="gray-line"></span>
    </div>

    <p class="term-message">
      {{ thanksData?.term }}
    </p>

    <p class="term-message" *ngIf="thanksData?.video">
      {{ thanksData?.video.text }}
    </p>
  </div>
  <video *ngIf="thanksData?.video" class="video-container" [poster]="thanksData?.video.poster" controls preload="metadata">
    <source [src]="thanksData?.video.srcmp4" type="video/mp4">
    <source [src]="thanksData?.video.srcwebm" type="video/webm">
    Your browser does not support the video tag.
  </video>
</div>
