import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { APP_CONFIG, AppConfig } from '@app/app.config';
import * as BrandData from "@config/brand.json"
import * as GiftDoneData from "@config/gift-done.json"

import { Coupon } from '@app/core/models/coupon.model';
import { Store } from '@ngrx/store';

import * as isToday from 'date-fns/is_today';
// services
import { SessionStorageService } from 'ngx-webstorage';

import { map } from 'rxjs/operators';
import { PlansService } from '@app/core/services';

import * as fromCouponsActions from './../../../store/actions/coupon.actions';
import * as format from 'date-fns/format';
import * as addDays from 'date-fns/add_days';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'sucstu-pages-gift-done',
  styleUrls: ['./gift-done.component.scss'],
  templateUrl: 'gift-done.template.html'
})
export class GiftDoneComponent implements OnInit {
  giftDoneData = (GiftDoneData as any).default;
  brandData = (BrandData as any).default;

  data: any = this.route.snapshot.data.data;

  renderBradsDealsPixel = false;
  bradsDealsTrafficData: { orderId: string, transactionId: string } = null;
  bradsDealsPixelTagUrl = '';

  constructor(
    @Inject(APP_CONFIG) private app_config: AppConfig,
    private route: ActivatedRoute,
    private plansService: PlansService,
    private sessionStorage: SessionStorageService,
    private store: Store<any>,
    private router: Router,
  ) {
  }

  get isPlanABradsDealsDeal() {
    const { subscription } = this.data;
    return subscription.plan._id === this.plansService.BRADS_GIFT_BOGO;
  }

  get isProd() {
    return this.app_config.production && this.app_config.name === 'production';
  }

  ngOnInit() {
    const { subscription, shipment } = this.data;
    const coupon: Coupon = subscription.usedCoupons[0];

    const total = this.data.subscription.plan.price + (
      this.data.subscription.plan.shippingCosts * this.data.subscription.plan.shipmentsAtCheckout
    );

    if (this.isPlanABradsDealsDeal) {
      this.plansService.getPlansStatistics()
        .pipe(
          map((plans) => plans.find(plan => plan._id === this.plansService.BRADS_GIFT_BOGO)),
          map((plan) => plan.total),
        )
        .subscribe((num: number) => {
          if (num > 500) {
            this.renderBradsDealsPixel = false;
            return;
          }
          this.renderBradsDealsPixel = true;
          const data = this.parseBradsDealsTrafficData();
          if (!data) {
            this.renderBradsDealsPixel = false;
            return;
          }

          const params = new HttpParams({
            fromObject: {
              nid: '104',
              oid: data.orderId,
              transaction_id: data.transactionId,
              amount: total.toString().replace('.', ',')
            }
          });

          this.bradsDealsPixelTagUrl = `https://bradsdeals.servtrk.com/?${params.toString()}`;
        });
    }

    if ((window as any).dataLayer) {
      (window as any).dataLayer.push({
        'event': 'transactionComplete',
        'transactionId': this.app_config.production && this.app_config.name === 'production'
          ? this.data.subscription._id
          : `TEST_${this.data.subscription._id}`,
        'transactionTotal': total,
        'transactionProducts': [
          {
            'name': this.data.subscription.plan.title,
            'sku': this.data.subscription.plan.name,
            'price': total,
            'quantity': 1
          }
        ],
        'customerEmail': subscription.user.email,
        'sourceParam': subscription.user.utmData && subscription.user.utmData.utm_source,
        'coupon': coupon && coupon.code.toLowerCase()
      });
    }

    if ((window as any).ire) {
      (window as any).ire('identify', {
        customerId: subscription.user._id,
        customerEmail: subscription.user.email
      });

      (window as any).ire('trackConversion', 15273, {
        orderId: subscription._id,
        customerId: subscription.user._id,
        customerStatus: isToday(subscription.user.createdAt) ? 'new' : 'returning',
        orderPromoCode: coupon ? coupon.code.toLowerCase() : '',
        orderDiscount: coupon ? parseInt(coupon.amount as any, 10) : 0,
        currencyCode: 'USD',
        items: [
          {
            subTotal: subscription.plan.price,
            category: (subscription.plan.type as string).toLowerCase(),
            sku: subscription.plan.name,
            quantity: 1
          }
        ]
      });
    }

    if ((window as any).uetq && (window as any).uetq.push) {
      window['uetq'].push('event', 'checkout', {
        revenue_value: total,
        currency: 'USD'
      });
    }

    if ((window as any).gapi) {
      (window as any).gapi.load('surveyoptin', function () {
        (window as any).gapi.surveyoptin.render(
          {
            // REQUIRED FIELDS
            'merchant_id': 129373759,
            'order_id': subscription._id,
            'email': subscription.user.email,
            'delivery_country': 'US',
            'estimated_delivery_date': format(addDays(shipment.printed.cantPrintUntil, 7), 'YYYY-MM-DD'),
          });
      });
    }
  }

  parseBradsDealsTrafficData(): { orderId: string, transactionId: string } {
    const data = this.sessionStorage.retrieve('bradsDealsTrafficData');
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  goToCheckoutWithCoupon() {
    this.store.dispatch(new fromCouponsActions.VerifyCouponAction('FIFTYOFF'));
    this.router.navigate(['/checkout'], { queryParams: { planId: '5997c3ac8852a761249342e9' } });
  }
}
