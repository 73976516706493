/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./monthly-thanks.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./monthly-thanks.component";
import * as i5 from "../../../app.config";
import * as i6 from "angulartics2";
var styles_MonthlyThanksComponent = [i0.styles];
var RenderType_MonthlyThanksComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MonthlyThanksComponent, data: {} });
export { RenderType_MonthlyThanksComponent as RenderType_MonthlyThanksComponent };
export function View_MonthlyThanksComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "thanks-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "thanks-title-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "thanks-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Thank you for your one-time order!"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "thanks-title-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We've processed your October box, and we'll have it in the mail to you in just a few business days. "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "thanks-title-divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", "Succulent Studio Logo"], ["src", "/assets/images/icon-transparent@3x.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "gray-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 8, "p", [["class", "title-links"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "a", [["class", "text-right"], ["routerLink", "/profile"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["REVIEW YOUR ACCOUNT"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "vertical-divider"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["|"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["SEND A SUCCULENT GIFT"])), (_l()(), i1.ɵeld(18, 0, null, null, 3, "video", [["class", "video-container"], ["controls", ""], ["poster", "/assets/videos/placeholder/unboxing.png"], ["preload", "metadata"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "source", [["src", "/assets/videos/unboxing-vid.mp4"], ["type", "video/mp4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "source", [["src", "/assets/videos/unboxing-vid.webm"], ["type", "video/webm"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your browser does not support the video tag. "]))], function (_ck, _v) { var currVal_2 = "/profile"; _ck(_v, 11, 0, currVal_2); var currVal_5 = "/"; _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 11).target; var currVal_1 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 16).target; var currVal_4 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_3, currVal_4); }); }
export function View_MonthlyThanksComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-monthly-thanks", [], null, null, null, View_MonthlyThanksComponent_0, RenderType_MonthlyThanksComponent)), i1.ɵdid(1, 114688, null, 0, i4.MonthlyThanksComponent, [i5.APP_CONFIG, i2.ActivatedRoute, i6.Angulartics2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MonthlyThanksComponentNgFactory = i1.ɵccf("sucstu-monthly-thanks", i4.MonthlyThanksComponent, View_MonthlyThanksComponent_Host_0, {}, {}, []);
export { MonthlyThanksComponentNgFactory as MonthlyThanksComponentNgFactory };
