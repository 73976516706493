/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./apartment-therapy.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./apartment-therapy.component";
import * as i3 from "@angular/router";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/platform-browser";
var styles_ApartmentTherapyComponent = [i0.styles];
var RenderType_ApartmentTherapyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApartmentTherapyComponent, data: {} });
export { RenderType_ApartmentTherapyComponent as RenderType_ApartmentTherapyComponent };
export function View_ApartmentTherapyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 35, "div", [["class", "page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "section", [["class", "hero-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "hero-slides-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "slide-picture"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "hero-title-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "hero-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Succulent Studios"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "hero-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["welcomes"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "hero-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["alt", "Apartment Therapy"], ["src", "/assets/images/apartment-therapy/apartment-therapy-text.png"], ["width", "350"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "hero-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["shoppers!"])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "hero-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "a", [["class", "hero-btn btn btn-primary--inset btn-primary--with-hover"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.applyCoupon() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Subscribe"])), (_l()(), i1.ɵeld(16, 0, null, null, 16, "section", [["class", "plans-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["What you get."])), (_l()(), i1.ɵeld(19, 0, null, null, 13, "div", [["class", "plan-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "img", [["alt", ""], ["src", "/assets/images/2x-plan.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 11, "div", [["class", "plan-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 6, "div", [["class", "plan-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 3, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["2 / month"])), (_l()(), i1.ɵted(-1, null, [" $10"])), (_l()(), i1.ɵeld(27, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["(+ free shipping on your first month)"])), (_l()(), i1.ɵeld(29, 0, null, null, 1, "p", [["class", "plan-desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Get FREE SHIPPING on your first month of succulents! Perfect for homes, apartments, offices, and dorms. Add 'em to your bedside table, windowsill, desk -- anywhere you want a little pop of color and a boost of oxygen!"])), (_l()(), i1.ɵeld(31, 0, null, null, 1, "button", [["class", "plan-btn btn btn-primary--inset btn-primary--with-hover"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.applyCoupon() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Get Started"])), (_l()(), i1.ɵeld(33, 0, null, null, 2, "div", [["class", "subscribe-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 1, "button", [["class", "subscribe-btn btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.applyCoupon() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Subscribe"]))], null, null); }
export function View_ApartmentTherapyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-apartment-therapy", [], null, null, null, View_ApartmentTherapyComponent_0, RenderType_ApartmentTherapyComponent)), i1.ɵdid(1, 114688, null, 0, i2.ApartmentTherapyComponent, [i3.Router, i4.Store, i5.Meta, i5.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApartmentTherapyComponentNgFactory = i1.ɵccf("sucstu-apartment-therapy", i2.ApartmentTherapyComponent, View_ApartmentTherapyComponent_Host_0, {}, {}, []);
export { ApartmentTherapyComponentNgFactory as ApartmentTherapyComponentNgFactory };
