/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lead-banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./lead-banner.component";
var styles_LeadBannerComponent = [i0.styles];
var RenderType_LeadBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LeadBannerComponent, data: {} });
export { RenderType_LeadBannerComponent as RenderType_LeadBannerComponent };
function View_LeadBannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "lead-error error-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please use a valid email"]))], null, null); }
export function View_LeadBannerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { banner: 0 }), i1.ɵqud(402653184, 2, { bannerContainer: 0 }), (_l()(), i1.ɵeld(2, 0, [[2, 0], ["bannerContainer", 1]], null, 26, "div", [["class", "lead-banner-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["banner", 1]], null, 25, "div", [["class", "lead-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 24, "div", [["class", "lead-banner-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [["class", "lead-banner__title text-bolder"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["50% OFF"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h3", [["class", "lead-banner__subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["YOUR FIRST BUNDLE OF SEASONAL EUCALYPTUS!"])), (_l()(), i1.ɵeld(9, 0, null, null, 17, "form", [["class", "lead-banner__form"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i2.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(11, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(13, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 10, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 7, "input", [["class", "lead-input form-group__input text-extralight"], ["formControlName", "email"], ["id", "nameInput"], ["placeholder", "Enter your email"], ["required", "required"], ["type", "email"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keyup.enter"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 16)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 16)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keyup.enter" === en)) {
        var pd_4 = ((!_co.isFieldInvalid("email", "invalidEmail") ? _co.submitEmail() : null) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(17, 16384, null, 0, i2.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.RequiredValidator]), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(20, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(22, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeadBannerComponent_1)), i1.ɵdid(24, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 1, "button", [["class", "lead-banner__btn btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(26, null, ["I want ", ""])), (_l()(), i1.ɵeld(27, 0, null, null, 1, "a", [["class", "lead-banner__link"]], [[1, "test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeBanner() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["No thanks, I do not want 50% off. "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 11, 0, currVal_7); var currVal_16 = "required"; _ck(_v, 17, 0, currVal_16); var currVal_17 = "email"; _ck(_v, 20, 0, currVal_17); var currVal_18 = _co.isFieldInvalid("email", "invalidEmail"); _ck(_v, 24, 0, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 13).ngClassValid; var currVal_5 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 9, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = (i1.ɵnov(_v, 17).required ? "" : null); var currVal_9 = i1.ɵnov(_v, 22).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 22).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 22).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 22).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 22).ngClassValid; var currVal_14 = i1.ɵnov(_v, 22).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 22).ngClassPending; _ck(_v, 15, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_19 = ((_co.brandData == null) ? null : _co.brandData.mainProduct); _ck(_v, 26, 0, currVal_19); var currVal_20 = "lead-banner__close-button"; _ck(_v, 27, 0, currVal_20); }); }
export function View_LeadBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-lead-banner", [], null, null, null, View_LeadBannerComponent_0, RenderType_LeadBannerComponent)), i1.ɵdid(1, 4243456, null, 0, i4.LeadBannerComponent, [i2.FormBuilder], null, null)], null, null); }
var LeadBannerComponentNgFactory = i1.ɵccf("sucstu-lead-banner", i4.LeadBannerComponent, View_LeadBannerComponent_Host_0, {}, { close: "close", submit: "submit" }, []);
export { LeadBannerComponentNgFactory as LeadBannerComponentNgFactory };
