import * as tslib_1 from "tslib";
import { createEntityAdapter } from '@ngrx/entity';
import * as plansActions from './../actions/plans.actions';
export var adapter = createEntityAdapter({
    selectId: function (plan) { return plan._id; },
    sortComparer: false
});
export var initialState = adapter.getInitialState({
    loading: false,
    loaded: false,
    error: null,
    selectedPlanId: null
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case plansActions.LOAD_PLANS: {
            return tslib_1.__assign({}, state, { loading: true, loaded: false, error: null });
        }
        case plansActions.LOAD_PLANS_COMPLETE: {
            return tslib_1.__assign({}, adapter.addMany(action.payload, state), { loaded: true, loading: false, error: null });
        }
        case plansActions.LOAD_PLANS_FAIL: {
            return adapter.removeAll(tslib_1.__assign({}, state, { loaded: false, loading: false, selectedPlanId: null, error: action.payload.error }));
        }
        case plansActions.SELECT_PLAN: {
            return tslib_1.__assign({}, state, { selectedPlanId: action.payload });
        }
        case plansActions.DESELECT_PLAN: {
            return tslib_1.__assign({}, state, { selectedPlanId: null });
        }
        case plansActions.CLEAR_PLAN_ERROR: {
            return tslib_1.__assign({}, state, { error: null });
        }
        default: {
            return state;
        }
    }
}
export var getPlansEntities = function (state) { return state.entities; };
export var getPlansIds = function (state) { return state.ids; };
export var getSelectedPlanId = function (state) { return state.selectedPlanId; };
export var getPlansLoaded = function (state) { return state.loaded; };
export var getPlansError = function (state) { return state.error; };
export var getPlansLoading = function (state) { return state.loading; };
